// NGRX
import { Action } from '@ngrx/store';
// Models
import { Toll } from '../_models/toll.model';

export enum TollActionTypes {
    AllTollsRequested = '[Init] All Tolls Requested',
    AllTollsLoaded = '[Init] All Tolls Loaded'
}

export class AllTollsRequested implements Action {
    readonly type = TollActionTypes.AllTollsRequested;
}

export class AllTollsLoaded implements Action {
    readonly type = TollActionTypes.AllTollsLoaded;
    constructor(public payload: { Tolls: Toll[] }) { }
}

export type TollActions = AllTollsRequested | AllTollsLoaded;
