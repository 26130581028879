// NGRX
import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { createFeatureSelector } from '@ngrx/store';
// Actions
import { TollActionTypes, TollActions } from '../_actions/toll.actions';
// Models
import { Toll } from '../_models/toll.model';

export interface TollsState extends EntityState<Toll> {
    _isAllTollsLoaded: boolean;
}

export const adapter: EntityAdapter<Toll> = createEntityAdapter({
  selectId: (toll: Toll) => toll._id,
});

export const initialTollsState: TollsState = adapter.getInitialState({
    _isAllTollsLoaded: false
});

export function tollsReducer(state = initialTollsState, action: TollActions): TollsState {
    switch  (action.type) {
        case TollActionTypes.AllTollsRequested:
            return {...state,
                _isAllTollsLoaded: false
        };
        case TollActionTypes.AllTollsLoaded:
            return adapter.setAll(action.payload.Tolls, {
                ...state,
                _isAllTollsLoaded: true
            });
        default:
            return state;
    }
}

export const getRoleState = createFeatureSelector<TollsState>('tolls');

export const {
    selectAll,
    selectEntities,
    selectIds,
    selectTotal
} = adapter.getSelectors();
