// Angular
import { Injectable } from "@angular/core";
import {
  HttpEvent,
  HttpInterceptor,
  HttpHandler,
  HttpRequest,
  HttpClient,
  HttpErrorResponse,
} from "@angular/common/http";

import { BehaviorSubject, Observable, throwError } from "rxjs";
import { catchError, filter, switchMap, take, tap } from "rxjs/operators";
import { environment } from "../../../../../environments/environment";
import { AuthService, Logout } from "src/app/core/auth";
import { AppState } from "src/app/core/reducers";
import { Store } from "@ngrx/store";
@Injectable()
export class InterceptService implements HttpInterceptor {

  private isRefreshing: boolean = false;
  private refreshTokenSubject: BehaviorSubject<string | null> = new BehaviorSubject<string | null>(null);

  constructor(
    private http: HttpClient,
    private service: AuthService,
    private store: Store<AppState>,
  ) {}

  // intercept request and add token
  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {

    let request = this.createHeaders(req);

    return next.handle(request).pipe(
      // switchMap((event) => {
      //   if (event instanceof HttpResponse && event.status === 200) {
      //     // All went well!!!
      //   }
      // }),
      catchError(error => {
        if (error instanceof HttpErrorResponse && error.status === 401) {
          if (request.url.includes("refreshToken")) {
            this.store.dispatch(new Logout({type: 'expired'}));
            return throwError(() => error);
          }
          if (
            !request.url.includes("refreshToken") &&
            !request.url.includes("resetPassword") &&
            !request.url.includes("login") &&
            !request.url.includes("changePassword")
          ) {
            return this.refreshToken(request, next);
          }
          return throwError(() => error);
        }else {
          return throwError(() => error);
        }
      })
    );
  }

  private createHeaders(request: HttpRequest<any>) {
    if (
      request.url.includes("resetPassword") ||
      request.url.includes("https://geolocation-db.com/json/") ||
      request.url.includes("https://nominatim.openstreetmap.org/") ||
      request.url.includes(environment.tbUrl)
    ) {
      request = request.clone({});
    } else {
      const token = request.url.includes("refreshToken") ? localStorage.getItem(environment.refreshTokenKey) : localStorage.getItem(environment.authTokenKey);
      const tokenKey = request.url.includes("refreshToken") ? "accessToken" : "token";
      const clientIp = localStorage.getItem(environment.clientIpv4);
      const clientMac = localStorage.getItem(environment.clientMac);
      request = request.clone({
        setHeaders: {
          [tokenKey]: `${token}`,
          clientip4: `${clientIp}`,
          clientmac: `${clientMac}`,
          versioncode: `${environment.versionCode}`,
          versionname: `${environment.versionName}`,
          platform: "web",
          version: "v4",
        },
      });
    }
    return request;
  }

  refreshToken(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    console.log("Token refreshing...")
    if (!this.isRefreshing) {
      this.isRefreshing = true;
      this.refreshTokenSubject.next(null);
      return this.service.refreshToken().pipe(
        switchMap((res) => {
          this.isRefreshing = false;
          if (res.response.code === 200) {
            localStorage.setItem(environment.authTokenKey, res.data.token);
            this.refreshTokenSubject.next(res.data.token);
            return next.handle(this.createHeaders(req));
          } else {
            this.store.dispatch(new Logout({type: 'expired'}));
            return throwError(() => res);
          }
        }),
        catchError(error => {
          this.isRefreshing = false;
          this.store.dispatch(new Logout({type: 'expired'}));
          return throwError(() => error);
        })
      );
    } else {
      return this.refreshTokenSubject.pipe(
        filter(token => token !== null),
        take(1),
        switchMap(token => next.handle(this.createHeaders(req)))
      );
    }
  }


  getClientIp() {
    return this.http.get<any>('https://geolocation-db.com/json/').subscribe(
      res => {
        console.log(res);
        if (res.IPv4) {
          localStorage.setItem(environment.clientIpv4, res.IPv4);
        }else {
          localStorage.setItem(environment.clientIpv4, 'unknown');
        }
      }
    );
  }
}
