// English
export const locale = {
  lang: "en",
  data: {
    TRANSLATOR: {
      SELECT: "Select your language",
      LANGUAGE: "Language",
      ENGLISH: "English",
      HINDI: "Hindi",
      KANNADA: "Kannada",
      MALAYALAM: "Malayalam",
    },
    AUTH: {
      GENERAL: {
        SIGNIN: "Sign In",
        SIGNOUT: "Sign Out",
        SIGNUP: "Create Account",
        FORGOT_PASSWORD: "Forgot Password?",
        RESET_PASSWORD: "Reset Password?",
        TAGLINE: "The best of Guatemala is made as a team!",
        BACKHOME: "Go back to",
        HOME: "Home",
      },
    },
    ERROR_PAGE: {
      TITLE: "Oops!",
      SUB_TITLE: "Something went wrong here.",
      CONTENT:
        "We're working on it and we'll get it fixed as soon possible. You can back or use our Help Center.",
    },
    COMING_SOON: {
      TITLE: "Page is under Construction.",
      SUB_TITLE: "We're working on it and we'll get it fixedas soon possible.",
      CONTENT: "You can back or use our Help Center.",
    },
    DASHBOARD: {
      DASHBOARDS: "Dashboards",
      RIDES: "Rides",
      DEFAULT: "Default",
      OPERATIONS: "Operations",
      DRIVER: "Driver",
      RIDER: "Rider",
      SUBSCRIPTIONS: "Subscriptions",
      SALES: "Sales",
      PARTNERS: "Partners",
      REVENUE: "Revenue",
    },
    MANAGE: {
      MANAGE: "Manage",
      ADMIN: {
        ADMIN: "Admin",
        USERS: "Users",
        ROLES: "Roles",
        WORK_FLOW: "Work Flow",
      },
      PARTNERS: {
        PARTNERS: "Partners",
        FLEETS: "Fleets",
        TRAVEL_DESK: "Travel Desk",
        CORPORATES: "Corporates",
      },
      REVENUE_MODEL: {
        REVENUE_MODEL: "Revenue Model",
        SUBSCRIPTIONS: "Suscriptions",
        COMMISSION: "Commission",
        PLATFORM_FEE: "Platform Fee",
      },
      BILLING_INVOICING: {
        BILLING_INVOICING: "Billing & Invoicing",
        BILLED: "Billed",
        INVOICED: "Invoiced",
      },
      CUSTOMERS: {
        CUSTOMERS: "Customers",
        DRIVERS: "Drivers",
        RIDERS: "Riders",
        CUSTOMER_GROUPS: "Customer Groups",
      },
      SUBSCRIPTIONS: {
        SUBSCRIPTIONS: "Subsciptions",
        PACKAGES: "Packages",
        SUBSCRIBERS: "Subscribers",
      },
      REFERRAL: {
        REFERRAL: "Refferal",
        REFERRAL_PROMOTIONS: "Referral Promotions",
        REFERRAL_SETTLEMENT: "Referral Settlement",
      },
      OFFERS: {
        OFFERS: "Offers",
        PROMOTIONS: "Promotions",
        PROMOTION_SETTLEMENTS: "Promotion Settlements",
      },
      WALLET: {
        WALLET: "Wallet",
        WALLET_TRANSACTIONS: "Wallet Transactions",
        WITHDRAWAL_REQUESTS: "Withdrawal Requests",
      },
      ARCHIVED: {
        ARCHIVED: "Archived",
        USERS: "Users",
        DRIVERS: "Drivers",
        RIDERS: "Riders",
        FLEETS: "Fleets",
        TRAVEL_DESKS: "Travel Desks",
        CORPORATES: "Corporates",

      },
      INVITED_USERS: {
        INVITED_USERS: "Invited Users",
        INVITED_DRIVERS: "Invited Drivers",
        INVITED_RIDERS: "Invited Riders",
      },
    },
    RIDES: {
      RIDES: "Rides",
      BOOK_RIDE: "Book Ride",
      PENDING_RIDES: "Pending Rides",
    },
    REPORTS: {
      REPORTS: "Reports",

      SALES: "Sales",
      REVENUE: "Revenue",
    },
    CUSTOMER_ENGAGEMENT: {
      CUSTOMER_ENGAGEMENT: "Customer Engagement",
      DYNAMIC_SECTIONS: "Dynamic Sections",
      RIDE_REQUEST_TIPS: "Ride Request Tips",
    },
    ADMIN_CONFIGURATIONS: {
      ADMIN_CONFIGURATIONS: "Admin Configurations",
      MASTER_DATA: {
        MASTER_DATA: "Master Data",
        ZONES: "Zones",
        CITIES: "Cities",
        AIRPORTS: "Airports",
        TOLLS: "Tolls",
        POPULAR_PACKAGES: "Popular Packages",
        VEHICLES: "Vehicles",
        REASONS: "Reasons",
        ATTRIBUTES: "Attributes",
        TAXES: "Taxes",
        AMENITIES:"Amenities"
      },
      CONTROL_PANEL: {
        CONTROL_PANEL: "Control Panel",
        TRIP_FARE: "Trip Fare",
        COMMISSION_SETTLEMENT: "Commission Settlement",
        "TAX&INVOICING": "Tax & Invoicing",
        WALLET_CONFIGURATION: "Wallet Configuration",
        SURGE_CHARGE: "Surge Charge",
        VEHICLE_GROUPS: "Vehicle Groups",
        TARGET_CONFIGURATION: "Target Configuration",
        KYC_CONFIGURATION: "KYC Configuration",
        GENERAL_SETTINGS: "General Settings",
      },
    },
    LOGS: {
      LOGS: "Logs",
      AUDIT_LOGS: "Audit Logs",
    },
    //common
    //Help
    HELP: {
      USER_GUIDE: "User Guide",
      FAQ: "FAQ",
      SUPPORT: "Support",
      CHANGE_LOG: "Change Log",
    },

    //tools
    TOOLS: {
      URL_SHORTNER: "URL Shortener",
      DATA_SYNC: "Data Sync",
    },
    NOTIFICATIONS: {
      NOTIFICATIONS: "Notifications",
      MARK_ALL_AS_READ: "Mark all as read",
    },
    PROFILE: {
      PROFILE: "Profile",
      LOCK_SCREEN: "Lock Screen",
      LOGOUT: "Logout",
    },
    GLOBAL_SEARCH: {
      ADVACED_GLOBAL_SEARCH: "Advanced Global Search",
      EXPAND_SEARCH_IN: "Expand Search in",
      ALL_RECORDS: "All Records",
      GLOBAL_SEARCH: "Search Result",
      DOCUMENT_NUMBER: "Document Number",
      DRIVER_DETAILS: "Driver Details",
      KYC_STATUS: "KYC Status",
      UPLOAD_DATE: "Upload Date",
      EXPIRY_DATE: "Expiry Date",
      STATUS: "Status",
      DRIVER_ID: "Driver ID",
      DETAILS: "Details",
      SUBSCRIPTION_STATUS: "Subscription Status",
      RATING: "Rating",
      JOINNG_DATE: "Joining Date",
      DATE: "Date",
      RIDE_ID: "Ride ID",
      DRIVER: "Driver",
      RIDER: "Rider",
      RIDE_FARE: "Ride Fare",
      INVALID: "Invalid",
      PENDING: "Pending",
      SEARCH_RESULT: "Search Result",
      "ADD_KEYWORD_TO_SEARCH": "Add Keyword to Search",
      "PLEASE_ADD_A_KEYWORD_IN_GLOBAL_SEARCH_INPUT_TO_INITIATE_A_SEARCH": "Please add a keyword in global search input to intiate a search",
      "NO_RESULT_FOUND": "No Result Found",
      "NO_CONTENT:_THERE_MAY_NOT_BE_ANY_RECORD_ABOUT_THE_TOPIC_BEING_SEARCHED_FOR":"No content: There may not be any record about the topic being searched for"
    },
    BUTTONS: {
      SEARCH: "Search",
      VIEW_PROFILE: "View Profile",
      VIEW_ALL: "View All",
      OPEN: "Open",
      CLOSED: "Closed",
      "NEW_USER": "New User",
      "NEW_ROLE": "New Role",
      "ADD_NEW": "Add New",
      "EDIT": "Edit",
      "CANCEL": "Cancel",
      "SAVE": "Save",
      "CLOSE":"Close",
      "NORMAL": "NORMAL",
      "ADVANCED": "ADVANCED",
      "INVITE_RIDER": "Invite Rider",
      "NEW_GROUP": "New Group",
      "NEW_PACKAGE": "New Package",
      "NEW_REFERRAL_PROMOTION": "New Referral Promotion",
      "UPDATE": "Update",
      "NEW_PROMOTION": "New Promotion",
      "PAYOUT": "Payout",
      "ADD_TRANSACTION": "Add Transaction",
      "GET_OTP": "Get OTP",
      "CANCEL_RIDE": "Cancel Ride",
      "BACK": "Back",
      "DOWNLOAD_RECEIPT": "Download Receipt",
      "VIEW_DETAILS": "View Details",
      "NEW_SECTION": "New Section",
      "ADD": "Add",
      "NEW_ZONE": "New Zone",
      "SUSPEND": "Suspend",
      "REINSTATE": "Reinstate",
      "NEW_CITY": "New City",
      "NEW_AIRPORT": "New Airport",
      "NEW_TOLL": "New Toll",
      "MANAGE_POPULAR_LOCATIONS": "Manage Popular Locations",
      "NEW_POPULAR_PACKAGE": "New Popular Package",
      "NEW_POPULAR_LOCATION": "New Popular Location",
      "MANAGE_VEHICLE_TYPES": "Manage Vehicle Types",
      "MANAGE_VEHICLE_ATTRIBUTES": "Manage vehicle Attributes",
      "NEW_VEHICLE": "New Vehicle",
      "RESET": "Reset",
      "NEW_TAX": "New Tax",
      "CREATE_FARE_CHARGE": "Create Fare Charge",
      "CLONE_FARE_CHARGE": "Clone Fare Charge",
      "NEW_TIMING": "New Timing",
      "NEW_VEHICLE_GROUP": "New Vehicle Group",
      "ENABLE": "Enable",
      "DISABLE": "Disable",
      "CREATE_KYC_CONFIGURATION": "Create KYC Configuration",
      "DETAILED_VIEW": "Detailed View",
      MONTHLY: "Monthly",
      "YEARLY":"Yearly"


    },
    PAGE_TITLE: {

    }
  },
};
