<div class="table-responsive mb-0">
    <table class="table table-centered table-nowrap">
      <thead class="thead-light">
        <tr>
          <th style="width: 20px;">
            <div class="custom-control custom-checkbox">
              <input id="customCheck" type="checkbox" class="custom-control-input" />
              <label class="custom-control-label" for="customCheck">&nbsp;</label>
            </div>
          </th>
          <th>Task ID</th>
          <th>Task Name</th>
          <th>Date</th>
          <th>Total</th>
          <th>Task Status</th>
          <th>Task Method</th>
          <th>View Details</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let data of transactions">
          <td>
            <div class="custom-control custom-checkbox">
              <input id="customCheck{{data.index}}" type="checkbox" class="custom-control-input" />
              <label class="custom-control-label" for="customCheck{{data.index}}">&nbsp;</label>
            </div>
          </td>
          <td>
            <a href="javascript: void(0);" class="text-body font-weight-bold">{{data.id}}</a>
          </td>
          <td>{{data.name}}</td>
          <td>{{data.date}}</td>
          <td>{{data.total}}</td>
          <td>
            <span class="badge badge-pill badge-soft-success font-size-12" [ngClass]=" { 'badge-soft-danger': data.status === 'Chargeback',
                          'badge-soft-warning':data.status === 'Refund' }">{{data.status}}</span>
          </td>
          <td>
            <i :class="`fab ${data.payment[0]} mr-1`"></i>
            {{data.payment[1]}}
          </td>
          <td>
            <!-- Button trigger modal -->
            <button type="button" class="btn btn-primary btn-sm btn-rounded" (click)="openModal(content)">View
              Details</button>
          </td>
        </tr>
      </tbody>
    </table>
  
    <ng-template #content role="document" let-modal>
      <div class="modal-header">
        <h5 class="modal-title mt-0">Order Details</h5>
        <button type="button" class="close" aria-hidden="true" (click)="modal.dismiss('Cross click')">×</button>
      </div>
      <div class="modal-body">
        <p class="mb-2">
          Product id:
          <span class="text-primary">#SK2540</span>
        </p>
        <p class="mb-4">
          Billing Name:
          <span class="text-primary">Neal Matthews</span>
        </p>
        <div class="table-responsive">
          <table class="table table-centered table-nowrap">
            <thead>
              <tr>
                <th scope="col">Product</th>
                <th scope="col">Product Name</th>
                <th scope="col">Price</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <th scope="row">
                  <div>
                    <img src="assets/images/icons/profile.png" alt class="avatar-sm" />
                  </div>
                </th>
                <td>
                  <div>
                    <h5 class="text-truncate font-size-14">Wireless Headphone (Black)</h5>
                    <p class="text-muted mb-0">$ 225 x 1</p>
                  </div>
                </td>
                <td>$ 255</td>
              </tr>
              <tr>
                <th scope="row">
                  <div>
                    <img src="assets/images/icons/profile.png" alt class="avatar-sm" />
                  </div>
                </th>
                <td>
                  <div>
                    <h5 class="text-truncate font-size-14">Phone patterned cases</h5>
                    <p class="text-muted mb-0">$ 145 x 1</p>
                  </div>
                </td>
                <td>$ 145</td>
              </tr>
              <tr>
                <td colspan="2">
                  <h6 class="m-0 text-right">Sub Total:</h6>
                </td>
                <td>$ 400</td>
              </tr>
              <tr>
                <td colspan="2">
                  <h6 class="m-0 text-right">Shipping:</h6>
                </td>
                <td>Free</td>
              </tr>
              <tr>
                <td colspan="2">
                  <h6 class="m-0 text-right">Total:</h6>
                </td>
                <td>$ 400</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div class="modal-footer">
        <button class="btn btn-secondary" (click)="modal.close('Close click')">Close</button>
      </div>
    </ng-template>
  </div>
  <!-- end table -->