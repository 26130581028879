import { Injectable, PipeTransform } from "@angular/core";

import { BehaviorSubject, Observable, of, Subject } from "rxjs";
import { debounceTime, delay, switchMap, tap, mergeMap, catchError, map } from "rxjs/operators";

import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { apiUrl } from '../../_config/api.config';
import { QueryParamsModel } from '../../_base/crud';
import { TollCategories } from "../_server/tollCategory.table";

const BASE_URL = environment.apiUrl;

@Injectable({
  providedIn: "root",
})

export class TollsService {

  constructor(private http: HttpClient) { }
 // Toll Category List
  getAllTollCategories(): Observable<TollCategories[]> {
    const data = TollCategories.tollCategories;
    return of(data);
  }
  //Get all countries
  getAllCountries(): Observable<any> {
    return this.http.get<any>(BASE_URL + apiUrl.country).pipe(
      map((res: any) => {
        if (res.response.code === 200) {
          return res.data.list;
        } else {
          return [];
        }
      }),
      catchError(err => {
        console.log(err);
        return [];
      })
    );
  }

  //Get all countries
  getAllStates(countryId): Observable<any> {
    return this.http.get<any>(BASE_URL + apiUrl.state + '?countryId=' + countryId).pipe(
      map((res: any) => {
        if (res.response.code === 200) {
          return res.data.list;
        } else {
          return [];
        }
      }),
      catchError(err => {
        console.log(err);
        return [];
      })
    );
  }

  //Get all countries
  getAllPincodes(stateName): Observable<any> {
    return this.http.get<any>(BASE_URL + apiUrl.postalCode +'?stateName=' + stateName).pipe(
      map((res: any) => {
        if (res.response.code === 200) {
          return res.data.list;
        } else {
          return [];
        }
      }),
      catchError(err => {
        console.log(err);
        return [];
      })
    );
  }

  // Get all zones with filter
  getAllZones(queryParams: QueryParamsModel): Observable<any> {
    return this.http.get<any>(BASE_URL + apiUrl.getAllZones +
    '?pageSize=' + queryParams.pageSize +
    '&pageNumber=' + queryParams.pageNumber +
    '&sortOrder=' + queryParams.sortOrder +
    '&sortField=' + queryParams.sortField +
    '&searchText=' + queryParams.searchText + queryParams.filter).pipe(
      mergeMap(res => {
        console.log(res);
        if (res.response.code === 200) {
          const result = {
            items: res.data.list,
            totalCount: res.data.totalCount
          };
          return of(result);
        } else {
          const result = {
            items: [],
            totalCount: 0
          };
          return of(result);
        }
      })
    );
  }

  // Get all zones without filter
  getZones(): Observable<any> {
    return this.http.get<any>(BASE_URL + apiUrl.getAllZones +'?isDelete=false&pageSize=1000000&pageNumber=1').pipe(
      map((res: any) => {
        if (res.response.code === 200) {
          return res.data.list;
        } else {
          return [];
        }
      }),
      catchError(err => {
        console.log(err);
        return [];
      })
    );
  }

  // Get single zone
  getZoneById(_id: string): Observable<any> {
    return this.http.get(BASE_URL + apiUrl.zone +'?zoneId=' + _id);
  }

  // Add new zone
  createZone(zone: any): Observable<any> {
    return this.http.post(BASE_URL + apiUrl.zone, zone);
  }

  // Update zone
  updateZone(zone: any): Observable<any> {
    return this.http.put(BASE_URL + apiUrl.zone, zone);
  }

  // Delete zone
  deleteZone(zone: any): Observable<any> {
    return this.http.delete(BASE_URL + apiUrl.zone +'?zoneId=' + zone._id + '&isDelete=' + zone.isDelete);
  }

  // Get all toll with filter
  getAllTolls(queryParams: QueryParamsModel): Observable<any> {
    return this.http.get<any>(BASE_URL + apiUrl.getAllTolls +
    '?pageSize=' + queryParams.pageSize +
    '&pageNumber=' + queryParams.pageNumber +
    '&sortOrder=' + queryParams.sortOrder +
    '&sortField=' + queryParams.sortField +
    '&searchText=' + queryParams.searchText + queryParams.filter).pipe(
      mergeMap(res => {
        console.log(res);
        if (res.response.code === 200) {
          const result = {
            items: res.data.list,
            totalCount: res.data.totalCount
          };
          return of(result);
        } else {
          const result = {
            items: [],
            totalCount: 0
          };
          return of(result);
        }
      })
    );
  }

  // Get all toll with filter
  getTolls(): Observable<any> {
    return this.http.get<any>(BASE_URL + apiUrl.getAllTolls + '?isDelete=false&pageSize=1000&pageNumber=1').pipe(
      map((res: any) => {
        if (res.response.code === 200) {
          return res.data.list;
        } else {
          return [];
        }
      }),
      catchError(err => {
        console.log(err);
        return [];
      })
    );
  }

  // Get single toll
  getTollById(_id: string): Observable<any> {
    return this.http.get(BASE_URL + apiUrl.toll +'?tollId=' + _id);
  }

  // Add new toll
  createToll(toll: any): Observable<any> {
    return this.http.post(BASE_URL + apiUrl.toll, toll);
  }

  // Update toll
  updateToll(toll: any): Observable<any> {
    return this.http.put(BASE_URL + apiUrl.toll, toll);
  }

  // Delete toll
  deleteToll(toll: any): Observable<any> {
    return this.http.delete(BASE_URL + apiUrl.toll +'?tollId=' + toll._id + '&isDelete=' + toll.isDelete);
  }
  getCities(): Observable<any> {
    return this.http.get<any>(BASE_URL + apiUrl.getAllCites + '?isDelete=false&pageSize=1000&pageNumber=1').pipe(
      map((res: any) => {
        if (res.response.code === 200) {
          return res.data.list;
        } else {
          return [];
        }
      }),
      catchError(err => {
        console.log(err);
        return [];
      })
    );
  }

}
