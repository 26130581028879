import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { QueryParamsModel, QueryResultsModel} from '../../_base/crud';
import { environment } from '../../../../environments/environment';
import { apiUrl } from '../../_config/api.config';
import { Router } from '@angular/router';
// Material
import { MatDialog } from '@angular/material/dialog';
// Partials for CRUD
import { RemarkEditorComponent } from '../../../views/partials/crud/remark-editor/remark-editor.component';
import { Remark } from '../../remark';

const BASE_URL = environment.apiUrl;

@Injectable()
export class RemarkService {
  constructor(private http: HttpClient,
    private dialog: MatDialog) {}

  // Show editor popup
  editorPopup(_remark: Remark) {
    return this.dialog.open(RemarkEditorComponent, {
      data: {
        remark: _remark
      },
      width: '650px'
    });
  }

  // Get all remarks
  getAllRemarks(query: string): Observable<any> {
    return this.http.get(BASE_URL + apiUrl.remark + query);
  }

  // Add new remark
  createRemark(remark: any): Observable<any> {
    return this.http.post(BASE_URL + apiUrl.remark, remark);
  }

  // Update remark
  updateRemark(remark: any): Observable<any> {
    return this.http.put(BASE_URL + apiUrl.remark, remark);
  }

  // Delete remark
  deleteRemark(remark: any): Observable<any> {
    return this.http.delete(BASE_URL + apiUrl.remark +'?_id=' + remark._id + '&isDelete=' + remark.isDelete);
  }
}
