// USA
export const locale = {
  lang: "hi",
  data: {
    "TRANSLATOR": {
      "SELECT": "Select your language",
      "LANGUAGE": "Language",
      "ENGLISH": "English",
      "HINDI": "Hindi",
      "KANNADA": "Kannada",
      "MALAYALAM": "Malayalam"
    },
    "AUTH": {
      "GENERAL": {
        "SIGNIN": "Sign In",
        "SIGNOUT": "Sign Out",
        "SIGNUP": "Create Account",
        "FORGOT_PASSWORD": "Forgot Password?",
        "RESET_PASSWORD": "Reset Password?",
        "TAGLINE": "The best of Guatemala is made as a team!",
        "BACKHOME": "Go back to",
        "HOME": "Home"
      },
    },
    "ERROR_PAGE": {
      "TITLE": "Oops!",
      "SUB_TITLE": "Something went wrong here.",
      "CONTENT": "We're working on it and we'll get it fixed as soon possible. You can back or use our Help Center."
    },
    "COMING_SOON": {
      "TITLE": "Page is under Construction.",
      "SUB_TITLE": "We're working on it and we'll get it fixedas soon possible.",
      "CONTENT": "You can back or use our Help Center."
    },
    Dashboard: {
      Dashboards: "डैशबोर्ड",
      Rides: "सवारी",
      Default: "डिफ़ॉल्ट",
      Operations: "संचालन",
      Driver: "ड्राइवर",
      Subscriptions: "सदस्यताएँ",
      Sales: "बिक्री",
      Partners: "साझेदार",
      Revenue: "राजस्व",
    },
    Manage: {
      Manage: "प्रबंधन",
      Admin: {
        Admin: "प्रशासन",
        Users: "उपयोगकर्ता",
        Roles: "भूमिकाएँ",
        "Work Flow": "कार्य प्रवाह",
      },
      Partners: {
        Partners: "साझेदार",
        Fleets: "फ्लीट्स",
        "Travel Desks": "यात्रा डेस्क",
        Corporates: "कॉरपोरेट्स",
      },
      "Revenue Model": {
        "Revenue Model": "राजस्व मॉडल",
        Subscriptions: "सदस्यताएँ",
        Commission: "कमीशन",
        "Platform Fee": "प्लेटफ़ॉर्म शुल्क",
      },
      "Billing & Invoicing": {
        "Billing & Invoicing": "बिलिंग और चालान",
        Billed: "बिल किया गया",
        Invoiced: "चालान किया गया",
      },
      Customers: {
        Customers: "ग्राहक",
        Drivers: "ड्राइवर",
        Riders: "यात्री",
        "Customer Groups": "ग्राहक समूह",
      },
      Subscriptions: {
        Subscriptions: "सदस्यताएँ",
        Packages: "पैकेज",
        Subscribers: "सदस्य",
      },
      Referral: {
        Referral: "संदर्भ",
        "Referral Promotions": "संदर्भ प्रचार",
        "Referral Settlement": "संदर्भ निपटान",
      },
      Offers: {
        Offers: "ऑफ़र",
        Promotions: "प्रचार",
        "Promotion Settlements": "प्रचार निपटान",
      },
      Wallet: {
        Wallet: "वॉलेट",
        "Wallet Transactions": "वॉलेट लेन-देन",
        "Withdrawal Requests": "निकासी अनुरोध",
      },
      Archived: {
        Archived: "संग्रहीत",
        Users: "उपयोगकर्ता",
        "Fleet Partners": "फ्लीट साझेदार",
        "Travel Desk Partners": "यात्रा डेस्क साझेदार",
        "Corporate Partners": "कॉरपोरेट साझेदार",
        "Archived Drivers": "संग्रहीत ड्राइवर",
        "Archived Riders": "संग्रहीत यात्री",
      },
      "Invited Users": {
        "Invited Users": "आमंत्रित उपयोगकर्ता",
        "Invited Drivers": "आमंत्रित ड्राइवर",
        "Invited Riders": "आमंत्रित यात्री",
      },
    },
    Rides: {
      Rides: "सवारी",
      "Book Ride": "सवारी बुक करें",
      "Pending Request": "लंबित अनुरोध",
    },
    Reports: {
      Reports: "रिपोर्ट",
      "Ride Report": "सवारी रिपोर्ट",

      Sales: "बिक्री",
      Revenue: "राजस्व",
    },
    "Customer Engagement": {
      "Customer Engagement": "ग्राहक सहभागिता",
      "Dynamic Sections": "गतिशील अनुभाग",
      "Ride Request Tips": "सवारी अनुरोध सुझाव",
    },
    "Admin Configurations": {
      "Admin Configurations": "प्रशासनिक विन्यास",
      "Master Data": {
        "Master Data": "मास्टर डेटा",
        Zones: "क्षेत्र",
        Cities: "शहर",
        Airports: "हवाई अड्डे",
        Tolls: "टोल",
        "Popular Packages": "लोकप्रिय पैकेज",
        Vehicles: "वाहन",
        Reasons: "कारण",
        Attributes: "गुण",
        Taxes: "कर",
      },
      "Control Panel": {
        "Control Panel": "नियंत्रण पैनल",
        "Trip Fare": "यात्रा किराया",
        "Commission Settlement": "कमीशन निपटान",
        "Tax & Invoicing": "कर और चालान",
        "Wallet Configuration": "वॉलेट कॉन्फ़िगरेशन",
        "Surge Charge": "अतिरिक्त शुल्क",
        "Vehicle Groups": "वाहन समूह",
        "Target Configuration": "लक्ष्य कॉन्फ़िगरेशन",
        "KYC Configuration": "KYC कॉन्फ़िगरेशन",
        "General Settings": "सामान्य सेटिंग्स",
      },
    },
    Logs: {
      Logs: "लॉग",
      "Audit Logs": "लेखा परीक्षा लॉग",
    },
    //common
    //Help
    "User Guide": "उपयोगकर्ता गाइड",
    FAQ: "सामान्य प्रश्न",
    Support: "सहायता",
    "Change Log": "परिवर्तन लॉग",
    //Tools
    "URL Shortner": "URL संक्षिप्तकर्ता",
    "Data Sync": "डेटा समन्वयन",
  }
};
