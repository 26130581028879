import { User } from '../../auth';
import { UploadFile } from '../../upload/_models/uploadFile.model';

export class Remark {
  _id: string;
  rideId: string;
  driverId: string;
  partnerId: string;
  riderId: string;
  userId: string;
  dataId: string;
  attachments: UploadFile[];
  comments: string;
  title: string;
  attachTime: string;
  whoId: string;
  who: User;
  __v: number;

  clear(): void {
    this._id = undefined;
    this.rideId = undefined;
    this.driverId = undefined;
    this.riderId = undefined;
    this.partnerId = undefined;
    this.userId = undefined;
    this.dataId = undefined;
    this.attachments = [];
    this.comments = '';
    this.title = '';
    this.attachTime = '';
    this.whoId = undefined;
    this.who = new User();
    this.who.clear();
    this.__v = 0;
  }
}
