export class MenuConfig {
	public defaults: any = {
		aside: {
			self: {},
			items: [
				{
					section: 'DASHBOARD.DASHBOARDS',
          permissions: ['defaultDashboard', 'driverDashboard', 'riderDashboard', 'partnerDashboard', 'ridesDashboard', 'salesDashboard', 'subscriptionsDashboard', 'revenueDashboard']
				},
				{
					title: 'DASHBOARD.DASHBOARDS',
					icon: 'bx-home-circle',
          permissions: ['defaultDashboard', 'driverDashboard', 'riderDashboard', 'partnerDashboard', 'ridesDashboard', 'salesDashboard', 'subscriptionsDashboard', 'revenueDashboard'],
          submenu: [
            {
							title: 'DASHBOARD.DEFAULT',
							page: '/dashboard/default',
              permission: 'defaultDashboard'
						},
            {
							title: 'DASHBOARD.DRIVER',
							page: '/dashboard/driver',
              permission: 'driverDashboard'
						},
            {
							title: 'DASHBOARD.RIDER',
							page: '/dashboard/rider',
              permission: 'riderDashboard'
						},
            {
              title: 'DASHBOARD.PARTNERS',
              page: '/dashboard/partners',
              permission: 'partnerDashboard'
            },
            {
              title: 'DASHBOARD.RIDES',
              page: '/dashboard/rides',
              permission: 'ridesDashboard',
              badge: {
                variant: 'danger',
                text: 'Beta'
              },
            },
						{
							title: 'DASHBOARD.SALES',
							page: '/dashboard/sales',
              permission: 'salesDashboard'
            },
						{
							title: 'DASHBOARD.SUBSCRIPTIONS',
							page: '/dashboard/subscriptions',
              permissions: ['subscriptionsDashboard']
						},
            {
							title: 'DASHBOARD.REVENUE',
							page: '/dashboard/revenue',
							permission: 'revenueDashboard'
						},
					]
				},
				{
					section: 'MANAGE.MANAGE',
          permissions: ['userManagement', 'roleManagement', 'workflowManagement', 'driverManagement', 'riderManagement', 'customerGroupManagement', 'fleetPartnerManagement', 'travelDeskPartnerManagement', 'corporatePartnerManagement', 'subscriptionManagement', 'commissionManagement', 'platformFeeManagement', 'walletManagement', 'invoiceManagement', 'invitedUsersManagement', 'archivedUsersManagement']
        },
        {
					title: 'MANAGE.ADMIN.ADMIN',
					icon: 'bx-user',
          permissions: ['userManagement', 'roleManagement', 'workflowManagement'],
					submenu: [
						{
							title: 'MANAGE.ADMIN.USERS',
							page: '/admin/users',
              permission: 'userManagement'
						},
						{
							title: 'MANAGE.ADMIN.ROLES',
							page: '/admin/roles',
              permission: 'roleManagement'
						},
						{
							title: 'MANAGE.ADMIN.WORK_FLOW',
							page: '/admin/work-flows',
              permission: 'workflowManagement'
						}
					]
				},
        {
          title: 'MANAGE.CUSTOMERS.CUSTOMERS',
          icon: 'bx-face',
          permissions: ['driverManagement', 'riderManagement', 'customerGroupManagement'],
          submenu: [
            {
              title: 'MANAGE.CUSTOMERS.DRIVERS',
              icon: 'bx-taxi',
              page: '/driver-management',
              permission: 'driverManagement'
            },
            {
              title: 'MANAGE.CUSTOMERS.RIDERS',
              icon: 'bx-briefcase',
              page: '/rider-management',
              permission: 'riderManagement'
            },
            {
              title: 'MANAGE.CUSTOMERS.CUSTOMER_GROUPS',
              icon: 'bx-group',
              page: '/customer-groups',
              permission: 'customerGroupManagement'
            },
          ]
        },
        {
          title: 'MANAGE.PARTNERS.PARTNERS',
          icon: 'bx-taxi',
          permissions: ['fleetPartnerManagement', 'travelDeskPartnerManagement', 'corporatePartnerManagement'],
          submenu: [
            {
              title: 'MANAGE.PARTNERS.FLEETS',
              icon: 'bx-taxi',
              page: '/partners/fleets',
              permission: 'fleetPartnerManagement'
            },
            {
              title: 'MANAGE.PARTNERS.TRAVEL_DESK',
              icon: 'bx-briefcase',
              page: '/partners/travel-desks',
              permission: 'travelDeskPartnerManagement'
            },
            {
              title: 'MANAGE.PARTNERS.CORPORATES',
              icon: 'bx-group',
              page: '/partners/corporates',
              permission: 'corporatePartnerManagement'
            },
          ]
        },
				{
					title: 'MANAGE.REVENUE_MODEL.REVENUE_MODEL',
					icon: 'bx-money',
          permissions: ['subscriptionManagement', 'commissionManagement', 'platformFeeManagement'],
          submenu: [
            {
              title: 'MANAGE.REVENUE_MODEL.SUBSCRIPTIONS',
              page: '/revenue-model/subscriptions',
              permission: 'subscriptionManagement'
            },
            {
              title: 'MANAGE.REVENUE_MODEL.COMMISSION',
              page: '/revenue-model/commission',
              permission: 'commissionManagement'
            },
            {
              title: 'MANAGE.REVENUE_MODEL.PLATFORM_FEE',
              page: '/revenue-model/platform-fee',
              permission: 'platformFeeManagement'
            }
          ]
				},
        {
          title: 'MANAGE.WALLET.WALLET',
          icon: 'bx-wallet',
          permissions: ['walletManagement'],
          submenu: [
            {
              title: 'MANAGE.WALLET.WALLET_TRANSACTIONS',
              page: '/wallet/wallet-transactions',
              permission: 'walletManagement',
              badge: {
                variant: 'warning',
                text: 'New'
              },
            },
            {
              title: 'MANAGE.WALLET.WITHDRAWAL_REQUESTS',
              page: '/wallet/withdrawal-request',
              permission: 'walletManagement',
              // badge: {
              //   variant: 'info',
              //   text: 'Supr!'
              // },
            },
          ]
        },
        {
          title: 'MANAGE.BILLING_INVOICING.BILLING_INVOICING',
          icon: 'bx-store-alt',
          permissions: ['invoiceManagement'],
          submenu: [
            {
              title: 'MANAGE.BILLING_INVOICING.BILLED',
              page: '/billing/billed',
              permission: 'invoiceManagement'
            },
            {
              title: 'MANAGE.BILLING_INVOICING.INVOICED',
              page: '/billing/invoiced',
              permission: 'invoiceManagement'
            },
          ]
        },
        {
          title: 'MANAGE.INVITED_USERS.INVITED_USERS',
          icon: 'bx-paper-plane',
          permissions: ['invitedUsersManagement'],
          submenu: [
            {
              title: 'MANAGE.INVITED_USERS.INVITED_DRIVERS',
              page: '/invited/drivers',
              permission: 'invitedUsersManagement'
            },
            {
              title: 'MANAGE.INVITED_USERS.INVITED_RIDERS',
              page: '/invited/riders',
              permission: 'invitedUsersManagement'
            }
          ]
        },
        {
					title: 'MANAGE.ARCHIVED.ARCHIVED',
					icon: 'bx-archive',
          permissions: ['archivedUsersManagement'],
					submenu: [
						{
							title: 'MANAGE.ARCHIVED.USERS',
							page: '/archived/users',
              permission: 'archivedUsersManagement'
						},
            {
              title: 'MANAGE.ARCHIVED.DRIVERS',
              page: '/archived/drivers',
              permission: 'archivedUsersManagement'
            },
            {
              title: 'MANAGE.ARCHIVED.RIDERS',
              page: '/archived/riders',
              permission: 'archivedUsersManagement'
            },
						{
							title: 'MANAGE.ARCHIVED.FLEETS',
							page: '/archived/fleets',
              permission: 'archivedUsersManagement'
						},
						{
							title: 'MANAGE.ARCHIVED.TRAVEL_DESKS',
							page: '/archived/travel-desks',
              permission: 'archivedUsersManagement'
						},
            {
              title: 'MANAGE.ARCHIVED.CORPORATES',
              page: '/archived/corporates',
              permission: 'archivedUsersManagement'
            },
					]
				},
        {
          section: 'CUSTOMER_ENGAGEMENT.CUSTOMER_ENGAGEMENT',
          badge: {
            variant: 'success',
            text: 'Engage'
          },
          permissions: ['referralManagement', 'promotionManagement', 'customerEngagementSections', 'customerEngagementVideos', 'customerEngagementTips']
        },
        {
          title: 'MANAGE.REFERRAL.REFERRAL',
          icon: 'bx-user-voice',
          permissions: ['referralManagement'],
          badge: {
            variant: 'success',
            text: 'New'
          },
          submenu: [
            {
              title: 'MANAGE.REFERRAL.REFERRAL_PROMOTIONS',
              page: '/referral-management/promotions',
              badge: {
                variant: 'warning',
                text: 'v3.1'
              },
              permission: 'referralManagement'
            },
            {
              title: 'MANAGE.REFERRAL.REFERRAL_SETTLEMENT',
              page: '/referral-management/referrals',
              permission: 'referralManagement'
            },
          ]
        },
        {
          title: 'MANAGE.OFFERS.OFFERS',
          icon: 'bx-gift',
          permissions: ['promotionManagement'],
          submenu: [
            {
              title: 'MANAGE.OFFERS.PROMOTIONS',
              page: '/offer-management/promotions',
              permission: 'promotionManagement',
              badge: {
                variant: 'info',
                text: 'v4.0'
              },
            },
            {
              title: 'MANAGE.OFFERS.PROMOTION_SETTLEMENTS',
              page: '/offer-management/promotion-settlements',
              permission: 'promotionManagement'
            },
          ]
        },
        {
          title: 'CUSTOMER_ENGAGEMENT.DYNAMIC_SECTIONS',
          icon: 'bx-image-alt',
          page: '/customer-engagement/sections',
          badge: {
            variant: 'warning',
            text: 'Whooo!'
          },
          permission: 'customerEngagementSections'
        },
        {
          title: 'Training Videos',
          icon: 'bxs-videos',
          page: '/customer-engagement/videos',
          badge: {
            variant: 'warning',
            text: 'New'
          },
          permission: 'customerEngagementVideos'
        },
        {
          title: 'CUSTOMER_ENGAGEMENT.RIDE_REQUEST_TIPS',
          icon: 'bx-bulb',
          page: '/customer-engagement/tips',
          // badge: {
          //   variant: 'warning',
          //   text: 'New'
          // }
          permission: 'customerEngagementTips'
        },
				{
					section: 'RIDES.RIDES',
          permissions: ['ridesManagement', 'pendingRequestManagement']
				},
        {
          title: 'RIDES.BOOK_RIDE',
          icon: 'bx-task',
          page: '/ride-reports/book-ride',
          permission: 'ridesManagementAdd'
        },
        {
					title: 'RIDES.RIDES',
					icon: 'bx-cycling',
					page: '/ride-reports',
          permission: 'ridesManagement'
        },
        {
          title: 'RIDES.PENDING_RIDES',
          icon: 'bx-history',
          page: '/ride-reports/pending-rides',
          permission: 'pendingRequestManagement'
        },
        {
					section: 'REPORTS.REPORTS',
          permissions: ['salesReportManagement', 'revenueReportManagement']
				},

        {
          title: 'REPORTS.SALES',
          icon: 'bx-body',
          page: '/sales',
          permission: 'salesReportManagement'
        },
        {
					title: 'REPORTS.REVENUE',
					icon: 'bx-line-chart',
					page: '/revenue',
          permission: 'revenueReportManagement'
				},
				{
					section: 'ADMIN_CONFIGURATIONS.ADMIN_CONFIGURATIONS',
          permissions: ['locationManagement', 'vehicleAttributesManagement', 'reasonsManagement', 'attributesManagement', 'amenitiesManagement', 'taxManagement', 'tripFareSettings', 'taxInvoiceSettings', 'walletConfiguration', 'surgeChargeSettings', 'vehicleGroupManagement', 'targetConfiguration', 'kycConfiguration', 'generalSettings']
				},
				{
					title: 'ADMIN_CONFIGURATIONS.MASTER_DATA.MASTER_DATA',
					icon: 'bx-data',
          permissions: ['locationManagement', 'vehicleAttributesManagement', 'reasonsManagement', 'attributesManagement', 'amenitiesManagement', 'taxManagement'],
					submenu: [
						{
							title: 'ADMIN_CONFIGURATIONS.MASTER_DATA.ZONES',
							page: '/master-data/zones',
							permission: 'locationManagement'
						},
						{
							title: 'ADMIN_CONFIGURATIONS.MASTER_DATA.CITIES',
							page: '/master-data/cities',
							permission: 'locationManagement'
						},
						{
							title: 'ADMIN_CONFIGURATIONS.MASTER_DATA.AIRPORTS',
							page: '/master-data/airports',
							permission: 'locationManagement'
						},
            {
              title: 'ADMIN_CONFIGURATIONS.MASTER_DATA.TOLLS',
              page: '/master-data/tolls',
              permission: 'locationManagement'
            },
            {
              title: 'ADMIN_CONFIGURATIONS.MASTER_DATA.POPULAR_PACKAGES',
			        page: '/master-data/popular-packages',
              permissions: ['locationManagement'],
              badge: {
                variant: 'warning',
                text: 'v1.0'
              },
            },
						{
							title: 'ADMIN_CONFIGURATIONS.MASTER_DATA.VEHICLES',
							page: '/master-data/vehicles',
              permission: 'vehicleAttributesManagement'
						},
						{
							title: 'ADMIN_CONFIGURATIONS.MASTER_DATA.REASONS',
							page: '/master-data/reasons',
							permission: 'reasonsManagement'
						},
            {
              title: 'ADMIN_CONFIGURATIONS.MASTER_DATA.ATTRIBUTES',
              page: '/master-data/attributes',
              permission: 'attributesManagement'
            },
						{
							title: 'ADMIN_CONFIGURATIONS.MASTER_DATA.AMENITIES',
              page: '/master-data/amenities',
              permission: 'amenitiesManagement'
						},
						{
							title: 'ADMIN_CONFIGURATIONS.MASTER_DATA.TAXES',
              page: '/master-data/taxes',
              permission: 'taxManagement'
						}
					]
				},
				{
					title: 'ADMIN_CONFIGURATIONS.CONTROL_PANEL.CONTROL_PANEL',
					icon: 'bx-cog',
          permissions: ['tripFareSettings', 'taxInvoiceSettings', 'walletConfiguration', 'surgeChargeSettings', 'vehicleGroupManagement', 'targetConfiguration', 'kycConfiguration', 'generalSettings'],
					submenu: [
						{
							title: 'ADMIN_CONFIGURATIONS.CONTROL_PANEL.TRIP_FARE',
							page: '/control-panel/fare-charge',
              permission: 'tripFareSettings'
						},
            {
              title: 'ADMIN_CONFIGURATIONS.CONTROL_PANEL.TAX&INVOICING',
              page: '/control-panel/tax-invoice',
              permission: 'taxInvoiceSettings'
            },
            {
            	title: 'ADMIN_CONFIGURATIONS.CONTROL_PANEL.WALLET_CONFIGURATION',
            	page: '/control-panel/wallet-configuration',
              permission: 'walletConfiguration'
            },
						{
							title: 'ADMIN_CONFIGURATIONS.CONTROL_PANEL.SURGE_CHARGE',
							page: '/control-panel/surge-charge',
              permission: 'surgeChargeSettings'
						},
            {
              title: 'ADMIN_CONFIGURATIONS.CONTROL_PANEL.VEHICLE_GROUPS',
              page: '/control-panel/vehicle-groups',
              permission: 'vehicleGroupManagement'
            },
            {
              title: 'ADMIN_CONFIGURATIONS.CONTROL_PANEL.TARGET_CONFIGURATION',
              page: '/control-panel/target-configuration',
              permission: 'targetConfiguration'
            },
            {
              title: 'ADMIN_CONFIGURATIONS.CONTROL_PANEL.KYC_CONFIGURATION',
              page: '/control-panel/kyc-configuration',
              permission: 'kycConfiguration'
            },
            {
              title: 'ADMIN_CONFIGURATIONS.CONTROL_PANEL.GENERAL_SETTINGS',
              page: '/control-panel/general-settings',
              permission: 'generalSettings'
            },
					]
				},
				{
					section: 'LOGS.LOGS',
          permissions: ['logsManagement']
				},
				{
					title: 'LOGS.AUDIT_LOGS',
					icon: 'bx-file',
					page: '/logs/audit-logs',
          permission: 'logsManagement'
				},
			]
		},
	};

	public get configs(): any {
		return this.defaults;
	}
}
