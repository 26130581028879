// Angular
import { Component, Inject, OnInit, ChangeDetectorRef, NgZone, OnDestroy, ViewChild, ElementRef } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

// Maps
//import { MouseEvent } from '@agm/core';

import { fromEvent, merge, Subscription, of, Observable, BehaviorSubject } from 'rxjs';
import { distinctUntilChanged, skip, debounceTime, tap } from 'rxjs/operators';

// Services
import { GoogleMapsLoaderService, LayoutUtilsService, MessageType, QueryParamsModel } from '../../../../core/_base/crud/';

// Environment Variables
import { environment } from '../../../../../environments/environment';
import { OpenStreetMapService, GoogleMapService } from "../../../../core/map-services";

import { v4 as uuidv4 } from 'uuid';


@Component({
  selector: 'app-location-picker',
  templateUrl: './location-picker.component.html',
  styleUrls: ['./location-picker.component.scss']
})
export class LocationPickerComponent implements OnInit, OnDestroy {

  zoom: number = 15;
  private geoCoder;
  mapType: any = "roadmap";
  mapStore: any;
  private subscriptions: Subscription[] = [];

  location: any = {
    lat: undefined,
    long: undefined,
    address: '',
    additionalInfo: ''
  };

  mapOptions: google.maps.MapOptions = {
    fullscreenControl: false,
    streetViewControl: false,
    zoomControl: true,
    disableDefaultUI: false,
    mapTypeControl: false
  };
  markerOptions: google.maps.MarkerOptions = {draggable:true};

  uuid: string;

  @ViewChild('search', { static: false }) searchElementRef: ElementRef;
  placesPrediction: any[] = [];
  isMapLoading: boolean;

  constructor(
    public dialogRef: MatDialogRef<LocationPickerComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private cdr: ChangeDetectorRef,
    //private mapsAPILoader: MapsAPILoader,
    private osmService: OpenStreetMapService,
    private gsmService: GoogleMapService,
    private ngZone: NgZone,
    private layoutService: LayoutUtilsService,
    private googleMapsLoader: GoogleMapsLoaderService
  ) { }

  ngOnInit() {
    this.isMapLoading = true;

    this.googleMapsLoader
    .load(environment.googleApiKey, ['geometry', 'drawing'], 'en')
    .then(() => {
      console.log('<script> tag successfully loaded!');
      this.isMapLoading = false;
    })
    .catch((error) => console.error('Error loading script:', error));
    this.generateV4UUID();
    this.location = JSON.parse(JSON.stringify(Object.assign({}, this.location, this.data.data)));
    console.log(this.location);
    this.cdr.detectChanges();

    if (!(this.location?.lat && this.location?.long) && this.mapStore) {
      this.currentLocation();
    }

    const searchSubscription = fromEvent(this.searchElementRef.nativeElement, 'input').pipe(
      debounceTime(600),
      distinctUntilChanged(),
      tap(($event: any) => {
        console.log($event.target.value);
        // this.subscriptions.push(this.osmService.getPlacesList($event.target.value).subscribe(res => {
        //   this.placesPrediction = res;
        //   this.cdr.detectChanges();
        // }));

        if ($event.target.value.length > 0) {
          let query = {
            input: $event.target.value,
            sessiontoken: this.uuid,
            originLat: this.location.lat,
            originLong: this.location.long,
            components: [
              'country:IN'
            ],
            types: [],
            language: 'en',
            radius: 50000,
          }

          this.subscriptions.push(
            this.gsmService.getAutoComplete(query).subscribe(res => {
            this.placesPrediction = res.predictions;
            console.log(res);
            this.cdr.detectChanges();
          },
            error => {
              console.log(error);
              this.placesPrediction = [];
              this.cdr.detectChanges();
            }));
        }else {
          this.placesPrediction = [];
        }
      })
    ).subscribe();
    this.subscriptions.push(searchSubscription);
  }

  generateV4UUID() {
    this.uuid = uuidv4();
  }

  ngOnDestroy() {
    this.layoutService.showAlert();
    this.subscriptions.forEach(el => el.unsubscribe());
  }

  onMapReady(map) {
    this.mapStore = map;
    if (!(this.location?.lat && this.location?.long) && this.mapStore) {
      this.currentLocation();
    }
  }

  onSubmit() {
    this.dialogRef.close(this.location);
  }

  closeDialog() {
    this.dialogRef.close();
  }

  getTitle() {
    if (this.data?.type == 'pickup') {
      return 'Select Pickup Location';
    } else {
      return 'Select Dropoff Location';
    }
  }

  onPredictionSelect(item, mapType) {
    if (mapType == 'osm') {
      this.location.lat = parseFloat(item.lat);
      this.location.long = parseFloat(item.lon);
      //this.location.address = item?.display_name;
      //this.location.additionalInfo = item?.address;
      this.getAddress(this.location.lat, this.location.long);
      //this.searchElementRef.nativeElement.value = item.display_name;
      this.zoom = 13;
    }else if (mapType == 'gsm') {
      this.subscriptions.push(this.gsmService.getDetailsByPlaceId(item.place_id, this.uuid).subscribe(res => {
        console.log(res);
        if (res.response.code == 200) {
          this.location.lat = res.data?.result?.geometry?.location?.lat;
          this.location.long = res.data?.result?.geometry?.location?.lng;
          this.location.address = res.data?.result?.formatted_address;
          this.location.additionalInfo = res.data?.result?.address_components;
          this.searchElementRef.nativeElement.value = this.location.address;
        }
        this.generateV4UUID();
        this.cdr.detectChanges();
      }));
    }
    this.placesPrediction = [];
    this.cdr.detectChanges();
  }

  markerDragEnd($event: google.maps.MapMouseEvent) {
    console.log($event);
    this.location = {
      lat: $event.latLng.lat(),
      long: $event.latLng.lng(),
      address: this.location.address,
      additionalInfo: this.location.additionalInfo
    }
    this.getAddress(this.location.lat, this.location.long);
  }

  currentLocation() {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((position) => {
        this.location.lat = position.coords.latitude;
        this.location.long = position.coords.longitude;
        this.getAddress(this.location.lat, this.location.long);
        this.zoom = 13;
      },
        error => {
          this.layoutService.showActionNotification(MessageType.warning, "Location Access Required", "Location access is not enabled on your browser.");
        });
    } else {
      this.layoutService.showActionNotification(MessageType.danger, "Unsupported!", "Geolocation is not supported by this browser.");
    }
  }


  getAddress(latitude, longitude) {
    this.geoCoder = new google.maps.Geocoder;
    this.geoCoder.accessibilityLanguage = "en-US";
    this.geoCoder.geocode({ 'location': { lat: latitude, lng: longitude } }, (results, status) => {
      if (status === 'OK') {
        if (results[0]) {
          console.log(results[0]);
          this.location.address = results[0].formatted_address;
          this.location.additionalInfo = results[0].address_components;
          this.searchElementRef.nativeElement.value = this.location.address;
        } else {
          window.alert('');
          this.layoutService.showActionNotification(MessageType.warning, "No results found", "No location found");
        }
      } else if (status === 'OVER_QUERY_LIMIT') {
        this.layoutService.showActionNotification(MessageType.warning, 'Limit Exceeds!', 'Request exceeds limit. Please try again after some time.');
      } else {
        this.layoutService.showActionNotification(MessageType.danger, 'Geocoder Error!', 'Geocoder failed due to: ' + status);
      }
    });
  }
}
