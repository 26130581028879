// USA
export const locale = {
  lang: "kn",
  data: {
    "TRANSLATOR": {
      "SELECT": "Select your language",
      "LANGUAGE": "Language",
      "ENGLISH": "English",
      "HINDI": "Hindi",
      "KANNADA": "Kannada",
      "MALAYALAM": "Malayalam"
    },
    "AUTH": {
      "GENERAL": {
        "SIGNIN": "Sign In",
        "SIGNOUT": "Sign Out",
        "SIGNUP": "Create Account",
        "FORGOT_PASSWORD": "Forgot Password?",
        "RESET_PASSWORD": "Reset Password?",
        "TAGLINE": "The best of Guatemala is made as a team!",
        "BACKHOME": "Go back to",
        "HOME": "Home"
      },
    },
    "ERROR_PAGE": {
      "TITLE": "Oops!",
      "SUB_TITLE": "Something went wrong here.",
      "CONTENT": "We're working on it and we'll get it fixed as soon possible. You can back or use our Help Center."
    },
    "COMING_SOON": {
      "TITLE": "Page is under Construction.",
      "SUB_TITLE": "We're working on it and we'll get it fixedas soon possible.",
      "CONTENT": "You can back or use our Help Center."
    },
    Dashboard: {
      Dashboards: "ಡ್ಯಾಶ್‌ಬೋರ್ಡ್‌ಗಳು",
      Rides: "ಸವಾರಿಗಳು",
      Default: "ಡೀಫಾಲ್ಟ್",
      Operations: "ಕಾರ್ಯಾಚರಣೆಗಳು",
      Driver: "ಚಾಲಕ",
      Subscriptions: "ಚಂದಾದಾರಿಕೆಗಳು",
      Sales: "ಮಾರಾಟ",
      Partners: "ಪಾಲುದಾರರು",
      Revenue: "ಆದಾಯ",
    },
    Manage: {
      Manage: "ನಿಯಂತ್ರಣೆ",
      Admin: {
        Admin: "ನಿರ್ವಹಣೆ",
        Users: "ಬಳಕೆದಾರರು",
        Roles: "ಪಾತ್ರಗಳು",
        "Work Flow": "ಕೆಲಸದ 흐ರಣೆ",
      },
      Partners: {
        Partners: "ಪಾಲುದಾರರು",
        Fleets: "ಫ್ಲೀಟ್‌ಗಳು",
        "Travel Desks": "ಪ್ರವಾಸ ಡೆಸ್ಕ್‌ಗಳು",
        Corporates: "ನಿಗಮಗಳು",
      },
      "Revenue Model": {
        "Revenue Model": "ಆದಾಯ ಮಾದರಿ",
        Subscriptions: "ಚಂದಾದಾರಿಕೆಗಳು",
        Commission: "ಕಮಿಷನ್",
        "Platform Fee": "ವೆಬ್‌ಪ್ಲಾಟ್‌ಫಾರ್ಮ್ ಶುಲ್ಕ",
      },
      "Billing & Invoicing": {
        "Billing & Invoicing": "ಬಿಲ್ಲಿಂಗ್ ಮತ್ತು ಸರಕುಪಟ್ಟಿ",
        Billed: "ಬಿಲ್ ಮಾಡಲಾದ",
        Invoiced: "ಸರಕುಪಟ್ಟಿ ನೀಡಲಾದ",
      },
      Customers: {
        Customers: "ಗ್ರಾಹಕರು",
        Drivers: "ಚಾಲಕರು",
        Riders: "ಸವಾರಿಗಳು",
        "Customer Groups": "ಗ್ರಾಹಕ ಗುಂಪುಗಳು",
      },
      Subscriptions: {
        Subscriptions: "ಚಂದಾದಾರಿಕೆಗಳು",
        Packages: "ಪ್ಯಾಕೇಜ್‌ಗಳು",
        Subscribers: "ಚಂದಾದಾರರು",
      },
      Referral: {
        Referral: "ರೆಫರಲ್",
        "Referral Promotions": "ರೆಫರಲ್ ಪ್ರಚಾರಗಳು",
        "Referral Settlement": "ರೆಫರಲ್ ಪರಿಹಾರ",
      },
      Offers: {
        Offers: "ಆಫರ್‌ಗಳು",
        Promotions: "ಪ್ರಚಾರಗಳು",
        "Promotion Settlements": "ಪ್ರಚಾರ ಪರಿಹಾರಗಳು",
      },
      Wallet: {
        Wallet: "ವಾಲೆಟ್",
        "Wallet Transactions": "ವಾಲೆಟ್ ವಹಿವಾಟುಗಳು",
        "Withdrawal Requests": "ಹಿಂತೆಗೆದುಕೊಳ್ಳುವ ವಿನಂತಿಗಳು",
      },
      Archived: {
        Archived: "ಅಭಿಲೇಖಿತ",
        Users: "ಬಳಕೆದಾರರು",
        "Fleet Partners": "ಫ್ಲೀಟ್ ಪಾಲುದಾರರು",
        "Travel Desk Partners": "ಪ್ರವಾಸ ಡೆಸ್ಕ್ ಪಾಲುದಾರರು",
        "Corporate Partners": "ನಿಗಮ ಪಾಲುದಾರರು",
        "Archived Drivers": "ಅಭಿಲೇಖಿತ ಚಾಲಕರು",
        "Archived Riders": "ಅಭಿಲೇಖಿತ ಸವಾರಿಗಳು",
      },
      "Invited Users": {
        "Invited Users": "ಆಮಂತ್ರಿತ ಬಳಕೆದಾರರು",
        "Invited Drivers": "ಆಮಂತ್ರಿತ ಚಾಲಕರು",
        "Invited Riders": "ಆಮಂತ್ರಿತ ಸವಾರಿಗಳು",
      },
    },
    Rides: {
      Rides: "ಸವಾರಿಗಳು",
      "Book Ride": "ಸವಾರಿ ಬುಕ್ ಮಾಡಿ",
      "Pending Request": "ಬಾಕಿ ಮನವಿ",
    },
    Reports: {
      Reports: "ವರದಿಗಳು",
      "Ride Report": "ಸವಾರಿ ವರದಿ",

      Sales: "ಮಾರಾಟ",
      Revenue: "ಆದಾಯ",
    },
    "Customer Engagement": {
      "Customer Engagement": "ಗ್ರಾಹಕ ಸಂಪರ್ಕ",
      "Dynamic Sections": "ಡೈನಾಮಿಕ್ ವಿಭಾಗಗಳು",
      "Ride Request Tips": "ಸವಾರಿ ವಿನಂತಿ ಸಲಹೆಗಳು",
    },
    "Admin Configurations": {
      "Admin Configurations": "ನಿರ್ವಹಣೆ ಸಂರಚನೆಗಳು",
      "Master Data": {
        "Master Data": "ಮಾಸ್ಟರ್ ಡೇಟಾ",
        Zones: "ವಲಯಗಳು",
        Cities: "ನಗರಗಳು",
        Airports: "ವಿಮಾನ ನಿಲ್ದಾಣಗಳು",
        Tolls: "ಟೋಲ್‌ಗಳು",
        "Popular Packages": "ಜನಪ್ರಿಯ ಪ್ಯಾಕೇಜ್‌ಗಳು",
        Vehicles: "ವಾಹನಗಳು",
        Reasons: "ಕಾರಣಗಳು",
        Attributes: "ಗುಣಲಕ್ಷಣಗಳು",
        Taxes: "ತೆರಿಗೆಗಳು",
      },
      "Control Panel": {
        "Control Panel": "ನಿಯಂತ್ರಣ ಫಲಕ",
        "Trip Fare": "ಪ್ರಯಾಣ ಭದ್ರತೆ",
        "Commission Settlement": "ಕಮಿಷನ್ ಪರಿಹಾರ",
        "Tax & Invoicing": "ತೆರಿಗೆ ಮತ್ತು ಸರಕುಪಟ್ಟಿ",
        "Wallet Configuration": "ವಾಲೆಟ್ ಸಂರಚನೆ",
        "Surge Charge": "ಸರ್ಜ್ ಶುಲ್ಕ",
        "Vehicle Groups": "ವಾಹನ ಗುಂಪುಗಳು",
        "Target Configuration": "ಗುರಿ ಸಂರಚನೆ",
        "KYC Configuration": "KYC ಸಂರಚನೆ",
        "General Settings": "ಸಾಮಾನ್ಯ ಸಂಯೋಜನೆಗಳು",
      },
    },
    Logs: {
      Logs: "ಲಾಗ್‌ಗಳು",
      "Audit Logs": "ಆಡಿಟ್ ಲಾಗ್‌ಗಳು",
    },
    //common
    //Help
    "User Guide": "ಬಳಕೆದಾರ ಮಾರ್ಗದರ್ಶಿ",
    FAQ: "ತೊಡಕು ಪ್ರಶ್ನೆಗಳು",
    Support: "ಬೆಂಬಲ",
    "Change Log": "ಬದಲಾವಣೆ ದಿನಚರಿ",
    //Tools
    "URL Shortner": "URL ಸಣ್ಣಗೊಳಿಸುವುದು",
    "Data Sync": "ಮಾಹಿತಿ ಸಮನ್ವಯ",
  }
};
