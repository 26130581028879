import { Injectable } from '@angular/core';
import { Observable, of} from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { catchError, map, mergeMap, take } from 'rxjs/operators';
import { environment } from '../../../../environments/environment';
import { apiUrl } from '../../_config/api.config';

const BASE_URL = environment.apiUrl;

@Injectable({
  providedIn: "root"
})

export class UploadService {
  constructor(private http: HttpClient) {}

  // Multipart file upload
  uploadFile(formData): Observable<any> {
   	const httpOptions = {
      headers: new HttpHeaders({
        'token':localStorage.getItem(environment.authTokenKey),
        'contentType': 'multipart/form-data'
      })
    };
    return this.http.post(BASE_URL+apiUrl.uploadFile, formData);
  }

  // Get file url using upload id
  getUploadedFile(fileUploadId: string): Observable<any> {
    return this.http.get(BASE_URL+ apiUrl.getUploadedFile +'?fileUploadId='+fileUploadId);
  }

  // Get file url using upload id
  getBase64FromUrl(imageUrl: string) {
    //var url = 'https://dots-files-images-dev.s3.ap-south-1.amazonaws.com/1664450206451-1664450205724.png?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIATHUSH3665BBYDL65%2F20221011%2Fap-south-1%2Fs3%2Faws4_request&X-Amz-Date=20221011T070151Z&X-Amz-Expires=604800&X-Amz-Signature=ee5a0035616bda0438486b2e2ee76b09db51c8b437f1982a3897bf8da754f3ac&X-Amz-SignedHeaders=host';
    return this.http.get(imageUrl, {
      observe: 'body',
      headers: new HttpHeaders({
        'Accept': '*/*',
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Headers': 'X-Requested-With,content-type'
      }),
      responseType: 'arraybuffer',
    })
      .pipe(
        take(1),
        map((arrayBuffer) =>
          btoa(
            Array.from(new Uint8Array(arrayBuffer))
              .map((b) => String.fromCharCode(b))
              .join('')
          )
        ),
      )
  }

}
