// Angular
import { Component, OnDestroy, OnInit, ChangeDetectorRef, Input, Output, EventEmitter, ViewChild, ElementRef } from '@angular/core';
// RxJS
import { Subscription, fromEvent } from 'rxjs';
import {
  debounceTime,
  distinctUntilChanged,
  tap,
} from "rxjs/operators";

@Component({
  selector: 'app-search-panel',
  templateUrl: './search-panel.component.html',
  styleUrls: ['./search-panel.component.scss']
})
export class SearchPanelComponent implements OnInit, OnDestroy {

  @Input() searchText: string;
  @Input() placeHolder: string;
  @Input() info: string;
  @Output() searchTextChange: EventEmitter<any> = new EventEmitter();
  @Output() reload: EventEmitter<any> = new EventEmitter();

  @ViewChild("searchInput", { static: true }) searchInput: ElementRef;

	// Private properties
	private subscriptions: Subscription[] = [];


	constructor(private cdr: ChangeDetectorRef) {
	}

	/**
	 * On init
	 */
	ngOnInit() {
    const searchSubscription = fromEvent(this.searchInput.nativeElement, 'keyup').pipe(
      debounceTime(500),
      distinctUntilChanged(),
      tap(() => {
        this.onChange();
      })
    ).subscribe();
    this.subscriptions.push(searchSubscription);
	}

	/**
	 * On destroy
	 */
	ngOnDestroy(): void {
		this.subscriptions.forEach(sb => sb.unsubscribe());
	}

  onChange() {
    console.log(this.searchText);
    this.searchTextChange.emit(this.searchText);
    this.reload.emit();
  }
}
