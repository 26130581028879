import { Injectable } from '@angular/core';
import { catchError, mergeMap, Observable, of} from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../../../environments/environment';
import { apiUrl } from '../../_config/api.config';

// State
import { AppState } from '../../../core/reducers';
import { currentUser, User } from '../../../core/auth';
// NGRX
import { select, Store } from '@ngrx/store';
import { BehaviorSubject } from 'rxjs';
import {
  LayoutUtilsService,
  MessageType,
  QueryParamsModel
} from "src/app/core/_base/crud";

const BASE_URL = environment.apiUrl;

@Injectable({
  providedIn: "root"
})

export class ExotelService {

  user$: Observable<User>;
  user: User;

  currentCall = new BehaviorSubject(null);

  constructor(private http: HttpClient,
              private store: Store<AppState>,
              private layoutUtilService: LayoutUtilsService,)
  {
    this.user$ = this.store.pipe(select(currentUser));
    this.user$.subscribe(res => {
      console.log(res);
      if (res) {
        this.user = res;
      }
    });
  }

  // To format mobile number based on exotel requirement
  formatMobileNumber(mobile: any) {
    if (!mobile) {
      return '';
    }
    if (mobile.countryCode && mobile.number) {
      return '+' + mobile.countryCode + mobile.number;
    } else {
      return '';
    }
  }

  startTimer(num){
    setInterval(() => {
      if (num == this.currentCall.value) {
        this.currentCall.next(null);
      }
    }, 15000);
  }

  // Initiate call in exotel to phone from active user
  initiateCall(toPhone: any, userType: string = 'rider', isExotelId: boolean = false) {
    this.currentCall.next(toPhone);
    let data: any = {};

    if (isExotelId) {
      data.Sid = toPhone;
    }else {
      data._id = toPhone;
      data.userType = userType;
    }
    return this.http.post(BASE_URL + apiUrl.initiateOutgoingCall, data).subscribe((res: any) => {
      console.log(res);
      if (res.response.code === 201) {
        if (res.data.http_code === 200) {
          this.layoutUtilService.showActionNotification(
            MessageType.success,
            "Call Initiated!",
            "Waiting for the connection to establish."
          );
          this.startTimer(toPhone);
        } else {
          this.layoutUtilService.showActionNotification(
            MessageType.warning,
            res.data.response.error_data.message,
            res.data.response.error_data.description
          );
          this.currentCall.next(null);
        }
      } else {
        this.layoutUtilService.showActionNotification(
          MessageType.warning,
          res.response.status,
          res.response.message
        );
        this.currentCall.next(null);
      }
    },
      error => {
        console.log(error);
        this.layoutUtilService.showActionNotification(
          MessageType.danger,
          error.error.response.status,
          error.error.response.message,
        );
        this.currentCall.next(null);
      });
  }

  // Get single short url
  getCallLogs(queryParams: QueryParamsModel): Observable<any> {
    return this.http.get<any>(BASE_URL + apiUrl.getCallLogs + '?PageSize=' + queryParams.pageSize +
      '&PageNumber=' + queryParams.pageNumber +
      '&SortBy=' + queryParams.sortField +
      ':' + queryParams.sortOrder + queryParams.filter).pipe(
        mergeMap(res => {
          console.log(res);
          if (res.response.code === 200) {
            const result = {
              items: res.data?.list ?? [],
              totalCount: res.data?.totalCount ?? 0,
              firstPageUri: res.data?.Metadata?.FirstPageUri ?? '',
              prevPageUri: res.data?.Metadata?.PrevPageUri ?? '',
              nextPageUri: res.data?.Metadata?.NextPageUri ?? '',
            };
            return of(result);
          } else {
            const result = {
              items: [],
              totalCount: 0,
              firstPageUri: '',
              prevPageUri: '',
              nextPageUri: '',
            };
            return of(result);
          }
        }), catchError(error => {
          const result = {
            items: [],
            totalCount: 0,
            firstPageUri: '',
            prevPageUri: '',
            nextPageUri: '',
          };
          return of(result);
        }));
  }

}
