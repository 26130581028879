// Angular
import { Component, OnDestroy, OnInit, ChangeDetectorRef, Input, Output, OnChanges, EventEmitter } from '@angular/core';
// RxJS
import { Subscription, BehaviorSubject } from 'rxjs';

@Component({
  selector: 'app-pagesize-selector',
  templateUrl: './pagesize-selector.component.html',
  styleUrls: ['./pagesize-selector.component.scss']
})
export class PageSizeSelectorComponent implements OnInit, OnDestroy, OnChanges {

  @Input() pageSize: number;
  @Input() maxPageSize: number = 500;
  @Output() pageSizeChange: EventEmitter<any> = new EventEmitter();
  @Output() reload: EventEmitter<any> = new EventEmitter();

  pageSizeList: number[] = [
    5,10,25,50,100,500
  ]

	// Private properties
	private subscriptions: Subscription[] = [];

	constructor(private cdr: ChangeDetectorRef) {
	}

	/**
	 * On init
	 */
	ngOnInit() {
    //console.log(this.queryParms);
	}

  ngOnChanges(): void {
    //console.log(this.filterBy);
  }

	/**
	 * On destroy
	 */
	ngOnDestroy(): void {
		this.subscriptions.forEach(sb => sb.unsubscribe());
	}

  onChange() {
    this.pageSizeChange.emit(this.pageSize);
    this.reload.emit();
  }

}
