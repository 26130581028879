
export const apiUrl = {
  /* Authentication */
  login: '/auth/admin/login',
  forgotPassword: '/auth/admin/forgotPassword',
  resetPassword: '/auth/management/resetPassword',
  getUserByToken: '/auth/management/member',
  changePassword: '/auth/management/changePassword',
  updateProfile: '/auth/management/member',
  getAllModules: '/settings/module/all',
  getAllRoles: '/settings/access/all',
  role: '/settings/access',
  findRoles: '/settings/access/all',
  workflow: '/mngeops/workflow',
  suspendReport: '/auth/suspendreport',
  chatId: '/auth/comm/chat',
  refreshToken: '/auth/management/refreshToken',

  /* Admin User Management */
  addMember: '/auth/management/addMember',
  member: '/auth/management/member',
  memberStatus: '/auth/management/editStatus',
  suspendMember: '/auth/management/suspend',
  getAllMembers: '/auth/management/memberlist',
  exportAllMembers: '/auth/management/memberlist/excel',
  assetType: '/mngeops/assetType',
  asset: '/mngeops/asset',

  /* Control Panel */
  getAllSurgeTime: '/settings/time/zone',
  surgeTime: '/settings/time',
  taxDetails: '/settings/invoice',
  getFareCharge: '/settings/fare/zone',
  commission: '/comser/commission',
  getReferral: '/rfrl/referral/zone',
  generalSettings: '/settings/setting',
  fareSlabRental: '/settings/fare/rental',
  getAllWallet: '/wallet/config/wallet/all',
  wallet: '/wallet/config/wallet',
  target: '/settings/target',
  fareCharge: '/settings/fare',
  getCommission: '/comser/commission/zone',
  // commission: '/settings/commission',
  // getCommission: '/settings/commission/zone',

  /* Customer Engagement */
  customerEngagement: '/settings/custengage',
  getAllDynamicSection: '/settings/custengage/homescreen/section/all',
  dynamicSection: '/settings/custengage/homescreen/section',
  getAllDynamicSectionItem: '/settings/custengage/homescreen/item/all',
  dynamicSectionItem: '/settings/custengage/homescreen/item',
  getAllVideoCms: '/settings/custengage/videocms/all',
  videoCms: '/settings/custengage/videocms',

  /* Customer Groups */
  getAllCustomerGroup: '/auth/management/customergrp/all',
  customerGroup: '/auth/management/customergrp',

  /* Dashboard API */
  newDriverAnalytics: '/dvrmngmt/management/driver/dashboard/newDriver',
  droppedDriverAnalytics: '/dvrmngmt/management/driver/dashboard/droppedDriver',
  kycAnalytics: '/kycmngmt/management/kyc/dashboard',
  driverStatusAnalytics: '/dvrmngmt/management/driver/dashboard/driverOperations',
  driverStatusCugAnalytics: '/dvrmngmt/management/driver/dashboard/driverOperations',
  riderStatusAnalytics: '/rdrmngmt/management/rider/dashboard/riderAnalytics',
  kycStatusAnalytics: '/kycmngmt/management/kyc/dashboard/statusAnalysis',
  subscriptionPackageAnalytics: '/dvrmngmt/management/driver/dashboard/subscriptionPackage',
  subscriptionFeeAnalytics: '/dvrmngmt/management/driver/dashboard/subscriptionFee',
  driverSubscriptionAnalytics: '/dvrmngmt/management/driver/dashboard/subscriptionDriver',
  // chargeAnalytics: '/drivers/driver/tripfare/dashboard',
  // tripEarningsAnalytics: '/drivers/driver/ride/dashboard/rideStatus',
  // rideReportAnalytics: '/drivers/driver/ride/dashboard/allStatus',
  // rideReportChart: '/drivers/driver/ride/dashboard/chart',
  // rideDashbaordDataExport: '/drivers/driver/ride/dashboard/excel',
  rideDashbaordDataExport: '/rds/driver/ride/dashboard/excel',
  rideReportChart: '/rds/driver/ride/dashboard/chart',
  rideReportAnalytics: '/rds/driver/ride/dashboard/allStatus',
  tripEarningsAnalytics: '/rds/driver/ride/dashboard/rideStatus',
  chargeAnalytics: '/rds/driver/tripfare/dashboard',

  /* Driver */
  getAllDrivers: '/dvrmngmt/management/alldriver',
  exportAllDrivers: '/dvrmngmt/management/alldriver/excel',
  driver: '/dvrmngmt/management/driver',
  singleDriverLocation: '/drivers/driver/profile/location',
  inviteDriverEmail: '/rfrl/management/invite/email',
  inviteDriverSms: '/rfrl/management/invite/sms',
  suspendDriver: '/dvrmngmt/management/driver/suspend',
  verifyDriver: '/drivers/driver/profile/verify',
  tagDriver: '/dvrmngmt/management/driver/remark',
  driverVehicleTypeList: '/drivers/driver/vehicletypelist',
  driverNumber: '/dvrmngmt/management/driver/number',
  driverType: '/dvrmngmt/management/driver/driverType',
  getAllCugMembers: '/dvrmngmt/management/driver/cug',
  driverFare: '/dvrmngmt/management/driver/driverfare',
  adminFare: '/dvrmngmt/management/driver/adminfare',
  // getAccountsList: '/drivers/driver/commission/earning/driver',
  // driverVehicle: '/dvrmngmt/management/driver/vehicle',
  driverVehicle: '/vhclmngmt/driver/drivervehicle',
  getAccountsList: '/comser/driver/commission/earning/driver',

  /* Exotel */
  initiateOutgoingCall: '/xtl/outgoing',
  getCallLogs: '/xtl/call/details',

  /* Cloud Messaging - FCM */
  updateFcmToken: '/notifi/fcm/token',
  getAllNotification: '/notifi/fcm/notifications',
  markAsRead: '/notifi/fcm/read',

  /* Invited Users */
  getAllInvitedUsers: '/rfrl/management/invite/all',

  /* KYC Management */
  getAllKyc: '/kycmngmt/management/kyc',
  searchKycDocument: '/kycmngmt/management/kyc/search',
  exportAllKyc: '/kycmngmt/management/kyc/checklist/excel',
  getKycDriver: '/dvrmngmt/management/driver',
  getSoftCopyKyc: '/kycmngmt/management/kyc/single',
  softCopyKyc: '/kycmngmt/management/kyc',
  digitalKyc: '/kycmngmt/management/kyc/digital',
  markKycInvalid: '/kycmngmt/management/kyc/invalid',
  notifyKycStatus: '/kycmngmt/driver/kyc/notify',
  linkRazorPay: '/kycmngmt/management/kyc/digital/razor',
  driverVehicleType: '/kycmngmt/management/driver/vehicleType',
  hardCopyKyc: '/kycmngmt/management/kyc/hard',
  hardCopyKycStatus: '/kycmngmt/management/kyc/hardstatus',
  getKycLog: '/kycmngmt/driver/kyclog',
  getDriverAccounts: '/pg/razorpayx/account',

  /* Logs */
  getAllLogs: '/settings/log/all',
  exportAllLogs: '/settings/log/all/excel',

  /* Location */
  country: '/settings/zone/country',
  state: '/settings/zone/state',
  postalCode: '/settings/zone/postalCodes',
  getAllZones: '/settings/zone/all',
  zone: '/settings/zone',
  getAllCites: '/settings/zone/city/all',
  city: '/settings/zone/city',
  getAllAirports: '/settings/zone/airport/all',
  airport: '/settings/zone/airport',
  getAllPopularLocations: '/settings/location/popular/list',
  popularLocation: '/settings/location/popular',
  getAllPopularPackages: '/settings/location/package/list',
  popularPackage: '/settings/location/package',

  /* Master Data Management */
  attribute: '/settings/general',
  reason: '/settings/reason',
  tax: '/settings/tax',
  // getAllVehicle: '/settings/vehicle/all',
  // vehicle: '/settings/vehicle',
  //vehicleFilter: '/settings/vehicle/filter',
  //getAllVehicleAttribute: '/settings/attribute/all',
  // vehicleAttribute: '/settings/attribute',
  // vehicleGroup: '/settings/attribute/group',
  vehicleGroup: '/vhclmngmt/attribute/group',
  vehicleAttribute: '/vhclmngmt/attribute',
  getAllVehicleAttribute: '/vhclmngmt/allattribute',
  vehicleFilter: '/vhclmngmt/vehiclefilter',
  vehicle: '/vhclmngmt/vehicle',
  getAllVehicle: '/vhclmngmt/all/vehicle',

  /* Tools Management */
  getAllShortUrl: '/fs/url/short/all',
  shortUrl: '/fs/url/short',
  suspendShortUrl: '/fs/url/short/suspend',
  bulkUploadShortUrl: '/fs/url/short/bulk',
  exportShortUrl: '/fs/url/short/all/excel',

  /* File Upload */
  uploadFile: '/fs/fileUpload',
  getUploadedFile: '/fs/fileUpload/presignurl',

  /* Promotions */
  promotion: '/prmtn/config',
  suspendPromotion: '/prmtn/config/suspend',
  promotionSettlement: '/prmtn/settlement',
  promotionSettlementOrder: '/prmtn/settlement/order',
  promotionSettlementOrders: '/prmtn/settlement/order',
  promotionSettlementPaymentVerify: '/prmtn/settlement/complete',

  /* Referral Management */
  getAllReferral: '/rfrl/referral/all',
  referral: '/rfrl/referral',
  suspendReferral: '/rfrl/referral/suspend',
  referralSettlement: '/rfrl/driver/referral',
  updateReferralSettlement: '/rfrl/driver/referral/settlement',

  /* Remarks */
  remark: '/auth/remark',

  /* Rides management */
  // getAllRideReport: '/drivers/driver/ride/list',
  // getAllPendingRide: '/drivers/driver/ride/list/pending',
  // rideReport: '/drivers/driver/ride/single',
  // rideTrack: '/drivers/driver/ride/location',
  // rideTimeline: '/drivers/driver/ride/timeline',
  // rideNotificationLog: '/riders/rider/ride/notify/list',
  // rideEstimate: '/drivers/driver/ride/estimate',
  // downloadInvoice: '/riders/rider/ride/receipt',
  // cancelRide: '/drivers/driver/ride/cancel/admin',
  // assignDriver: '/drivers/driver/ride/assign',
  // removeDriver: '/drivers/driver/ride/assign/remove',
  // rideVerification: '/drivers/driver/ride/verify',
  // driverCommissionReport: '/drivers/driver/commission/report',
  // getAllEarnings: '/drivers/driver/ride/list',
  getAllRideReport: '/rds/driver/ride/list',
  getAllPendingRide: '/rds/driver/ride/list/pending',
  rideReport: '/rds/driver/ride/single',
  rideTrack: '/rds/driver/ride/location',
  rideTimeline: '/rds/driver/ride/timeline',
  rideNotificationLog: '/rds/rider/ride/notify/list',
  rideEstimate: '/rds/driver/ride/estimate',
  downloadInvoice: '/rds/rider/ride/receipt',
  cancelRide: '/rds/driver/ride/cancel/admin',
  assignDriver: '/rds/driver/ride/assign',
  removeDriver: '/rds/driver/ride/assign/remove',
  rideVerification: '/rds/driver/ride/verify',
  driverCommissionReport: '/comser/driver/commission/report',
  getAllEarnings: '/rds/driver/ride/list',
  riderDeviceDetails: '/riders/rider/profile/version',
  driverDeviceDetails: '/drivers/driver/profile/version',

  /* Rider Management */
  getAllRiders: '/rdrmngmt/management/allrider',
  exportAllRiders: '/rdrmngmt/management/allrider/excel',
  rider: '/rdrmngmt/management/rider',
  inviteRiderEmail: '/rfrl/management/invite/email',
  inviteRiderSms: '/rfrl/management/invite/sms/rider',
  suspendRider: '/rdrmngmt/management/rider/suspend',
  verifyRider: '/riders/rider/profile/verify',

  /* Subscription */
  subscription: '/mngeops/subscription',
  subscribers: '/drivers/driver/subscriptionList',
  exportSubscribers: '/drivers/driver/subscriptionList/excel',
  trialSubscription: '/mngeops/subscription/trial',

  /* Wallet */
  walletDetails: '/wallet/user/wallet/single',
  getAllTransactions: '/wallet/user/wallet/transaction/all',
  addWalletTransaction: '/wallet/user/wallet/transaction',
  suspendWallet: '/wallet/user/wallet/suspend',
  addBulkWalletTransaction: '/wallet/user/wallet/bulk',
  getAllWithdrawRequest: '/wallet/driver/withdraw/list',
  withdrawRequest: '/wallet/driver/withdraw/details',
  updateWithdrawRequest: '/wallet/driver/withdraw/settlement',
  walletDashboard: '/wallet/dashboard/wallet/info/all',
  withdrawDashboard: '/wallet/dashboard/wallet/settlements/all',

  /* External API calls */
  osmGetPlacesList: 'https://nominatim.openstreetmap.org/search',
  getClientIP: 'https://geolocation-db.com/json/',

}
