<div class="account-pages my-5 pt-5">
    <div class="container">
        <div class="row">
            <div class="col-lg-12">
                <div class="text-center mb-5">
                    <h1 class="display-2 font-weight-medium">{{code}}</h1>
                    <h4 class="text-uppercase">{{title}}</h4>
                    <p style="padding: 0px 200px;">{{subtitle}}</p>
                    <div class="mt-5 text-center">
                        <a class="btn btn-secondary mr-3" href="javascript:void(0)" (click)="goBack()">
                          <i class="bx bx-left-arrow-alt mr-1"></i>Previous Page
                        </a>
                        <a id="returnUrlLink" class="btn btn-primary" href="javascript:void(0)" routerLink="{{return.returnUrl}}">
                          <i class="bx bx-home-circle mr-1"></i>{{return.label}}
                        </a>
                    </div>
                </div>
            </div>
        </div>
        <div class="row justify-content-center">
            <div class="col-md-8 col-xl-6">
                <div>
                    <img src="assets/images/error-img.png" alt="" class="img-fluid">
                </div>
            </div>
        </div>
    </div>
</div>
