import { Injectable, PipeTransform } from "@angular/core";

import { BehaviorSubject, Observable, of, Subject } from "rxjs";
import { debounceTime, delay, switchMap, tap, mergeMap } from "rxjs/operators";

import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { apiUrl } from '../../_config/api.config';
import { QueryParamsModel } from '../../_base/crud';

const BASE_URL = environment.apiUrl;

@Injectable({
  providedIn: "root",
})

export class AttributesService {

  constructor(private http: HttpClient) {}

  // Get all attributes
  getAllAttributes(): Observable<any>{
    return this.http.get(BASE_URL + apiUrl.attribute);
  }

  // Get single attribute
  getAttribute(type: string): Observable<any>{
    return this.http.get(BASE_URL + apiUrl.attribute +'?generalType='+type);
  }

  // Add new attribute
  addAttribute(attribute: any): Observable<any>{
    console.log(attribute);
    return this.http.post(BASE_URL+ apiUrl.attribute, attribute);
  }

  // Update attribute
  updateAttribute(attribute: any): Observable<any>{
    console.log(attribute);
    return this.http.put(BASE_URL+ apiUrl.attribute, attribute);
  }

  // Delete attribute
  deleteAttribute(attribute: any): Observable<any>{
    console.log(attribute);
    return this.http.delete(BASE_URL+ apiUrl.attribute +'?_id=' + attribute._id+'&isDelete='+attribute.isDelete);
  }

}
