<div class="d-inline-block mr-3">
  <label class="d-inline-flex align-items-center mb-0 font-size-12">Show
    <ng-select id="pageSizeSelect" class="custom-select custom-select-sm form-control form-control-sm bg-light ml-3 "
      name="tickets-table_length"
      aria-controls="tickets-table"
      name="pageSize"
      [clearable]="false"
      [searchable]="false"
      [(ngModel)]="pageSize"
      (change)="onChange()">
      <ng-container *ngFor="let item of pageSizeList">
        <ng-option *ngIf="item <= maxPageSize" [value]="item">{{item}}</ng-option>
      </ng-container>
    </ng-select>
  </label>
</div>
