import { Injectable, PipeTransform } from "@angular/core";

import { BehaviorSubject, Observable, of, Subject } from "rxjs";
import { debounceTime, delay, switchMap, tap, mergeMap, catchError, map } from "rxjs/operators";

import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { apiUrl } from '../../_config/api.config';
import { QueryParamsModel } from '../../_base/crud';

const BASE_URL = environment.apiUrl;

@Injectable({
  providedIn: "root",
})

export class ControlPanelService {

  constructor(private http: HttpClient) {}

  // Get all surge timing without filter
  getAllSurgeCharge(zoneId : string, cityId : string): Observable<any> {
    let query = '';
    if (cityId) {
      query = '&cityId='+cityId
    }
    return this.http.get<any>(BASE_URL + apiUrl.getAllSurgeTime +'?zoneId=' + zoneId + query).pipe(
      map((res: any) => {
        if (res.response.code === 200) {
          return res.data.list;
        } else {
          return [];
        }
      }),
      catchError(err => {
        console.log(err);
        return [];
      })
    );
  }

  // Add new surge timing
  createSurgeCharge(time: any): Observable<any> {
    return this.http.post(BASE_URL + apiUrl.surgeTime, time);
  }

  // Update surge timing
  updateSurgeCharge(time: any): Observable<any> {
    return this.http.put(BASE_URL + apiUrl.surgeTime, time);
  }

  // Delete surge timing
  deleteSurgeCharge(time: any, query): Observable<any> {
    return this.http.delete(BASE_URL + apiUrl.surgeTime + '?timeId=' + time._id + '&isDelete=' + time.isDelete + query);
  }

  // Get single tax-details
  getTaxDetails(zoneId: string, cityId: string): Observable<any> {
    return this.http.get(BASE_URL + apiUrl.taxDetails + '?zoneId=' + zoneId+'&cityId='+cityId);
  }

  // Add new tax-details
  createTaxDetails(invoice: any): Observable<any> {
    return this.http.post(BASE_URL + apiUrl.taxDetails, invoice);
  }

  // Update tax-details
  updateTaxDetails(invoice: any): Observable<any> {
    return this.http.put(BASE_URL + apiUrl.taxDetails, invoice);
  }

  // Delete tax-details
  deleteTaxDetails(invoice: any): Observable<any> {
    return this.http.delete(BASE_URL + apiUrl.taxDetails + '?invoiceId=' + invoice._id + '&isDelete=' + invoice.isDelete);
  }

  // Get fare charge details
  getFareCharge(zoneId: string, cityId: string, vehicleType: string): Observable<any> {
    return this.http.get<any>(BASE_URL + apiUrl.getFareCharge + '?zoneId=' + zoneId + '&cityId=' + cityId + '&vehicleType=' + vehicleType);
  }

  // Add new fare charge
  createFareCharge(fare: any): Observable<any> {
    console.log(fare);
    return this.http.post(BASE_URL + apiUrl.fareCharge, fare);
  }

  // Update fare charge
  updateFareCharge(fare: any): Observable<any> {
    console.log(fare);
    return this.http.put(BASE_URL + apiUrl.fareCharge, fare);
  }

  // Get ccomsion settlement details
  getCommission(zoneId: string, cityId: string): Observable<any> {
    return this.http.get<any>(BASE_URL + apiUrl.getCommission +'?zoneId=' + zoneId + '&cityId=' + cityId);
  }

  // Add new ccomsion settlement
  createCommission(commission: any): Observable<any> {
    console.log(commission);
    return this.http.post(BASE_URL + apiUrl.commission, commission);
  }

  // Update ccomsion settlement
  updateCommission(commission: any): Observable<any> {
    console.log(commission);
    return this.http.put(BASE_URL + apiUrl.commission, commission);
  }

  // Get referral bonus details
  getReferralBonus(zoneId: string, cityId: string): Observable<any> {
    return this.http.get<any>(BASE_URL + apiUrl.getReferral +'?zoneId=' + zoneId + '&cityId=' + cityId);
  }

  // Add new referral bonus
  createReferralBonus(referral: any): Observable<any> {
    console.log(referral);
    return this.http.post(BASE_URL + apiUrl.referral, referral);
  }

  // Update referral bonus
  updateReferralBonus(referral: any): Observable<any> {
    console.log(referral);
    return this.http.put(BASE_URL + apiUrl.referral, referral);
  }

  // Get general settings details
  getGeneralSettings(zoneId: string, cityId: string): Observable<any> {
    let query = '?zoneId=' + zoneId;
    if (cityId) {
      query = query + '&cityId=' + cityId;
    }
    return this.http.get<any>(BASE_URL + apiUrl.generalSettings + query);
  }

  // Add new general settings
  createGeneralSettings(settings: any): Observable<any> {
    console.log(settings);
    return this.http.post(BASE_URL + apiUrl.generalSettings, settings);
  }

  // Update general settings
  updateGeneralSettings(settings: any): Observable<any> {
    console.log(settings);
    return this.http.put(BASE_URL + apiUrl.generalSettings, settings);
  }

  // Get rental slab details
  getRentalSlabs(zoneId: string, cityId: string): Observable<any> {
    return this.http.get<any>(BASE_URL + apiUrl.fareSlabRental +'?zoneId=' + zoneId + '&cityId=' + cityId);
  }

  // Add new rental slab
  createRentalSlab(fare: any): Observable<any> {
    console.log(fare);
    return this.http.post(BASE_URL + apiUrl.fareSlabRental, fare);
  }

  // Update rental slab
  updateRentalSlab(fare: any): Observable<any> {
    console.log(fare);
    return this.http.put(BASE_URL + apiUrl.fareSlabRental, fare);
  }

  // Get single wallet configuration
  getAllWalletConfiguration(zoneId: string, cityId: string, customer: string): Observable<any> {
    return this.http.get(BASE_URL + apiUrl.getAllWallet +'?zoneId=' + zoneId + '&cityId=' + cityId + '&customer=' + customer);
  }

  // Add new wallet configuration
  createWalletConfiguration(wallet: any): Observable<any> {
    return this.http.post(BASE_URL + apiUrl.wallet , wallet);
  }

  // Update wallet configuration
  updateWalletConfiguration(wallet: any): Observable<any> {
    return this.http.put(BASE_URL + apiUrl.wallet , wallet);
  }

  // Delete wallet configuration
  deleteWalletConfiguration(wallet: any): Observable<any> {
    return this.http.delete(BASE_URL + apiUrl.wallet +'?_id=' + wallet._id + '&isDelete=' + wallet.isDelete);
  }

  // Get single target configuration
  getTargetConfiguration(zoneId: string, cityId: string, year: string, month: string): Observable<any> {
    return this.http.get(BASE_URL + apiUrl.target +'?zoneId=' + zoneId + '&cityId=' + cityId + '&year=' + year + '&month=' + month);
  }

  // Get single target configuration for dashboard
  getTargetDashboard(zoneId: string, cityId: string, start: string, end: string): Observable<any> {
    return this.http.get(BASE_URL + apiUrl.target +'?zoneId=' + zoneId + '&cityId=' + cityId + '&startDate=' + start + '&endDate=' + end);
  }

  // Add new target configuration
  createTargetConfiguration(target: any): Observable<any> {
    return this.http.post(BASE_URL + apiUrl.target, target);
  }

  // Update target configuration
  updateTargetConfiguration(target: any): Observable<any> {
    return this.http.put(BASE_URL + apiUrl.target, target);
  }

  // Delete target configuration
  deleteTargetConfiguration(target: any): Observable<any> {
    return this.http.delete(BASE_URL + apiUrl.target +'?_id=' + target._id + '&isDelete=' + target.isDelete);
  }

}
