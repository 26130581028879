export class PageConfig {
  public defaults: any = {
    // Dashboard
    'dashboard': {
      'default': {
        page: {
          title: 'Default Dashboard',
          breadcrumb: [
            {
              title: 'Dashboards',
              page: '/default',
            },
            {
              title: 'Default',
            }
          ],
        }
      },
      'operations': {
        page: {
          title: 'Operations Dashboard',
          breadcrumb: [
            {
              title: 'Dashboards',
              page: '/dashboard',
            },
            {
              title: 'Operations',
            }
          ],
        }
      },
      'rides': {
        page: {
          title: 'Rides Dashboard',
          breadcrumb: [
            {
              title: 'Dashboards',
              page: '/dashboard',
            },
            {
              title: 'Rides',
            }
          ],
        }
      },
      'sales': {
        page: {
          title: 'Sales Dashboard',
          breadcrumb: [
            {
              title: 'Dashboards',
              page: '/sales',
            },
            {
              title: 'Sales',
            }
          ],
        }
      },
      'partners': {
        page: {
          title: 'Partners Dashboard',
          breadcrumb: [
            {
              title: 'Dashboards',
              page: '/partners',
            },
            {
              title: 'Partners',
            }
          ],
        }
      },
      'revenue': {
        page: {
          title: 'Revenue Dashboard',
          breadcrumb: [
            {
              title: 'Dashboards',
              page: '/revenue',
            },
            {
              title: 'Revenue',
            }
          ],
        }
      },
      'subscriptions': {
        page: {
          title: 'Subscriptions Dashboard',
          breadcrumb: [
            {
              title: 'Dashboards',
              page: '/subscriptions',
            },
            {
              title: 'Subscriptions',
            }
          ],
        }
      },
      'kyc': {
        page: {
          title: 'KYC Dashboard',
          breadcrumb: [
            {
              title: 'Dashboards',
              page: '/dashboard',
            },
            {
              title: 'KYC',
            }
          ],
        }
      },
      'subscription': {
        page: {
          title: 'Subscription Dashboard',
          breadcrumb: [
            {
              title: 'Dashboards',
              page: '/dashboard',
            },
            {
              title: 'Subscription',
            }
          ],
        }
      },
      'driver': {
        page: {
          title: 'Driver Dashboard',
          breadcrumb: [
            {
              title: 'Dashboards',
              page: '/dashboard',
            },
            {
              title: 'Driver',
            }
          ],
        }
      },
    },
    // Management
    'admin': {
      'users': {
        page: {
          title: 'Admin Users',
          breadcrumb: [
            {
              title: 'Management',
              page: '/admin',
            },
            {
              title: 'Admin Users',
              page: '/admin'
            },
            {
              title: 'Users'
            }
          ],
        }
      },
      'roles': {
        page: {
          title: 'Roles Management',
          breadcrumb: [
            {
              title: 'Management',
              page: '/admin',
            },
            {
              title: 'Operations Team',
              page: '/admin'
            },
            {
              title: 'Roles'
            }
          ],
        }
      },
      'work-flows': {
        page: {
          title: 'Work Flow Editor',
          breadcrumb: [
            {
              title: 'Management',
              page: '/admin',
            },
            {
              title: 'Operations Team',
              page: '/admin'
            },
            {
              title: 'Work Flow'
            }
          ],
        }
      }
    },
    'driver-management': {
      page: {
        title: 'Driver Management',
        breadcrumb: [
          {
            title: 'Management',
            page: '/admin',
          },
          {
            title: 'Driver Management',
          }
        ],
      }
    },
    'rider-management': {
      page: {
        title: 'Rider Management',
        breadcrumb: [
          {
            title: 'Management',
            page: '/admin',
          },
          {
            title: 'Rider Management',
          }
        ],
      }
    },
    'customer-groups': {
      'groups': {
        page: {
          title: 'Customer Groups Management',
          breadcrumb: [
            {
              title: 'Management',
              page: '/admin',
            },
            {
              title: 'Customer Groups Management',
            }
          ],
        }
      }

    },
    'partners': {
      'fleets': {
        page: {
          title: 'Fleet Management',
          breadcrumb: [
            {
              title: 'Management',
              page: '/partners',
            },
            {
              title: 'Fleet Management',
              page: '/partners'
            },
            {
              title: 'Fleet Management'
            }
          ],
        }
      },
      'travel-desks': {
        page: {
          title: 'Travel Desk Management',
          breadcrumb: [
            {
              title: 'Management',
              page: '/partners',
            },
            {
              title: 'Travel Desk Management',
              page: '/partners'
            },
            {
              title: 'Travel Desk Management'
            }
          ],
        }
      },
      'corporates': {
        page: {
          title: 'Corporates Management',
          breadcrumb: [
            {
              title: 'Management',
              page: '/partners',
            },
            {
              title: 'Corporates Management',
              page: '/partners'
            },
            {
              title: 'Corporates'
            }
          ],
        }
      },
      'onboarding': {
        'fleet': {
          page: {
            title: 'Onboard Fleet',
            breadcrumb: [
              {
                title: 'Management',
                page: '/partners',
              },
              {
                title: 'Fleet Management',
                page: '/partners/fleets'
              },
              {
                title: 'Add Fleet'
              }
            ],
          }
        },
        'travel-desk': {
          page: {
            title: 'Onboard Travel Desk',
            breadcrumb: [
              {
                title: 'Management',
                page: '/partners',
              },
              {
                title: 'Travel Desk Management',
                page: '/partners/travel-desk'
              },
              {
                title: 'Add Travel Desk'
              }
            ],
          }
        },
        'corporate': {
          page: {
            title: 'Onboard Corporate',
            breadcrumb: [
              {
                title: 'Management',
                page: '/partners',
              },
              {
                title: 'Corporate Management',
                page: '/partners/corporate'
              },
              {
                title: 'Add Corporate'
              }
            ],
          }
        },
      },
    },
    'revenue-model': {
      'subscriptions': {
        page: {
          title: 'subscriptions Management',
          breadcrumb: [
            {
              title: 'Management',
              page: '/partners',
            },
            {
              title: 'subscriptions Management',
              page: '/partners'
            },
            {
              title: 'subscriptions Management'
            }
          ],
        }
      },
      'commission': {
        page: {
          title: 'commission Management',
          breadcrumb: [
            {
              title: 'Management',
              page: '/partners',
            },
            {
              title: 'commission Management',
              page: '/partners'
            },
            {
              title: 'commission Management'
            }
          ],
        }
      },
      'platform-fee': {
        page: {
          title: 'Platform Fee Management',
          breadcrumb: [
            {
              title: 'Management',
              page: '/partners',
            },
            {
              title: 'Platform Fee Management',
              page: '/partners'
            },
            {
              title: 'Platform Fee Management'
            }
          ],
        }
      },
    },
    'wallet': {
      'wallet-transactions': {
        page: {
          title: 'Wallet Transactions',
          breadcrumb: [
            {
              title: 'Management',
              page: '/admin',
            },
            {
              title: 'Wallet',
              page: '/wallet',
            },
            {
              title: 'Wallet Transactions',
            }
          ],
        }
      },
      'withdrawal-request': {
        page: {
          title: 'Withdrawal Request',
          breadcrumb: [
            {
              title: 'Management',
              page: '/admin',
            },
            {
              title: 'Wallet',
              page: '/wallet',
            },
            {
              title: 'Withdrawal Request',
            }
          ],
        }
      },
    },
    'billing': {
      'billed': {
        page: {
          title: 'Billing Management',
          breadcrumb: [
            {
              title: 'Management',
              page: '/billing',
            },
            {
              title: 'Billing Management',
              page: '/billing'
            },
            {
              title: 'Billing Management'
            }
          ],
        }
      },
      'invoiced': {
        page: {
          title: 'invoiced Management',
          breadcrumb: [
            {
              title: 'Management',
              page: '/billing',
            },
            {
              title: 'invoiced Management',
              page: '/billing'
            },
            {
              title: 'invoiced Management'
            }
          ],
        }
      },
    },
    'subscription-management': {
      'packages': {
        page: {
          title: 'Package Management',
          breadcrumb: [
            {
              title: 'Management',
              page: '/admin',
            },
            {
              title: 'Package Management',
            }
          ],
        },
        'trial': {
          page: {
            title: 'Trial Package Management',
            breadcrumb: [
              {
                title: 'Management',
                page: '/admin',
              },
              {
                title: 'Package Management',
                page: '/subscription-management/packages',
              },
              {
                title: 'Trial Package',
              }
            ],
          }
        },
      },
      'subscribers': {
        page: {
          title: 'Subscribers Management',
          breadcrumb: [
            {
              title: 'Management',
              page: '/admin',
            },
            {
              title: 'Subscribers Management',
            }
          ],
        }
      }
    },
    'kyc-management': {
      page: {
        title: 'KYC Management',
        breadcrumb: [
          {
            title: 'Management',
            page: '/admin',
          },
          {
            title: 'KYC Management',
          }
        ],
      }
    },
    'referral-management': {
      'promotions': {
        page: {
          title: 'Referral Promotions List',
          breadcrumb: [
            {
              title: 'Management',
              page: '/admin',
            },
            {
              title: 'Referral Management',
              page: '/referral-management',
            },
            {
              title: 'Referral Promotions List',
            }
          ],
        }
      },
      'referrals': {
        page: {
          title: 'Referral Settlement List',
          breadcrumb: [
            {
              title: 'Management',
              page: '/admin',
            },
            {
              title: 'Referral Management',
              page: '/referral-management',
            },
            {
              title: 'Referral Settlement List',
            }
          ],
        }
      },
      // 'requests': {
      //   page: {
      //     title: 'Redeem Requests',
      //     breadcrumb: [
      //       {
      //         title: 'Management',
      //         page: '/admin',
      //       },
      //       {
      //         title: 'Referral Bonus Management',
      //         page: '/referral-management',
      //       },
      //       {
      //         title: 'Redeem Requests',
      //       }
      //     ],
      //   }
      // },
      // 'orders': {
      //   page: {
      //     title: 'Processed Orders',
      //     breadcrumb: [
      //       {
      //         title: 'Management',
      //         page: '/admin',
      //       },
      //       {
      //         title: 'Referral Bonus Management',
      //         page: '/referral-management',
      //       },
      //       {
      //         title: 'Processed Orders',
      //       }
      //     ],
      //   }
      // },
    },
    'offer-management': {
      'promotions': {
        page: {
          title: 'Promotions List',
          breadcrumb: [
            {
              title: 'Management',
              page: '/admin',
            },
            {
              title: 'Offer Management',
              page: '/offer-management',
            },
            {
              title: 'Promotions List',
            }
          ],
        }
      },
      'promotion-settlements': {
        page: {
          title: 'Promotion Settlements',
          breadcrumb: [
            {
              title: 'Management',
              page: '/admin',
            },
            {
              title: 'Offer Management',
              page: '/offer-management',
            },
            {
              title: 'Promotion Settlements List',
            }
          ],
        }
      },
      'orders': {
        page: {
          title: 'Processed Orders',
          breadcrumb: [
            {
              title: 'Management',
              page: '/admin',
            },
            {
              title: 'Offer Management',
              page: '/offer-management',
            },
            {
              title: 'Processed Orders',
            }
          ],
        }
      },
    },
    // 'wallet': {
    //   'wallet-transactions': {
    //     page: {
    //       title: 'Wallet Transactions',
    //       breadcrumb: [
    //         {
    //           title: 'Management',
    //           page: '/admin',
    //         },
    //         {
    //           title: 'Wallet',
    //           page: '/wallet',
    //         },
    //         {
    //           title: 'Wallet Transactions',
    //         }
    //       ],
    //     }
    //   },
    //   'withdrawal-request': {
    //     page: {
    //       title: 'Withdrawal Request',
    //       breadcrumb: [
    //         {
    //           title: 'Management',
    //           page: '/admin',
    //         },
    //         {
    //           title: 'Wallet',
    //           page: '/wallet',
    //         },
    //         {
    //           title: 'Withdrawal Request',
    //         }
    //       ],
    //     }
    //   },
    // },
    'archived': {
      'users': {
        page: {
          title: 'Archived Users',
          breadcrumb: [
            {
              title: 'Management',
              page: '/archived/users',
            },
            {
              title: 'Archived Users',
              page: '/archived/users',
            },
            {
              title: 'users',
            }
          ],
        }
      },
      'drivers': {
        page: {
          title: 'Archived Drivers',
          breadcrumb: [
            {
              title: 'Management',
              page: '/archived/drivers',
            },
            {
              title: 'Archived Users',
              page: '/archived/drivers',
            },
            {
              title: 'Drivers',
            }
          ],
        }
      },
      'riders': {
        page: {
          title: 'Archived Riders',
          breadcrumb: [
            {
              title: 'Management',
              page: '/archived/riders',
            },
            {
              title: 'Archived Users',
              page: '/archived/riders',
            },
            {
              title: 'Riders',
            }
          ],
        }
      },
      'fleets': {
        page: {
          title: 'Archived fleets',
          breadcrumb: [
            {
              title: 'Management',
              page: '/archived/fleets',
            },
            {
              title: 'Archived Users',
              page: '/archived/fleets',
            },
            {
              title: 'fleets',
            }
          ],
        }
      },
      'travel-desks': {
        page: {
          title: 'Archived travel desks',
          breadcrumb: [
            {
              title: 'Management',
              page: '/archived/travel-desks',
            },
            {
              title: 'Archived Users',
              page: '/archived/travel-desks',
            },
            {
              title: 'travel desks',
            }
          ],
        }
      },
      'corporates': {
        page: {
          title: 'Archived corporates',
          breadcrumb: [
            {
              title: 'Management',
              page: '/archived/corporates',
            },
            {
              title: 'Archived Users',
              page: '/archived/corporates',
            },
            {
              title: 'corporates',
            }
          ],
        }
      }
    },
    'invited': {
      'drivers': {
        page: {
          title: 'Invited Drivers',
          breadcrumb: [
            {
              title: 'Management',
              page: '/invited/drivers',
            },
            {
              title: 'Invited Users',
              page: '/invited/drivers',
            },
            {
              title: 'Drivers',
            }
          ],
        }
      },
      'riders': {
        page: {
          title: 'Invited Riders',
          breadcrumb: [
            {
              title: 'Management',
              page: '/invited/riders',
            },
            {
              title: 'Invited Users',
              page: '/invited/riders',
            },
            {
              title: 'Riders',
            }
          ],
        }
      }
    },
    // Reports
    'ride-reports': {
      page: {
        title: 'Ride Reports',
        breadcrumb: [
          {
            title: 'Reports',
            page: '/ride-reports',
          },
          {
            title: 'Ride Reports',
          }
        ],
      },
      'pending-rides': {
        page: {
          title: 'Pending Ride Requests',
          breadcrumb: [
            {
              title: 'Reports',
              page: '/ride-reports',
            },
            {
              title: 'Pending Requests',
            }
          ],
        }
      },
      'book-ride': {
        page: {
          title: 'Book Ride',
          breadcrumb: [
            {
              title: 'Reports',
              page: '/ride-reports',
            },
            {
              title: 'Book Ride',
            }
          ],
        }
      },
    },
    'sales': {
        page: {
          title: 'Sales List',
          breadcrumb: [
            {
              title: 'Sales',
              page: '/sales'
            },
            {
              title: 'Sales List'
            }
          ],
        },
    },
    'revenue': {
        page: {
          title: 'Revenue Report',
          breadcrumb: [
            {
              title: 'Revenue Report',
              page: '/revenue'
            },
            {
              title: 'Revenue Report'
            }
          ],
        },
      },
    'earnings': {
      page: {
        title: 'Earnings',
        breadcrumb: [
          {
            title: 'Reports',
            page: '/ride-reports',
          },
          {
            title: 'Earnings',
          }
        ],
      }
    },
    'trip-fare': {
      page: {
        title: 'Trip Fare Settlements',
        breadcrumb: [
          {
            title: 'Reports',
            page: '/ride-reports',
          },
          {
            title: 'Trip Fare Settlements',
          }
        ],
      }
    },
    // Customer Engagement
    'customer-engagement': {
      'tips': {
        page: {
          title: 'Manage Ride Request Tips',
          breadcrumb: [
            {
              title: 'Customer Engagement',
              page: '/customer-engagement',
            },
            {
              title: 'Tips',
            }
          ],
        }
      },
      'sections': {
        page: {
          title: 'Manage Rider Home Screen Sections',
          breadcrumb: [
            {
              title: 'Customer Engagement',
              page: '/customer-engagement',
            },
            {
              title: 'Sections',
            }
          ],
        }
      },
      'videos': {
        page: {
          title: 'Manage Training Video Sections',
          breadcrumb: [
            {
              title: 'Customer Engagement',
              page: '/customer-engagement',
            },
            {
              title: 'Training Videos',
            }
          ],
        }
      },
    },
    // Master Configurations
    'master-data': {
      'zones': {
        page: {
          title: 'Zones Management',
          breadcrumb: [
            {
              title: 'Admin Configurations',
              page: '/master-data',
            },
            {
              title: 'Master Data',
              page: '/master-data',
            },
            {
              title: 'Zones Management',
            }
          ],
        }
      },
      'cities': {
        page: {
          title: 'Cities Management',
          breadcrumb: [
            {
              title: 'Admin Configurations',
              page: '/master-data',
            },
            {
              title: 'Master Data',
              page: '/master-data',
            },
            {
              title: 'Cities Management',
            }
          ],
        }
      },
      'airports': {
        page: {
          title: 'Airports Management',
          breadcrumb: [
            {
              title: 'Admin Configurations',
              page: '/master-data',
            },
            {
              title: 'Master Data',
              page: '/master-data',
            },
            {
              title: 'Airports Management',
            }
          ],
        }
      },
      'tolls': {
        page: {
          title: 'Tolls Management',
          breadcrumb: [
            {
              title: 'Admin Configurations',
              page: '/master-data',
            },
            {
              title: 'Master Data',
              page: '/master-data',
            },
            {
              title: 'Tolls Management',
            }
          ],
        }
      },
      'popular-packages': {
        page: {
          title: 'Popular Packages Management',
          breadcrumb: [
            {
              title: 'Admin Configurations',
              page: '/master-data',
            },
            {
              title: 'Master Data',
              page: '/master-data',
            },
            {
              title: 'Popular Packages',
            }
          ],
        },
        'popular-locations': {
          page: {
            title: 'Popular Locations Management',
            breadcrumb: [
              {
                title: 'Admin Configurations',
                page: '/master-data',
              },
              {
                title: 'Master Data',
                page: '/master-data',
              },
              {
                title: 'Popular Locations',
              }
            ],
          }
        },
      },
      'vehicles': {
        page: {
          title: 'Vehicles Management',
          breadcrumb: [
            {
              title: 'Admin Configurations',
              page: '/master-data',
            },
            {
              title: 'Master Data',
              page: '/master-data',
            },
            {
              title: 'Vehicles Management',
            }
          ],
        },
        'attributes': {
          page: {
            title: 'Vehicle Attributes Management',
            breadcrumb: [
              {
                title: 'Admin Configurations',
                page: '/master-data',
              },
              {
                title: 'Master Data',
                page: '/master-data',
              },
              {
                title: 'Vehicles Management',
                page: '/master-data/vehicles',
              },
              {
                title: 'Vehicle Attributes',
              }
            ],
          }
        }
      },
      'reasons': {
        page: {
          title: 'Reasons Management',
          breadcrumb: [
            {
              title: 'Admin Configurations',
              page: '/master-data',
            },
            {
              title: 'Master Data',
              page: '/master-data',
            },
            {
              title: 'Reasons Management',
            }
          ],
        }
      },
      'attributes': {
        page: {
          title: 'Attributes Management',
          breadcrumb: [
            {
              title: 'Admin Configurations',
              page: '/master-data',
            },
            {
              title: 'Master Data',
              page: '/master-data',
            },
            {
              title: 'Attributes Management',
            }
          ],
        }
      },

      'taxes': {
        page: {
          title: 'Taxes Management',
          breadcrumb: [
            {
              title: 'Admin Configurations',
              page: '/master-data',
            },
            {
              title: 'Master Data',
              page: '/master-data',
            },
            {
              title: 'Taxes Management',
            }
          ],
        }
      },
      'amenities': {
        page: {
          title: 'Amenities Management',
          breadcrumb: [
            {
              title: 'Admin Configurations',
              page: '/master-data',
            },
            {
              title: 'Master Data',
              page: '/master-data',
            },
            {
              title: 'Amenities Management',
            }
          ],
        }
      }
    },
    'control-panel': {
      'fare-charge': {
        page: {
          title: 'Trip Fare',
          breadcrumb: [
            {
              title: 'Admin Configurations',
              page: '/master-data',
            },
            {
              title: 'Control Panel',
              page: '/control-panel',
            },
            {
              title: 'Trip Fare',
            }
          ],
        },
        'ride-now': {
          page: {
            title: 'Trip Fare Configuration',
            breadcrumb: [
              {
                title: 'Admin Configurations',
                page: '/master-data',
              },
              {
                title: 'Control Panel',
                page: '/control-panel',
              },
              {
                title: 'Trip Fare Configuration',
                page: '/control-panel/fare-charge',
              },
              {
                title: 'City Ride',
              }
            ],
          }
        },
        'half-day': {
          page: {
            title: 'Trip Fare Configuration',
            breadcrumb: [
              {
                title: 'Admin Configurations',
                page: '/master-data',
              },
              {
                title: 'Control Panel',
                page: '/control-panel',
              },
              {
                title: 'Trip Fare Configuration',
                page: '/control-panel/fare-charge',
              },
              {
                title: 'Half Day',
              }
            ],
          }
        },
        'full-day': {
          page: {
            title: 'Trip Fare Configuration',
            breadcrumb: [
              {
                title: 'Admin Configurations',
                page: '/master-data',
              },
              {
                title: 'Control Panel',
                page: '/control-panel',
              },
              {
                title: 'Trip Fare Configuration',
                page: '/control-panel/fare-charge',
              },
              {
                title: 'Full Day',
              }
            ],
          }
        },
        'outstation-oneway': {
          page: {
            title: 'Trip Fare Configuration',
            breadcrumb: [
              {
                title: 'Admin Configurations',
                page: '/master-data',
              },
              {
                title: 'Control Panel',
                page: '/control-panel',
              },
              {
                title: 'Trip Fare Configuration',
                page: '/control-panel/fare-charge',
              },
              {
                title: 'Outstation One Way',
              }
            ],
          }
        },
        'outstation-round': {
          page: {
            title: 'Trip Fare Configuration',
            breadcrumb: [
              {
                title: 'Admin Configurations',
                page: '/master-data',
              },
              {
                title: 'Control Panel',
                page: '/control-panel',
              },
              {
                title: 'Trip Fare Configuration',
                page: '/control-panel/fare-charge',
              },
              {
                title: 'Outstation Round Trip',
              }
            ],
          }
        },
        'airport': {
          page: {
            title: 'Trip Fare Configuration',
            breadcrumb: [
              {
                title: 'Admin Configurations',
                page: '/master-data',
              },
              {
                title: 'Control Panel',
                page: '/control-panel',
              },
              {
                title: 'Trip Fare Configuration',
                page: '/control-panel/fare-charge',
              },
              {
                title: 'Airport',
              }
            ],
          }
        },
        'cancellation': {
          page: {
            title: 'Trip Fare Configuration',
            breadcrumb: [
              {
                title: 'Admin Configurations',
                page: '/master-data',
              },
              {
                title: 'Control Panel',
                page: '/control-panel',
              },
              {
                title: 'Trip Fare Configuration',
                page: '/control-panel/fare-charge',
              },
              {
                title: 'Cancellation Charge',
              }
            ],
          }
        },
        'waiting': {
          page: {
            title: 'Trip Fare Configuration',
            breadcrumb: [
              {
                title: 'Admin Configurations',
                page: '/master-data',
              },
              {
                title: 'Control Panel',
                page: '/control-panel',
              },
              {
                title: 'Trip Fare Configuration',
                page: '/control-panel/fare-charge',
              },
              {
                title: 'Waiting Charge',
              }
            ],
          }
        }
      },
      'commission': {
        page: {
          title: 'Commission Settlement',
          breadcrumb: [
            {
              title: 'Admin Configurations',
              page: '/master-data',
            },
            {
              title: 'Control Panel',
              page: '/control-panel',
            },
            {
              title: 'Commission Settlement',
            }
          ],
        }
      },
      'tax-invoice': {
        page: {
          title: 'Tax & Invoice Management',
          breadcrumb: [
            {
              title: 'Admin Configurations',
              page: '/master-data',
            },
            {
              title: 'Control Panel',
              page: '/control-panel',
            },
            {
              title: 'Tax & Invoicing',
            }
          ],
        }
      },
      'referral-bonus': {
        page: {
          title: 'Referral Bonus',
          breadcrumb: [
            {
              title: 'Admin Configurations',
              page: '/master-data',
            },
            {
              title: 'Control Panel',
              page: '/control-panel',
            },
            {
              title: 'Referral Bonus',
            }
          ],
        }
      },
      'wallet-configuration': {
        page: {
          title: 'Wallet Configuration',
          breadcrumb: [
            {
              title: 'Admin Configurations',
              page: '/master-data',
            },
            {
              title: 'Control Panel',
              page: '/control-panel',
            },
            {
              title: 'Wallet Configuration',
            }
          ],
        }
      },
      'surge-charge': {
        page: {
          title: 'Surge Charge',
          breadcrumb: [
            {
              title: 'Admin Configurations',
              page: '/master-data',
            },
            {
              title: 'Control Panel',
              page: '/control-panel',
            },
            {
              title: 'Surge Charge',
            }
          ],
        }
      },
      'vehicle-groups': {
        page: {
          title: 'Vehicle Groups',
          breadcrumb: [
            {
              title: 'Admin Configurations',
              page: '/master-data',
            },
            {
              title: 'Control Panel',
              page: '/control-panel',
            },
            {
              title: 'Vehicle Groups',
            }
          ],
        }
      },
      'kyc-configuration': {
        page: {
          title: 'KYC Configuration',
          breadcrumb: [
            {
              title: 'Admin Configurations',
              page: '/master-data',
            },
            {
              title: 'Control Panel',
              page: '/control-panel',
            },
            {
              title: 'KYC Configuration',
            }
          ],
        }
      },
      'general-settings': {
        page: {
          title: 'General Settings',
          breadcrumb: [
            {
              title: 'Admin Configurations',
              page: '/master-data',
            },
            {
              title: 'Control Panel',
              page: '/control-panel',
            },
            {
              title: 'General Settings',
            }
          ],
        }
      },
      'target-configuration': {
        page: {
          title: 'Target Configuration (KPI)',
          breadcrumb: [
            {
              title: 'Admin Configurations',
              page: '/master-data',
            },
            {
              title: 'Control Panel',
              page: '/control-panel',
            },
            {
              title: 'Target Configuration (KPI)',
            }
          ],
        }
      },
    },
    // Logs and reports
    'logs': {
      'audit-logs': {
        page: {
          title: 'Audit Logs',
          breadcrumb: [
            {
              title: 'Logs',
              page: '/logs'
            },
            {
              title: 'Audit Logs'
            }
          ],
        },
      }
    },
    // Tools
    'tools': {
      'url-shortener': {
        page: {
          title: 'URL Shortener',
          breadcrumb: [
            {
              title: 'Tools',
              page: '/tools'
            },
            {
              title: 'URL Shortener'
            }
          ],
        }
      },
      'data-sync': {
        page: {
          title: 'Data Sync',
          breadcrumb: [
            {
              title: 'Tools',
              page: '/tools'
            },
            {
              title: 'Data Sync'
            }
          ],
        }
      },
    },
    'help-center': {
      'user-guide': {
        page: {
          title: 'User Guide',
          breadcrumb: [
            {
              title: 'User Guide',
              page: '/user-guide'
            },
            {
              title: 'User Guide'
            }
          ],
        }
      },
      'faq': {
        page: {
          title: 'FAQ',
          breadcrumb: [
            {
              title: 'FAQ',
              page: '/faq'
            },
            {
              title: 'FAQ'
            }
          ],
        }
      },
      'support': {
        page: {
          title: 'Support',
          breadcrumb: [
            {
              title: 'Support',
              page: '/support'
            },
            {
              title: 'Support'
            }
          ],
        }
      },
      'change-log': {
        page: {
          title: 'Change Log',
          breadcrumb: [
            {
              title: 'Change Log',
              page: '/change-log'
            },
            {
              title: 'Change Log'
            }
          ],
        }
      },
    },
    // Other
    'profile': {
      page: {
        title: 'PROFILE',
        breadcrumb: [
          {
            title: 'Profile Management',
            page: '/dashboard',
          },
          {
            title: 'Profile',
          }
        ],
      }
    },
    'notifications': {
      page: {
        title: 'Notifications',
        breadcrumb: [
          {
            title: 'Dashboard',
            page: '/dashboard',
          },
          {
            title: 'Notifications',
          }
        ],
      }
    },
    error: {
      404: {
        page: {
          title: '404 Not Found',
          subheader: false
        }
      },
      403: {
        page: {
          title: '403 Access Forbidden',
          subheader: false
        }
      }
    }
  };

  public get configs(): any {
    return this.defaults;
  }
}
