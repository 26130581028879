// Angular
import { Injectable } from '@angular/core';
// RxJS
import { mergeMap, map, tap } from 'rxjs/operators';
import { defer, Observable, of } from 'rxjs';
// NGRX
import { createEffect, Actions, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
// Services
import { TollsService } from '../_services/tolls.service';
// Actions
import {
    AllTollsLoaded,
    AllTollsRequested,
   TollActionTypes
} from '../_actions/toll.actions';
// Models
import { Toll } from '../_models/toll.model';

@Injectable()
export class TollEffects {

    loadAllTolls$ = createEffect(() => this.actions$
        .pipe(
          ofType<AllTollsRequested>(TollActionTypes.AllTollsRequested),
          mergeMap(() => this.service.getTolls()),
            map((result: Toll[]) => {
              console.log(result);
                return  new AllTollsLoaded({
                    Tolls: result
                });
            })
          ));

    init$: Observable<Action> = createEffect(() => defer(() => {
        return of(new AllTollsRequested());
    }));

  constructor(private actions$: Actions, private service: TollsService) { }
}
