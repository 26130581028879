




import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class GoogleMapsLoaderService {
  private isLoaded = false;
  private loadPromise: Promise<void>;

  load(apiKey: string, libraries: string[] = [], language: string = 'en'): Promise<void> {
    if (this.isLoaded) {
      return this.loadPromise;
    }

    this.loadPromise = new Promise((resolve, reject) => {
      if (this.isScriptPresent()) {
        this.isLoaded = true;
        resolve();
        return;
      }

      const script = document.createElement('script');
      script.src = `https://maps.googleapis.com/maps/api/js?key=${apiKey}&libraries=${libraries.join(
        ','
      )}&language=${language}`;
      script.async = true;
      script.defer = true;

      script.onload = () => {
        console.log('Google Maps script loaded successfully!');
        this.isLoaded = true;
        resolve();
      };

      script.onerror = (error) => {
        console.error('Failed to load Google Maps API:', error);
        reject(error);
      };

      document.head.appendChild(script);
    });

    return this.loadPromise;
  }

  private isScriptPresent(): boolean {
    return !!document.querySelector('script[src*="maps.googleapis.com/maps/api/js"]');
  }
}
