export const apiUrl = {
  /* Authentication */
  login: '/auth/admin/login',
  forgotPassword: '/auth/admin/forgotPassword',
  resetPassword: '/auth/management/resetPassword',
  getUserByToken: '/auth/management/member',
  changePassword: '/auth/management/changePassword',
  updateProfile: '/auth/management/member',
  getAllModules: '/mngeops/module/all',
  getAllRoles: '/mngeops/access/all',
  role: '/mngeops/access',
  workflow: '/mngeops/workflow',
  suspendReport: '/auth/suspendreport',
  chatId: '/auth/comm/chat',
  refreshToken: '/auth/admin/refreshToken',
  partnerKey: '/auth/management/partnerkey',

  /* Admin User Management */
  addMember: '/auth/management/addMember',
  member: '/auth/management/member',
  memberStatus: '/auth/management/editStatus',
  suspendMember: '/auth/management/suspend',
  getAllMembers: '/auth/management/memberlist',
  exportAllMembers: '/auth/management/memberlist/excel',
  assetType: '/mngeops/assetType',
  asset: '/mngeops/asset',
  memberSalesAnalytics: '/riders/rider/ride/lead/admin',

  /* Location */
  country: '/settings/zone/country',
  state: '/settings/zone/state',
  postalCode: '/settings/zone/postalCodes',
  getAllZones: '/settings/zone/all',
  zone: '/settings/zone',
  getAllCites: '/settings/zone/city/all',
  city: '/settings/zone/city',
  getAllAirports: '/settings/zone/airport/all',
  airport: '/settings/zone/airport',
  getAllPopularLocations: '/settings/location/popular/list',
  popularLocation: '/settings/location/popular',
  getAllPopularPackages: '/settings/location/package/list',
  popularPackage: '/settings/location/package',
  toll: '/settings/toll',
  getAllTolls: '/settings/toll/all',

  /* Master Data Management */
  attribute: '/settings/general',
  reason: '/settings/reason',
  amenity: '/settings/amenity/all',
  createAminity: '/settings/amenity',
  updateAminity: '/settings/amenity',
  deleteAminity: '/settings/amenity',
  tax: '/settings/tax',
  vehicleGroup: '/vhclmngmt/attribute/group',
  vehicleAttribute: '/vhclmngmt/attribute',
  getAllVehicleAttribute: '/vhclmngmt/attribute/all',
  vehicleFilter: '/vhclmngmt/vehiclefilter',
  vehicle: '/vhclmngmt/vehicle',
  getAllVehicle: '/vhclmngmt/all/vehicle',
  getAllVehicleTypes: '/vhclmngmt/attribute/type',
  vehicleType: '/vhclmngmt/attribute/type',

  /* Control Panel */
  getAllSurgeTime: '/settings/time/zone',
  surgeTime: '/settings/time',
  taxDetails: '/settings/invoice',
  getFareCharge: '/settings/fare/zone',
  commission: '/comser/commission',
  platformFee: '/comser/commission',
  getReferral: '/rfrl/referral/zone',
  generalSettings: '/settings/setting',
  fareSlabRental: '/settings/fare/rental',
  getAllWallet: '/wallet/config/wallet/all',
  wallet: '/wallet/config/wallet',
  target: '/settings/target',
  fareCharge: '/settings/fare',
  getCommission: '/comser/commission/zone',
  getPlatformFee:'/comser/commission/zone',
  kycConfiguration: '/settings/kyc/mandatory',

  /* Dashboard API */
  newDriverAnalytics: '/dvrmngmt/management/driver/dashboard/newDriver',
  droppedDriverAnalytics: '/dvrmngmt/management/driver/dashboard/droppedDriver',
  kycAnalytics: '/kycmngmt/management/kyc/dashboard',
  driverStatusAnalytics: '/dvrmngmt/management/driver/dashboard/driverOperations',
  driverStatusCugAnalytics: '/dvrmngmt/management/driver/dashboard/driverOperations',
  riderStatusAnalytics: '/rdrmngmt/management/rider/dashboard/riderAnalytics',
  kycStatusAnalytics: '/kycmngmt/management/kyc/dashboard/statusAnalysis',
  subscriptionPackageAnalytics: '/dvrmngmt/management/driver/dashboard/subscriptionPackage',
  subscriptionFeeAnalytics: '/dvrmngmt/management/driver/dashboard/subscriptionFee',
  driverSubscriptionAnalytics: '/dvrmngmt/management/driver/dashboard/subscriptionDriver',
  rideDashbaordDataExport: '/rds/driver/ride/dashboard/excel',
  rideReportChart: '/rds/driver/ride/dashboard/chart',
  rideReportAnalytics: '/rds/driver/ride/dashboard/allStatus',
  tripEarningsAnalytics: '/rds/driver/ride/dashboard/rideStatus',
  chargeAnalytics: '/rds/driver/tripfare/dashboard',

  /* Rider Management */
  getAllRiders: '/rdrmngmt/management/allrider',
  exportAllRiders: '/rdrmngmt/management/allrider/excel',
  exportAllPendingRiders: '/rds/driver/ride/list/pending/excel',

  rider: '/rdrmngmt/management/rider',
  inviteRiderEmail: '/rfrl/management/invite/email',
  inviteRiderSms: '/rfrl/management/invite/sms/rider',
  suspendRider: '/rdrmngmt/management/rider/suspend',
  verifyRider: '/riders/rider/profile/verify',

  /* Driver */
  getAllDrivers: '/dvrmngmt/management/alldriver',
  exportAllDrivers: '/dvrmngmt/management/alldriver/excel',
  exportAllInvitedDrivers: '/rfrl/management/invite/excel',
  exportAllInvitedRiders: '/rfrl/management/invite/excel',

  driver: '/dvrmngmt/management/driver',
  singleDriverLocation: '/drivers/driver/profile/location',
  inviteDriverEmail: '/rfrl/management/invite/email',
  inviteDriverSms: '/rfrl/management/invite/sms',
  suspendDriver: '/dvrmngmt/management/driver/suspend',
  verifyDriver: '/drivers/driver/profile/verify',
  tagDriver: '/dvrmngmt/management/driver/remark',
  driverVehicleTypeList: '/drivers/driver/vehicletypelist',
  driverNumber: '/dvrmngmt/management/driver/number',
  driverType: '/dvrmngmt/management/driver/driverType',
  getAllCugMembers: '/dvrmngmt/management/driver/cug',
  driverFare: '/dvrmngmt/management/driver/driverfare',
  adminFare: '/dvrmngmt/management/driver/adminfare',
  driverVehicle: '/vhclmngmt/driver/drivervehicle',
  getAccountsList: '/comser/driver/commission/earning/driver',

  /* Partner Management */
  partner: '/ptnrmngmt/partner',
  getAllPartner: '/ptnrmngmt/partner/all',
  exportAllPartner: '/ptnrmngmt/partner/all/excel',
  suspendPartner: '/ptnrmngmt/partner/suspend',
  shareWelcomeMailPartner: '/ptnrmngmt/partner/share',
  partnerRidePreference: '/ptnrmngmt/partner/ridepreference',
  partnerSubscription: '/ptnrmngmt/partner/subscription',
  partnerCommission: '/ptnrmngmt/partner/commission',
  partnerPlatformFee: '/ptnrmngmt/partner/platformfee',
  partnerInvoicing: '/ptnrmngmt/partner/invoice',
  partnerTax: '/ptnrmngmt/partner/tax',

  /* Customer Groups */
  getAllCustomerGroup: '/auth/management/customergrp/all',
  customerGroup: '/auth/management/customergrp',

  /* Remarks */
  remark: '/auth/remark',

  /* Invited Users */
  getAllInvitedUsers: '/rfrl/management/invite/all',

  /* File Upload */
  uploadFile: '/fs/fileUpload',
  getUploadedFile: '/fs/fileUpload/presignurl',

  /* Tools Management */
  getAllShortUrl: '/fs/url/short/all',
  shortUrl: '/fs/url/short',
  suspendShortUrl: '/fs/url/short/suspend',
  bulkUploadShortUrl: '/fs/url/short/bulk',
  exportShortUrl: '/fs/url/short/all/excel',

  /* KYC Management */
  getAllKyc: '/kycmngmt/management/kyc',
  searchKycDocument: '/kycmngmt/management/kyc/search',
  exportAllKyc: '/kycmngmt/management/kyc/checklist/excel',
  getKycDriver: '/dvrmngmt/management/driver',
  getSoftCopyKyc: '/kycmngmt/management/kyc/single',
  softCopyKyc: '/kycmngmt/management/kyc',
  digitalKyc: '/kycmngmt/management/kyc/digital',
  markKycInvalid: '/kycmngmt/management/kyc/invalid',
  notifyKycStatus: '/kycmngmt/driver/kyc/notify',
  linkRazorPay: '/kycmngmt/management/kyc/digital/razor',
  driverVehicleType: '/kycmngmt/management/driver/vehicleType',
  hardCopyKyc: '/kycmngmt/management/kyc/hard',
  hardCopyKycStatus: '/kycmngmt/management/kyc/hardstatus',
  getKycLog: '/kycmngmt/driver/kyclog',
  getDriverAccounts: '/pg/razorpayx/account',

  /* Subscription Management Old */
  subscription: '/mngeops/subscription',
  subscribers: '/drivers/driver/subscriptionList',
  exportSubscribers: '/drivers/driver/subscriptionList/excel',
  trialSubscription: '/mngeops/subscription/trial',

  /* Subscription Model Management */
  getAllSubscriptionModel: '/revser/subscription/all',
  subscriptionModel: '/revser/subscription',
  getAllFleetModules: '/mngeops/module/flt/all',
  getAllTravelDeskModules: '/mngeops/module/trvldsk/all',
  getAllCorporateModules: '/mngeops/module/corpr8/all',
  getAllRiderModules: '/mngeops/module/rider/all',
  getAllDriverModules: '/mngeops/module/driver/all',

  /* Commission Model Management */
  getAllCommissionModel: '/revser/commission/all',
  commissionModel: '/revser/commission',

  /* Platform Fee Model Management */
  getAllPlatformFeeModel: '/revser/platformfee/all',
  platformFeeModel: '/revser/platformfee',

  /* Promotions */
  promotion: '/prmtn/config',
  suspendPromotion: '/prmtn/config/suspend',
  promotionSettlement: '/prmtn/settlement',
  promotionSettlementOrder: '/prmtn/settlement/order',
  promotionSettlementOrders: '/prmtn/settlement/order',
  promotionSettlementPaymentVerify: '/prmtn/settlement/complete',

  /* Referral Management */
  getAllReferral: '/rfrl/referral/all',
  referral: '/rfrl/referral',
  suspendReferral: '/rfrl/referral/suspend',
  referralSettlement: '/rfrl/driver/referral',
  updateReferralSettlement: '/rfrl/driver/referral/settlement',

  /* Customer Engagement */
  customerEngagement: '/settings/custengage',
  getAllDynamicSection: '/settings/custengage/homescreen/section/all',
  dynamicSection: '/settings/custengage/homescreen/section',
  getAllDynamicSectionItem: '/settings/custengage/homescreen/item/all',
  dynamicSectionItem: '/settings/custengage/homescreen/item',
  getAllVideoCms: '/settings/custengage/videocms/all',
  videoCms: '/settings/custengage/videocms',

  /* Wallet */
  walletDetails: '/wallet/user/wallet/single',
  getAllTransactions: '/wallet/user/wallet/transaction/all',
  addWalletTransaction: '/wallet/user/wallet/transaction',
  suspendWallet: '/wallet/user/wallet/suspend',
  addBulkWalletTransaction: '/wallet/user/wallet/bulk',
  getAllWithdrawRequest: '/wallet/driver/withdraw/list',
  withdrawRequest: '/wallet/driver/withdraw/details',
  updateWithdrawRequest: '/wallet/driver/withdraw/settlement',
  walletDashboard: '/wallet/dashboard/wallet/info/all',
  withdrawDashboard: '/wallet/dashboard/wallet/settlements/all',
  exportAllwidrawals:'/rdrmngmt/management/allrider/excel',
  /* Rides management */
  getAllRideReport: '/rds/driver/ride/list',
  getAllRideReportPartner: '/drivers/driver/ride/list/partner',
  getAllPendingRide: '/rds/driver/ride/list/pending',
  rideReport: '/rds/driver/ride/single',
  rideTrack: '/rds/driver/ride/location',
  rideTimeline: '/rds/driver/ride/timeline',
  rideNotificationLog: '/rds/rider/ride/notify/list',
  rideEstimate: '/rds/driver/ride/estimate',
  downloadInvoice: '/rds/rider/ride/receipt',
  cancelRide: '/rds/driver/ride/cancel/admin',
  assignDriver: '/rds/driver/ride/assign',
  removeDriver: '/rds/driver/ride/assign/remove',
  rideVerification: '/rds/driver/ride/verify',
  driverCommissionReport: '/comser/driver/commission/report',
  getAllEarnings: '/rds/driver/ride/list',
  riderDeviceDetails: '/riders/rider/profile/version',
  driverDeviceDetails: '/drivers/driver/profile/version',
  priceCalculate: '/riders/rider/ride/pricecalculate/admin',
  requestRide: '/riders/rider/ride/request/admin',

  /* Billing Report */ //Incorrect data
  getAllBilling: '/rdrmngmt/management/allrider',
  exportAllBilling: '/rdrmngmt/management/allrider/excel',
  billing: '/rdrmngmt/management/rider',
  inviteBillingEmail: '/rfrl/management/invite/email',
  inviteBillingSms: '/rfrl/management/invite/sms/rider',
  suspendBilling: '/rdrmngmt/management/rider/suspend',
  verifyBilling: '/riders/rider/profile/verify',

  /* Invoicing Report */ //Incorrect data
  getAllInvoicing: '/rdrmngmt/management/allrider',
  exportAllInvoicing: '/rdrmngmt/management/allrider/excel',
  invoicing: '/rdrmngmt/management/rider',
  inviteInvoicingEmail: '/rfrl/management/invite/email',
  inviteInvoicingSms: '/rfrl/management/invite/sms/rider',
  suspendInvoicing: '/rdrmngmt/management/rider/suspend',
  verifyInvoicing: '/riders/rider/profile/verify',

  /* Sales Report */ //Incorrect data
  getAllSales: '/rds/driver/ride/list',
  getAllSalesPartner: '/drivers/driver/ride/list/partner',
  getAllPendingSale: '/rds/driver/ride/list/pending',
  Sales: '/rds/driver/ride/single',

  /* Revenue Report */ //Incorrect data
  getAllRevenue: '/rds/driver/ride/list',
  getAllRevenuePartner: '/drivers/driver/ride/list/partner',
  RevenueVerification: '/rds/driver/ride/verify',
  requestRevenue: '/riders/rider/ride/request/admin',

  /* Exotel */
  initiateOutgoingCall: '/xtl/outgoing',
  getCallLogs: '/xtl/call/details',

  /* Cloud Messaging - FCM */
  updateFcmToken: '/notifi/fcm/token',
  getAllNotification: '/notifi/fcm/notifications',
  markAsRead: '/notifi/fcm/read',

  /* Logs */
  getAllLogs: '/settings/log/all',
  exportAllLogs: '/settings/log/all/excel',

  /* Google proxy calls */
  googleDirections: '/fs/googlemap/directions',
  googleAutocomplete: '/fs/googlemap/places/auto',
  googlePlacesId: '/fs/googlemap/places',

  /* Thingsboard API Calls */
  tbRefreshToken: '/api/auth/token',

  /* External API Calls */
  osmGetPlacesList: 'https://nominatim.openstreetmap.org/search',
  getClientIP: 'https://geolocation-db.com/json/',

}
