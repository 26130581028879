import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { inject } from "@angular/core/testing";
import { BehaviorSubject, Subject } from "rxjs";
// RxJS
import { Observable, of } from "rxjs";
import { catchError, map, mergeMap } from "rxjs/operators";

import { environment } from "src/environments/environment";
import { apiUrl } from '../../_config/api.config';

import { QueryParamsModel } from "../../_base/crud";

const BASE_URL = environment.apiUrl;

@Injectable({ providedIn: "root" })

export class PromotionService {

  constructor(private http: HttpClient) {}

  // Get all promotions with table functions
  getAllPromotions(queryParams: QueryParamsModel): Observable<any> {
    return this.http.get<any>(BASE_URL + apiUrl.promotion +'?isDelete=false&pageSize=' + queryParams.pageSize +
    '&pageNumber=' + queryParams.pageNumber +
    '&sortField=' + queryParams.sortField +
    '&sortOrder=' + queryParams.sortOrder +
    '&searchText=' + queryParams.searchText + queryParams.filter).pipe(
      mergeMap(res => {
        console.log(res);
        if (res.response.code === 200) {
          const result = {
            items: res.data.list,
            totalCount: res.data.totalCount
          };
          return of(result);
        } else {
          const result = {
            items: [],
            totalCount: 0
          };
          return of(result);
        }
      })
    );
  }

  // Get single Promotion
  getSinglePromotion(id): Observable<any> {
    return this.http.get(BASE_URL + apiUrl.promotion +'?_id='+id);
  }

  // Add new promotion
  createPromotion(promotion: any): Observable<any> {
    return this.http.post(BASE_URL + apiUrl.promotion, promotion);
  }

  // Update promotion
  updatePromotion(promotion: any): Observable<any> {
    return this.http.put(BASE_URL + apiUrl.promotion, promotion);
  }

  // Suspend promotion
  suspendPromotion(promotion: any): Observable<any> {
    return this.http.put(BASE_URL + apiUrl.suspendPromotion, promotion);
  }

  // Get all promotion settlement with table functions
  getAllPromotionSettlements(queryParams: QueryParamsModel): Observable<any> {
    return this.http.get<any>(BASE_URL + apiUrl.promotionSettlement +'?pageSize=' + queryParams.pageSize +
    '&pageNumber=' + queryParams.pageNumber +
    '&sortField=' + queryParams.sortField +
    '&sortOrder=' + queryParams.sortOrder +
    '&searchText=' + queryParams.searchText + queryParams.filter).pipe(
      mergeMap(res => {
        console.log(res);
        if (res.response.code === 200) {
          const result = {
            items: res.data.list,
            totalCount: res.data.totalCount
          };
          return of(result);
        } else {
          const result = {
            items: [],
            totalCount: 0
          };
          return of(result);
        }
      })
    );
  }

  // Get single promotion settlement
  getSinglePromotionSettlement(id): Observable<any> {
    return this.http.get(BASE_URL + apiUrl.promotionSettlement +'?_id=' + id);
  }

  // Update / Reject multiple promotion settlement
  updatePromotionSettlement(data): Observable<any> {
    return this.http.put(BASE_URL + apiUrl.promotionSettlement, data);
  }

  // Create order for payout
  createOrder(data): Observable<any> {
    return this.http.post(BASE_URL + apiUrl.promotionSettlementOrder, data);
  }

  // Get all orders with table functions
  getAllOrders(queryParams: QueryParamsModel): Observable<any> {
    return this.http.get<any>(BASE_URL + apiUrl.promotionSettlementOrders +'?isDelete=false&pageSize=' + queryParams.pageSize +
    '&pageNumber=' + queryParams.pageNumber +
    '&sortField=' + queryParams.sortField +
    '&sortOrder=' + queryParams.sortOrder +
    '&searchText=' + queryParams.searchText + queryParams.filter).pipe(
      mergeMap(res => {
        console.log(res);
        if (res.response.code === 200) {
          const result = {
            items: res.data.list,
            totalCount: res.data.totalCount
          };
          return of(result);
        } else {
          const result = {
            items: [],
            totalCount: 0
          };
          return of(result);
        }
      })
    );
  }

  // Get single order
  getSingleOrder(id): Observable<any> {
    return this.http.get(BASE_URL + apiUrl.promotionSettlementOrders +'?_id=' + id);
  }

  // Create order for payout
  verifyPayment(data): Observable<any> {
    return this.http.post(BASE_URL + apiUrl.promotionSettlementPaymentVerify, data);
  }

}
