<div class="dropdown d-inline-block" ngbDropdown>
  <button
    id="closeDialogButton"
    type="button"
    class="btn header-item noti-icon"
    id="page-header-notifications-dropdown"
    ngbDropdownToggle>
    <i class="bx bx-bell bx-tada"></i>
    <span class="badge badge-danger badge-pill" *ngIf="unreadCount() > 0">{{unreadCount()}}</span>
  </button>
  <div
    class="dropdown-menu dropdown-menu-lg dropdown-menu-right p-0"
    aria-labelledby="page-header-notifications-dropdown"
    ngbDropdownMenu>
    <div class="p-3">
      <div class="row align-items-center">
        <div class="col">
          <h6 class="m-0">{{'NOTIFICATIONS.NOTIFICATIONS'|translate}}</h6>
        </div>
        <div class="col-auto">
          <a id="markAllReadLink" href="javascript: void(0);" (click)="markAllRead();closeDropDown();" class="small">{{'NOTIFICATIONS.MARK_ALL_AS_READ'|translate}}</a>
        </div>
      </div>
    </div>
    <!-- <div class="custom-scroll-area" style="position: relative; height: 230px;" [config]="configData"> -->
    <div class="custom-scroll-area overflow-auto" style="position: relative; height: 230px;">
      <a id="navigateToLink" href="javascript:void(0)" [routerLink]="navigateUrl(message)"
      (click)="markAllRead(message._id);closeDropDown();" class="text-reset notification-item" *ngFor="let message of notificationsList; let i = index">
        <div class="media">
          <div class="avatar-xs mr-3">
            <span
              class="avatar-title rounded-circle bg-info font-size-16" [ngClass]="{'bg-warning': message?.priority === 'Low', 'bg-info': message?.priority === 'Normal', 'bg-danger': message?.priority === 'High'
                    }">
              <!-- {{ (message?.priority | slice : 0:1) || 'N' }} -->
              <i class="bx {{getModuleIcon(message.module)}}"></i>
            </span>
          </div>
          <div class="media-body">
            <h6 class="mt-0 mb-1">{{message.title | titlecase}}</h6>
            <div class="font-size-12 text-muted">
              <p class="mb-1">
                {{message.body}}
              </p>
              <p class="mb-0">
                <i class="mdi mdi-clock-outline"></i> {{getTimeAgo(message.time)}}
              </p>
            </div>
          </div>
        </div>
      </a>
    </div>
    <div class="p-2 border-top">
      <a
        id="viewAllLink"
        class="btn btn-sm btn-light btn-block text-center"
        href="javascript:void(0);" (click)="closeDropDown()" routerLink="/notifications">
        <i class="mdi mdi-arrow-down-circle mr-1"></i> View All
      </a>
    </div>
  </div>
</div>
