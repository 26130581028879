// Angular
import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpResponse, HttpClient } from '@angular/common/http';

import { Router } from '@angular/router';
// RxJS
import { Observable, of } from 'rxjs';
import { tap, map, mergeMap, catchError, debounceTime, distinctUntilChanged, switchMap } from 'rxjs/operators';
// Layout
// import { LayoutUtilsService, MessageType } from '../../../../core/_base/crud/';
import { environment } from '../../../../../environments/environment';
import { apiUrl } from '../../../_config/api.config';

const BASE_URL = environment.apiUrl;


/**
 * More information there => https://medium.com/@MetonymyQT/angular-http-interceptors-what-are-they-and-how-to-use-them-52e060321088
 */
@Injectable()
export class InterceptService implements HttpInterceptor {

  constructor(private router: Router,
    private http: HttpClient) {}

  authToken: string = '';

  clientIp: string = '';

  clientMac: string = '';

  isRefreshToken: boolean = false;

  // intercept request and add token
  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    if (request.url.includes('resetPassword') || request.url.includes('https://geolocation-db.com/json/') || request.url.includes('https://nominatim.openstreetmap.org/') || request.url.includes(environment.exotelSubdomain) || request.url.includes(environment.exotelSubdomainV1)) {
      if (request.url.includes(environment.exotelSubdomain)) {
        let authdata = window.btoa(environment.exotelApiKey + ':' + environment.exotelToken);
        request = request.clone({
          setHeaders: {
            'Authorization': `Basic ${authdata}`
          }
        });
      } else if (request.url.includes(environment.exotelSubdomainV1)) {
        let authdata = window.btoa(environment.exotelApiKey + ':' + environment.exotelToken);
        request = request.clone({
          setHeaders: {
            'Authorization': `Basic ${authdata}`,
          }
        });
      } else {
        request = request.clone({});
      }
    } else {
      this.authToken = localStorage.getItem(environment.authTokenKey);
      this.clientIp = localStorage.getItem(environment.clientIpv4);
      this.clientMac = localStorage.getItem(environment.clientMac);
      request = request.clone({
        setHeaders: {
          'token': `${this.authToken}`,
          'clientip4': `${this.clientIp}`,
          'clientmac': `${this.clientMac}`,
          'versioncode': `${environment.versionCode}`,
          'versionname': `${environment.versionName}`,
          'platform': 'web',
          'version': 'v4',
        }
      });
    }

    return next.handle(request).pipe(
      tap(
        event => {
          if (event instanceof HttpResponse) {
            if (event.status === 200) {
              if (!request.url.includes("refreshToken") && localStorage.getItem(environment.authTokenKey) && localStorage.getItem(environment.authTokenKey).length > 0) {
                if (!this.isRefreshToken) {
                  this.refreshToken();
                }
              }
            }
          }
        },
        error => {
          if (error.status === 401) {
            if (!request.url.includes('changePassword') && !request.url.includes(environment.exotelSubdomain)) {
              localStorage.removeItem(environment.authTokenKey);
            }
            if (!request.url.includes('resetPassword') && !request.url.includes('login') && !request.url.includes('changePassword') && !request.url.includes(environment.exotelSubdomain)) {
              this.router.navigate(['/auth/login'], { queryParams: { type: 'expired' } });
            }
          }
        }
      )
    );
  }

  refreshToken() {
    this.isRefreshToken = true;
    return this.http.get<any>(BASE_URL + apiUrl.refreshToken).subscribe(
      res => {
        if (res.response.code === 200) {
          this.isRefreshToken = false;
          localStorage.setItem(environment.authTokenKey, res.data.token);
          this.authToken = res.data.token;
          return null;
        }
      }
    );
  }

  getClientIp() {
    // return this.http.get<any>('https://geolocation-db.com/json/').subscribe(
    //   res => {
    //     console.log(res);
    //     if (res.IPv4) {
    //       localStorage.setItem(environment.clientIpv4, res.IPv4);
    //     }else {
    //       localStorage.setItem(environment.clientIpv4, 'unknown');
    //       return this.clientIp;
    //     }
    //   }
    // );
  }
}
