import { UploadFile } from '../../upload/_models/uploadFile.model';
import { Zone } from './zone.model';

export class City {
  _id: string;
  cityName: string;
  zone: Zone;
  postalCodes: string[];
  cityBoundary: {
    coordinates: any[],
    type: string
  }
  createdById: string;
  isDelete: boolean;
  readOnly: boolean;
  officeAddress: {
    line1: string,
    line2: string,
    postalCode: number,
    contactNumber: {
      number: string,
      countryCode: string,
    },
    lat: number,
    long: number,
  };
  imageId: string
  imageDetails: UploadFile;

  clear(): void {
    this._id = undefined;
    this.cityName = '';
    this.zone = new Zone();
    this.zone.clear();
    this.postalCodes = [];
    this.cityBoundary = {
      coordinates: [],
      type: 'Polygon'
    }
    this.createdById = undefined;
    this.isDelete = false;
    this.readOnly = false;
    this.officeAddress = {
      line1: '',
      line2: '',
      postalCode: undefined,
      contactNumber: {
        number: undefined,
        countryCode: "91"
      },
      lat: 12.909705,
      long: 77.644281,
    };
    this.imageId = undefined;
    this.imageDetails = new UploadFile();
    this.imageDetails.clear();
  }
}
