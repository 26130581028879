import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { inject } from '@angular/core/testing';
import { environment } from 'src/environments/environment';
import { apiUrl } from '../../_config/api.config';
import { BehaviorSubject, Subject } from 'rxjs';
import { QueryParamsModel, QueryResultsModel} from '../../_base/crud';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { Observable, of } from 'rxjs';

const BASE_URL = environment.apiUrl;

@Injectable({providedIn: 'root'})

export class TripFareService {


  constructor(private http: HttpClient){}

  // Get all tripFare
  getAllTripFare(queryParams: QueryParamsModel):Observable<any> {
    return this.http.get<any>(BASE_URL + apiUrl.driverCommissionReport +
    '?pageSize='+queryParams.pageSize +
    '&pageNumber='+queryParams.pageNumber+
    '&sortField='+queryParams.sortField+
    '&sortOrder='+queryParams.sortOrder+
    '&searchText='+queryParams.searchText+
    queryParams.filter).pipe(
      mergeMap(res => {
          console.log(res);
          if(res.response.code === 200) {
              const result = {
                items: res.data.list,
                totalCount: res.data.totalCount
              };
              return of(result);
          } else {
            const result = {
              items: [],
              totalCount: 0
            };
            return of(result);
          }
      })
    );
  }

  // Get a single tripFare by id
  getSingleEarning(_id:string): Observable<any>{
    return this.http.get(BASE_URL + apiUrl.rideReport +'?rideId=' +_id);
  }


  // Handler
  private handleError<T>(rider = 'rider', result?: any) {
    return (error: any): Observable<any> => {
        // TODO: send the error to remote logging infrastructure
        console.error(error); // log to console instead
        // Let the app keep running by returning an empty result.
        return of(result);
    };
  }
}
