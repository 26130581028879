// USA
export const locale = {
  lang: "ml",
  data: {
    "TRANSLATOR": {
      "SELECT": "Select your language",
      "LANGUAGE": "Language",
      "ENGLISH": "English",
      "HINDI": "Hindi",
      "KANNADA": "Kannada",
      "MALAYALAM": "Malayalam"
    },
    "AUTH": {
      "GENERAL": {
        "SIGNIN": "Sign In",
        "SIGNOUT": "Sign Out",
        "SIGNUP": "Create Account",
        "FORGOT_PASSWORD": "Forgot Password?",
        "RESET_PASSWORD": "Reset Password?",
        "TAGLINE": "The best of Guatemala is made as a team!",
        "BACKHOME": "Go back to",
        "HOME": "Home"
      },
    },
    "ERROR_PAGE": {
      "TITLE": "Oops!",
      "SUB_TITLE": "Something went wrong here.",
      "CONTENT": "We're working on it and we'll get it fixed as soon possible. You can back or use our Help Center."
    },
    "COMING_SOON": {
      "TITLE": "Page is under Construction.",
      "SUB_TITLE": "We're working on it and we'll get it fixedas soon possible.",
      "CONTENT": "You can back or use our Help Center."
    },
    Dashboard: {
      Dashboards: "ഡാഷ്ബോർഡ്",
      Rides: "സവാരികൾ",
      Default: "ഡിഫോൾട്ട്",
      Operations: "പ്രവർത്തനങ്ങൾ",
      Driver: "ഡ്രൈവർ",
      Subscriptions: "സബ്സ്ക്രിപ്ഷനുകൾ",
      Sales: "വിൽപ്പന",
      Partners: "പങ്കാളികൾ",
      Revenue: "വരുമാനം",
    },
    Manage: {
      Manage: "മാനേജ്",
      Admin: {
        Admin: "അഡ്മിൻ",
        Users: "ഉപയോക്താക്കൾ",
        Roles: "റോൾ",
        "Work Flow": "വർക്ക് ഫ്ലോ",
      },
      Partners: {
        Partners: "പങ്കാളികൾ",
        Fleets: "ഫ്ലീറ്റ്",
        "Travel Desks": "ട്രാവൽ ഡെസ്ക്",
        Corporates: "കോർപ്പറേറ്റുകൾ",
      },
      "Revenue Model": {
        "Revenue Model": "വരുമാന മാതൃക",
        Subscriptions: "സബ്സ്ക്രിപ്ഷനുകൾ",
        Commission: "കമ്മീഷൻ",
        "Platform Fee": "പ്ലാറ്റ്ഫോം ഫീസ്",
      },
      "Billing & Invoicing": {
        "Billing & Invoicing": "ബില്ലിംഗ് & ഇൻവോയ്സിംഗ്",
        Billed: "ബിൽ ചെയ്തതെല്ലാം",
        Invoiced: "പൂർത്തിയാക്കിയ എല്ലാ ഇൻവോയ്സുകളും",
      },
      Customers: {
        Customers: "ഉപഭോക്താക്കൾ",
        Drivers: "ഡ്രൈവർമാർ",
        Riders: "റൈഡർമാർ",
        "Customer Groups": "ഉപഭോക്തൃ ഗ്രൂപ്പുകൾ",
      },
      Subscriptions: {
        Subscriptions: "സബ്സ്ക്രിപ്ഷനുകൾ",
        Packages: "പാക്കേജുകൾ",
        Subscribers: "വരിക്കാർ",
      },
      Referral: {
        Referral: "റഫറൽ",
        "Referral Promotions": "റഫറൽ പ്രമോഷനുകൾ",
        "Referral Settlement": "റഫറൽ സെറ്റിൽമെൻ്റ്",
      },
      Offers: {
        Offers: "ഓഫറുകൾ",
        Promotions: "പ്രമോഷനുകൾ",
        "Promotion Settlements": "പ്രമോഷൻ സെറ്റിൽമെൻ്റുകൾ",
      },
      Wallet: {
        Wallet: "വാലറ്റ്",
        "Wallet Transactions": "വാലറ്റ് ഇടപാടുകൾ",
        "Withdrawal Requests": "പിൻവലിക്കൽ അഭ്യർത്ഥനകൾ",
      },
      Archived: {
        Archived: "ആർക്കൈവ് ചെയ്തവ",
        Users: "ഉപയോക്താക്കൾ",
        "Fleet Partners": "ഫ്ലീറ്റ് പങ്കാളികൾ",
        "Travel Desk Partners": "ട്രാവൽ ഡെസ്ക് പങ്കാളികൾ",
        "Corporate Partners": "കോർപ്പറേറ്റ് പങ്കാളികൾ",
        "Archived Drivers": "ആർക്കൈവ് ചെയ്ത ഡ്രൈവറുകൾ",
        "Archived Riders": "ആർക്കൈവ് ചെയ്ത റൈഡർമാർ",
      },
      "Invited Users": {
        "Invited Users": "ക്ഷണിക്കപ്പെട്ട ഉപയോക്താക്കൾ",
        "Invited Drivers": "ക്ഷണിക്കപ്പെട്ട ഡ്രൈവർമാർ",
        "Invited Riders": "ക്ഷണിക്കപ്പെട്ട റൈഡർമാർ",
      },
    },
    Rides: {
      Rides: "സവാരികൾ",
      "Book Ride": "റൈഡ് ബുക്ക്",
      "Pending Request": "തീർപ്പുകൽപ്പിക്കാത്ത അഭ്യർത്ഥന",
    },
    Reports: {
      Reports: "റിപ്പോർട്ടുകൾ",
      "Ride Report": "റൈഡ് റിപ്പോർട്ട്",

      Sales: "വിൽപ്പന",
      Revenue: "വരുമാനം",
    },
    "Customer Engagement": {
      "Customer Engagement": "ഉപഭോക്തൃ ഇടപെടൽ",
      "Dynamic Sections": "ചലനാത്മക വിഭാഗങ്ങൾ",
      "Ride Request Tips": "റൈഡ് അഭ്യർത്ഥന Tips",
    },
    "Admin Configurations": {
      "Admin Configurations": "അഡ്മിൻ കോൺഫിഗറേഷനുകൾ",
      "Master Data": {
        "Master Data": "മാസ്റ്റർ ഡാറ്റ",
        Zones: "സോണുകൾ",
        Cities: "നഗരങ്ങൾ",
        Airports: "വിമാനത്താവളങ്ങൾ",
        Tolls: "ടോളുകൾ",
        "Popular Packages": "ജനപ്രിയ പാക്കേജുകൾ",
        Vehicles: "വാഹനങ്ങൾ",
        Reasons: "കാരണങ്ങൾ",
        Attributes: "ആട്രിബ്യൂട്ടുകൾ",
        Taxes: "നികുതികൾ",
      },
      "Control Panel": {
        "Control Panel": "നിയന്ത്രണ പാനൽ",
        "Trip Fare": "യാത്രാക്കൂലി",
        "Commission Settlement": "കമ്മീഷൻ സെറ്റിൽമെൻ്റ്",
        "Tax & Invoicing": "നികുതിയും ഇൻവോയ്‌സിംഗും",
        "Wallet Configuration": "വാലറ്റ് കോൺഫിഗറേഷൻ",
        "Surge Charge": "സർജ് ചാർജ്",
        "Vehicle Groups": "വാഹന ഗ്രൂപ്പുകൾ",
        "Target Configuration": "ടാർഗെറ്റ് കോൺഫിഗറേഷൻ",
        "KYC Configuration": "KYC കോൺഫിഗറേഷൻ",
        "General Settings": "പൊതുവായ ക്രമീകരണങ്ങൾ",
      },
    },
    Logs: {
      Logs: "ലോഗുകൾ",
      "Audit Logs": "ഓഡിറ്റ് ലോഗുകൾ",
    },
    //common
    //Dashboards
    "On Trip": "യാത്രയിൽ",
    Available: "ലഭ്യമായവ",
    //Help
    "User Guide": "ഉപയോക്തൃ ഗൈഡ്",
    FAQ: "പതിവുചോദ്യങ്ങൾ",
    Support: "പിന്തുണ",
    "Change Log": "ചേഞ്ച്ലോഗ്",

    //Tools
    "URL Shortner": "URL ഷോർട്ടനർ",
    "Data Sync": "ഡാറ്റ സമന്വയം",
    //Dashboard
    "All Zones": "എല്ലാ സോണുകളും",
    "Welcome to viaDOTS.. dashboard": "viaDOTS.. ഡാഷ്‌ബോർഡിലേക്ക് സ്വാഗതം.",
    "Super Admin": "സൂപ്പർ അഡ്മിൻ",

    //rides dashboard
    Offline: "ഓഫ്‌ലൈൻ ആയവ",
    "Total Rides": "മൊത്തം റൈഡുകൾ",
    "Upcoming Rides": "വരാനിരിക്കുന്ന റൈഡുകൾ",
    "Completed Rides": "പൂർത്തിയാക്കിയ റൈഡുകൾ",
    "Taxi Live Location": "ടാക്സി ലൈവ് ലൊക്കേഷൻ",
    Cancelled: "റദ്ദാക്കിയവ",
    "Total Scheduled Rides": "ആകെ ഷെഡ്യൂൾ ചെയ്ത റൈഡുകൾ",
    "Scheduled for the day": "1 ദിവസത്തേക്ക് ഷെഡ്യൂൾ ചെയ്ത റൈഡുകൾ",
    "Scheduled Today": "ഇന്ന് ഷെഡ്യൂൾ ചെയ്തവ",
    "Scheduled for Future": "ഭാവിയിൽ ഷെഡ്യൂൾ ചെയ്തവ",
    Requested: "ആവശ്യപ്പെട്ടവ",
    Accepted: "സ്വീകരിച്ചവ",
    Rejected: "നിരസിച്ചവ",
    Completed: "പൂർത്തിയാക്കിയവ",
    "Cancelled by Driver": "ഡ്രൈവർ റദ്ദാക്കിയവ",
    "Cancelled by Rider": "റൈഡർ റദ്ദാക്കിയവ",
    "Meter Taxi Initiated": "മീറ്റർ ടാക്സി ആരംഭിച്ചവ",
    "Meter Taxi Completed": "മീറ്റർ ടാക്സി പൂർത്തിയാക്കിയവ",
    "Total Ride Fare": "മൊത്തം റൈഡ് നിരക്ക്",
    "Total Ride Fare (Meter Taxi)": "മൊത്തം യാത്രാ നിരക്ക് (മീറ്റർ ടാക്സി)",
    "Ride Request Analytics": "സവാരി അഭ്യർത്ഥന വിശകലനം",
    "Ride Accept Analytics": "സവാരി സ്വീകരിക്കൽ വിശകലനം",
    "Ride Reject Analytics": "സവാരി നിരാകരണം വിശകലനം",
    "Ride Completed Analytics": "സവാരി പൂർത്തീകരണം വിശകലനം",
    "Ride Cancelled By Driver": "ഡ്രൈവർ സവാരി റദ്ദാക്കിയവ",
    "Ride Cancelled By Rider": "റൈഡർ സവാരി റദ്ദാക്കിയവ",
    "Meter Taxi Ride Initialed": "മീറ്റർ ടാക്‌സി സവാരി ആരംഭിച്ചവ",
    "Meter Taxi Ride Completed": "മീറ്റർ ടാക്‌സി സവാരി പൂർത്തിയായവ",
    //sales dashboard
    Corporate: "കോർപ്പറേറ്റ്",
    Fleet: "ഫ്ലീറ്റ്",
    "Travel Desk": "ട്രാവൽഡെസ്ക്",
    Earning: "വരുമാനം",
    "Sales Analytics": "സെയിൽസ് അനലിറ്റിക്സ്",
    "Top Performers": "മികച്ച പ്രകടനം നടത്തുന്നവർ",
    "Onboarding Count": "ഓൺബോർഡിംഗ് എണ്ണം",
    //partners dashboard
    Partner: "പങ്കാളി",
    Travel: "ട്രാവൽ",
    "Today Usage Analytics": "ഇന്നത്തെ ഉപയോഗ വിശകലനം",
    "Usage Analytics": "ഉപയോഗ അനലിറ്റിക്സ്",
    Transactions: "ഇടപാടുകൾ",
    "Total Turnover": "മൊത്തം വിറ്റുവരവ്",
    "Total Transactions": "മൊത്തം ഇടപാടുകൾ",
    "Detailed View": "വിശദമായ കാഴ്ച",
    Total: "ആകെ",
    Expense: "ചെലവ്",
    Income: "വരുമാനം",
    "From previous period": "മുൻ കാലഘട്ടത്തിൽ നിന്ന്",
    "Fleet Partners": "ഫ്ലീറ്റ് പങ്കാളികൾ",
    "View Details": "വിശദാംശങ്ങൾ കാണുക",
    "New Partners": "പുതിയ പങ്കാളികൾ",
    "Travel Desk Partners": "ട്രാവൽ ഡെസ്ക് പങ്കാളികൾ",
    "Corporate Partners": "കോർപ്പറേറ്റ് പങ്കാളികൾ",
    "Corporate Revenue": "കോർപ്പറേറ്റ് വരുമാനം",
    //Revenue
    "This month": "ഈ മാസം",
    "Last month": "കഴിഞ്ഞ മാസം",
    "Travel Desk Revenue": "ട്രാവൽഡെസ്ക് വരുമാനം",
    "Total Revenue": "മൊത്തം വരുമാനം",
    "Revenue from Partners": "പങ്കാളികളിൽ നിന്നുള്ള വരുമാനം",
    //Sbscriptions Dashboard
    "Most Used Packages": "ഏറ്റവും കൂടുതൽ ഉപയോഗിക്കുന്ന പാക്കേജുകൾ",
    "Total Subscriptions": "മൊത്തം സബ്സ്ക്രിപ്ഷനുകൾ",
    "Subscribed Drivers in viaDots":
      "ഡോട്ട്‌സ് വഴി സബ്‌സ്‌ക്രൈബുചെയ്‌ത ഡ്രൈവറുകൾ",
    Packages: "പാക്കേജുകൾ",
    "Subscribed Drivers in Corporate": "കോർപ്പറേറ്റിലെ വരിക്കാരായ ഡ്രൈവർമാർ",
    "Total Subscribers": "മൊത്തം വരിക്കാർ",
    "Subscribed Drivers in Fleet": "ഫ്ലീറ്റിൽ സബ്‌സ്‌ക്രൈബ് ചെയ്‌ത ഡ്രൈവർമാർ",
    "Subscribed Drivers in Travel Desk":
      "ട്രാവൽ ഡെസ്‌കിൽ സബ്‌സ്‌ക്രൈബ് ചെയ്‌ത ഡ്രൈവർമാർ",
    "Subscribed Package": "സബ്സ്ക്രൈബ് ചെയ്ത പാക്കേജ്",
    "Total Drivers": "മൊത്തം ഡ്രൈവറുകൾ",
    "Ready for Ride Drivers": "റൈഡ് ഡ്രൈവർമാർക്ക് തയ്യാറാണ്",
    "On Trip Drivers": "ഓൺ ട്രിപ്പ് ഡ്രൈവർമാർ",
    "Online Drivers": "ഓൺലൈൻ ഡ്രൈവർമാർ",
    "Offline Drivers": "ഓഫ്‌ലൈൻ ഡ്രൈവറുകൾ",
    "Driver Analytics": "ഡ്രൈവർ അനലിറ്റിക്സ്",
    "KYC Status": "KYC നില",
    "Documents Expiry": "പ്രമാണങ്ങൾ കാലഹരണപ്പെടുന്നു",
    "Total KYC Submissions": "ആകെ KYC സമർപ്പിക്കലുകൾ",
    //Admin
    //users
    "New User": "പുതിയ ഉപയോക്താവ്",
    Photo: "ഫോട്ടോ",
    "Employee ID": "ജീവനക്കാരുടെ ഐഡി",
    Name: "പേര്",
    Phone: "ഫോൺ",
    Email: "ഇമെയിൽ",
    Role: "റോൾ",
    Assets: "ആസ്തികൾ",
    Status: "നില",
    "Joining Date": "ചേരുന്ന തീയതി",
    //users details page
    "Member View Details": "അംഗത്തിൻ്റെ വിശദാംശങ്ങൾ",
    Zones: "സോണുകൾ",
    Address: "വിലാസം",
    Analytics: "അനലിറ്റിക്സ്",
    Documents: "രേഖകൾ",
    "Call Logs": "കോൾ ലോഗുകൾ",
    "Actions Taken": "സ്വീകരിച്ച നടപടികൾ",
    Remarks: "അഭിപ്രായങ്ങൾ",
    //New User/Edit
    "Change Photo": "ഫോട്ടോ മാറ്റുക",
    "Date Of Joining": "ചേരുന്ന തീയതി",
    "Phone Number": "ഫോൺ നമ്പർ",
    "Account Type": "അക്കൗണ്ട് തരം",
    Back: "മടങ്ങിപ്പോവുക",
    "Add New Member": "പുതിയ അംഗത്തെ ചേർക്കുക",
    "Edit Member": "അംഗം എഡിറ്റ് ചെയ്യുക",
    Save: "സേവ്",
    Cancel: "റദ്ദാക്കുക",
    Roles: "റോൾ",
    "New Role": "പുതിയ റോൾ",
    "Role Details": "റോൾ വിശദാംശങ്ങൾ",
    "Role Name": "റോളിൻ്റെ പേര്",
    //work flow
    "Workflow not yet added": "വർക്ക്ഫ്ലോ ഇതുവരെ ചേർത്തിട്ടില്ല",
    "Please click on add workflow button to start creating the workflow":
      "വർക്ക്ഫ്ലോ സൃഷ്‌ടിക്കാൻ ആരംഭിക്കുന്നതിന് വർക്ക്ഫ്ലോ ചേർക്കുക ബട്ടണിൽ ക്ലിക്കുചെയ്യുക",
    "Add Work Flow": "വർക്ക് ഫ്ലോ ചേർക്കുക",
    //Partners
    //fleet
    "Fleet ID": "ഫ്ലീറ്റ് ഐഡി",
    "Fleet Name": "ഫ്ലീറ്റ് നാമം",
    Type: "ടൈപ്പ്",
    "Primary Contact": "പ്രാഥമിക കോൺടാക്റ്റ്",
    Rating: "റേറ്റിംഗ്",
    "Onboarded On": "ബോർഡ് ചെയ്ത തീയതി",
    "Last Activity": "അവസാന പ്രവർത്തനം",
    "Add New": "പുതിയത് ചേർക്കുക",
    //Fleet Details
    "Requested At": "അഭ്യർത്ഥിച്ചു",
    "Requested For": "അഭ്യർത്ഥിച്ചു",
    //Trave Desk
    "Travel Desk ID": "ട്രാവൽ ഡെസ്ക് ഐഡി",
    "Travel Desk Name": "ട്രാവൽ ഡെസ്‌കിൻ്റെ പേര്",
    "Vehicle Group": "വാഹന ഗ്രൂപ്പ്",
    Includes: "ഇവ ഉൾപ്പെടുന്നു",
    "Price Based On": "വില അടിസ്ഥാനമാക്കിയുള്ളത്",
    "Fare Details": "യാത്രാക്കൂലി വിശദാംശങ്ങൾ",
    "Location Details": "ലൊക്കേഷൻ വിശദാംശങ്ങൾ",
    Destination: "ലക്ഷ്യസ്ഥാനം",
    Source: "ഉറവിടം",
    "Accepted By": "സ്വീകരിച്ചത്",
    "Requested By": "ആവശ്യപ്പെട്ടത്",
    Remove: "നീക്കം ചെയ്യുക",
    "Reg No": "രജിസ്ട്രേഷൻ നമ്പർ",
    Vehicle: "വാഹനം",
    "Drivers Nearby": "സമീപത്തുള്ള ഡ്രൈവർമാർ",
    "Based on real-time status": "തത്സമയ നിലയെ അടിസ്ഥാനമാക്കി",
    "Search Distance": "തിരയൽ ദൂരം",
    "RFR - Drivers": "RFR - ഡ്രൈവറുകൾ",
    Online: "ഓൺലൈൻ",
    OnTrip: "ഓൺട്രിപ്പ്",
    "Package Details": "പാക്കേജ് വിശദാംശങ്ങൾ",
    Distance: "ദൂരം",
    Duration: "ദൈർഘ്യം",
    "Start ODO": "ODO ആരംഭിക്കുക",
    "End ODO": "ODO അവസാനിപ്പിക്കുക",
    "Total Amount": "മൊത്തം തുക",
    "Payment Mode": "പേയ്മെൻ്റ് മോഡ്",
    "Payment Status": "പേയ്മെൻ്റ് നില",
    "Partner Dashboard": "പങ്കാളി ഡാഷ്ബോർഡ്",
    Subscriptions: "സബ്സ്ക്രിപ്ഷനുകൾ",
    Commissions: "കമ്മീഷനുകൾ",
    "Billing and Invoicing": "ബില്ലിംഗും ഇൻവോയ്‌സിംഗും",
    "Activity Logs": "പ്രവർത്തന ലോഗുകൾ",
    "Scheduled For": "ഷെഡ്യൂൾ ചെയ്തിരിക്കുന്നത്",
    "Travel Desk Dashboard": "ട്രാവൽ ഡെസ്ക് ഡാഷ്ബോർഡ്",
    //Corporate
    "Corporate ID": "കോർപ്പറേറ്റ് ഐഡി",
    "Corporate Name": "കോർപ്പറേറ്റ് പേര്",
    "No Dedicated Commission Settlements Found":
      "സമർപ്പിത കമ്മീഷൻ സെറ്റിൽമെൻ്റുകളൊന്നും കണ്ടെത്തിയില്ല",
    "You can create a new commission settlement rates for selected zone/city":
      "തിരഞ്ഞെടുത്ത മേഖല/നഗരത്തിനായി നിങ്ങൾക്ക് ഒരു പുതിയ കമ്മീഷൻ സെറ്റിൽമെൻ്റ് നിരക്കുകൾ സൃഷ്ടിക്കാൻ കഴിയും",
    "Create Commission Settlement": "കമ്മീഷൻ സെറ്റിൽമെൻ്റ് ഉണ്ടാക്കുക",
    "Manage Driver Commission Settings":
      "ഡ്രൈവർ കമ്മീഷൻ ക്രമീകരണങ്ങൾ നിയന്ത്രിക്കുക",
    "Amount Payable Configurations": "അടയ്‌ക്കേണ്ട തുക കോൺഫിഗറേഷനുകൾ",
    "Commission Amount Type": "കമ്മീഷൻ തുക തരം",
    Percentage: "ശതമാനം",
    Fixed: "നിശ്ചയിച്ചു",
    "Amount Payable Clearence Cycle": "അടയ്‌ക്കേണ്ട തുക ക്ലിയറൻസ് സൈക്കിൾ",
    Daily: "ദിവസേന",
    Weekly: "പ്രതിവാരം",
    Monthly: "പ്രതിമാസ",
    "Amount Payable Clearence On": "അടയ്‌ക്കേണ്ട തുക ക്ലിയറൻസ് ഓണാണ്",
    "First Day": "ആദ്യ ദിവസം",
    "Last Day": "അവസാന ദിവസം",
    //Billing and Invoicing
    "Subscription Details": "സബ്സ്ക്രിപ്ഷൻ വിശദാംശങ്ങൾ",
    "Ride Details": "റൈഡ് വിശദാംശങ്ങൾ",
    "Driver ID": "ഡ്രൈവർ ഐഡി",
    "Driver Name": "ഡ്രൈവറുടെ പേര്",

    "Driver Type": "ഡ്രൈവർ തരം",

    "Package & Validity Details": "പാക്കേജ് & സാധുത വിശദാംശങ്ങൾ",
    "Subscribed On": "സബ്സ്ക്രൈബ് ചെയ്തു",
    "Start Date": "ആരംഭിക്കുന്ന തീയതി",
    "Expiry Date": "കാലഹരണപ്പെടുന്ന തീയതി",
    "RazorPay Order ID": "RazorPay ഓർഡർ ഐഡി",
    "Download Invoice": "ഇൻവോയ്സ് ഡൗൺലോഡ് ചെയ്യുക",
    "New Group": "പുതിയ ഗ്രൂപ്പ്",
    "Group ID": "ഗ്രൂപ്പ് ഐഡി",
    "Group Name": "ഗ്രൂപ്പിൻ്റെ പേര്",
    "Total Members": "ആകെ അംഗങ്ങൾ",
    "Customer": "ഉപഭോക്താവ്",
    "Zone": "മേഖല",
    "City": "നഗരം",
    "No Data Available": "ഡാറ്റ ലഭ്യമല്ല",
    "Please wait": "കാത്തിരിക്കൂ",
    "Driver List": "ഡ്രൈവർ ലിസ്റ്റ്",
    "Selected Count": "തിരഞ്ഞെടുത്ത എണ്ണം",
    "Add Selected": "തിരഞ്ഞെടുത്തവ ചേർക്കുക",
    "Phone/Email": "ഫോൺ/ഇമെയിൽ",
    "Subscription Status": "സബ്സ്ക്രിപ്ഷൻ നില",
    //subscriptions
    "Package Name": "പാക്കേജ്",
    "Amount": "തുക",
    "Tenure": "കാലാവധി",
    //referal
    "Referral ID": "റഫറൽ ഐഡി",
    "Inviter": "ആഹ്വാന",
    "Inviter Reward": "ആഹ്വാനകർത്താവിന്റെ പ്രതിഫലം",
    "Invitee": "ആഹ്വാനം ചെയ്യപ്പെട്ടവൻ",
    "Invitee Reward": "ആഹ്വാനിതന്റെ പ്രതിഫലം",
    "Settlement ID": "സെറ്റിൽമെന്റ് ഐഡി",
    "User Details": "ഉപയോക്തൃ വിശദാംശങ്ങൾ",
    "Referral Details": "റഫറൽ വിശദാംശങ്ങൾ",
    "Date": "തിയതി",
    "Settlement Timeline": "സെറ്റിൽമെന്റ് സമയരേഖ",
    "Settlement Amount": "സെറ്റിൽമെന്റ് തുക",
    "Is Eligible": "അർഹതയുണ്ടോ",
    "Settlement Status": "സെറ്റിൽമെന്റ് നില",
    "Promotion ID": "പ്രമോഷൻ ഐഡി",
    "Promotion Details": "പ്രമോഷൻ വിശദാംശങ്ങൾ",
    "Value": "മൂല്യം",
    "Offer Type": "ഓഫർ തരം",
    "Update": "അപ്ഡേറ്റ്",
    "Payout": "പേയൗട്ട്",
    "TRIP ID": "ട്രിപ്പ് ഐഡി",
    "Initiated At": "ആരംഭിച്ചതിന്റെ സമയം",
    "Settled At": "തിരിച്ചടവ് പൂർത്തിയായി",
    // Wallet
    "Transaction ID": "ഇടപാട് ഐഡി",
    "Time": "സമയം",
    "Transaction Details": "ഇടപാട് വിശദാംശങ്ങൾ",
    "Add Transaction": "ഇടപാട് ചേർക്കുക",
    "Settled Amount": "സെറ്റിൽഡ് തുക",
    "Processed Amount": "പ്രോസസ്സ് ചെയ്ത തുക",
    "Pending Amount": "കെട്ടിക്കിടക്കുന്ന തുക",
    "Rejected Amount": "നിരസിച്ച തുക",

    "Request ID": "ഐഡി അഭ്യർത്ഥിക്കുക",
    "Requested Date": "",
    "User Type: Driver": "ഉപയോക്തൃ തരം: ഡ്രൈവർ",
    "User Type: Rider": "ഉപയോക്തൃ തരം: റൈഡർ",
    "Reason": "കാരണം",
    "Withdrawal Request Details": "പിൻവലിക്കൽ അഭ്യർത്ഥന വിശദാംശങ്ങൾ",
    "Email/Phone": "ഇമെയിൽ/ഫോൺ",
    "Delete Reason": "ഇല്ലാതാക്കാനുള്ള കാരണം",
    "Fleet Type": "ഫ്ലീറ്റ് തരം",
    "Rider ID": "റൈഡർ ഐഡി",
    "Rider Type": "റൈഡർ തരം",
    "Primary Driver": "പ്രാഥമിക ഡ്രൈവർ",
    "Invitee Number": "ആഹ്വാനം ചെയ്ത നമ്പർ",
    "Invitee Details": "ആഹ്വാനം ചെയ്തവരുടെ വിശദാംശങ്ങൾ",
    "Invited By": "ആഹ്വാനം ചെയ്തവർ",
    "Invited Date": "ആഹ്വാനം ചെയ്ത തീയതി",
    "Promotion Applicable": "പ്രമോഷൻ പ്രയോഗിക്കാവുന്നുണ്ടോ",

    //Book Ride
    "Book A Ride": "റൈഡ് ബുക്ക് ചെയ്യുക",
    "viaCASH Balance": "viaCASH ബാലൻസ്",
    "Airport Ride Details": "ഏയർപോർട്ട് റൈഡ് വിശദാംശങ്ങൾ",
    "Rental Ride Details": "റെന്റൽ റൈഡ് വിശദാംശങ്ങൾ",
    "Outstation Ride Details": "ഔട്സ്റ്റേഷൻ റൈഡ് വിശദാംശങ്ങൾ",
    "From Airport": "ഏയർപോർട്ടിൽ നിന്ന്",
    "To Airport": "ഏയർപോർട്ടിലേക്ക്",
    "Hourly": "മണിക്കൂറിനുള്ളിൽ",
    "Package": "പാക്കേജ്",
    "Round Trip": "റൗണ്ട് ട്രിപ്പ്",
    "Oneway Trip": "വൺവേ യാത്ര",
    "Pickup Location": "പിക്കപ്പ് സ്ഥലം",
    "Pickup Location is required": "പിക്കപ്പ് സ്ഥലം ആവശ്യമാണ്",
    "Airport": "ഏയർപോർട്ട്",
    "Airport is required": "ഏയർപോർട്ട് ആവശ്യമാണ്",
    "Dropoff Location is required": "ഡ്രോപ്പോഫ് സ്ഥലം ആവശ്യമാണ്",
    "Booking Mode": "ബുക്കിംഗ് മോഡ്",
    "Booking mode is required": "ബുക്കിംഗ് മോഡ് ആവശ്യമാണ്",
    "Pickup Date": "പിക്കപ്പ് തീയതി",
    "Pickup Date is required": "പിക്കപ്പ് തീയതി ആവശ്യമാണ്",
    "Pickup time must be at least 2 hours from the current time": "പിക്കപ്പ് സമയം നിലവിലെ സമയത്തിൽ നിന്ന് കൂടുതലായി രണ്ട് മണിക്കൂറുകൾ ആവശ്യമാണ്",
    "Pickup Time": "പിക്കപ്പ് സമയം",
    "Pickup Time is required": "പിക്കപ്പ് സമയം ആവശ്യമാണ്",
    "Invalid Pickup Time": "അസാധുവായ പിക്കപ്പ് സമയം",
    "Return Date": "തിരികെ തീയതി",
    "Return Date is required": "തിരികെ തീയതി ആവശ്യമാണ്",
    "Return time must be at least 2 hours from the pickup time": "തിരികെ സമയം പിക്കപ്പ് സമയത്തിൽ നിന്ന് കൂടുതലായി രണ്ട് മണിക്കൂറുകൾ ആവശ്യമാണ്",
    "Return Time": "തിരികെ സമയം",
    "Return Time is required": "തിരികെ സമയം ആവശ്യമാണ്",
    "Return time must be at least 2 hours after the pickup time": "തിരികെ സമയം പിക്കപ്പ് സമയത്തിൽ നിന്ന് കൂടുതലായി രണ്ട് മണിക്കൂറുകൾ ആവശ്യമാണ്",
    "Rider Contact Details To Share Ride OTP With Rider": "റൈഡ് ഓട്ടോടിപി റൈഡർക്ക് പങ്കിടാനുള്ള റൈഡർ കോൺടാക്റ്റ് വിശദാംശങ്ങൾ",
    "Code": "കോഡ്",
    "Phone number is required": "ഫോൺ നമ്പർ ആവശ്യമാണ്",
    "Minimum 6 digits required": "അത്യകം 6 അക്ഷരങ്ങൾ ആവശ്യമാണ്",
    "Maximum 13 digits allowed": "പരമാവധി 13 അക്ഷരങ്ങൾ അനുവദിക്കുന്നു",
    "Please enter valid number ex: 9876543210": "സാധുവായ നമ്പർ നൽകുക ex: 9876543210",
    "Select Rider": "റൈഡർ തിരഞ്ഞെടുക്കുക",
    "Coupon Code": "കൂപ്പൺ കോഡ്",
    "Coupon code is required": "കൂപ്പൺ കോഡ് ആവശ്യമാണ്",
    "Invalid promotion code": "അസാധുവായ പ്രമോഷൻ കോഡ്",
    "Choose Vehicle Type": "വാഹന തരം തിരഞ്ഞെടുക്കുക",
    "Offer Applied": "ഓഫർ അപ്ലൈ ചെയ്തു",
    "Payment Details": "പേയ്മെന്റ് വിശദാംശങ്ങൾ",
    "Use wallet balance to pay the ride fare": "റൈഡ് ഫേയർ പാവതിക്കാനായി വാലറ്റ് ബാലൻസ് ഉപയോഗിക്കുക",
    "Use Wallet Balance": "വാലറ്റ് ബാലൻസ് ഉപയോഗിക്കുക",
    "viaCASH": "വിയാക്യാഷ്",
    "Use upto 100% of the ride fare": "റൈഡ് ഫേയിന്റെ 100% വരെ ഉപയോഗിക്കുക",
    "viaCOIN": "വിയാകോയിൻ",
    "Usage restrictions applicable": "ഉപയോഗ നിയന്ത്രണങ്ങൾ ബാധകമാണ്",
    "Reset": "റിസെറ്റ്",
    "Calculate Price": "വില കണക്കാക്കുക",
    "Book Ride": "റൈഡ് ബുക്ക് ചെയ്യുക",
    "Configuration is missing for selected zone and city": "തിരഞ്ഞെടുത്ത മേഖലയും നഗരവും കൊണ്ഫിഗറേഷൻ ഇല്ല",
    "Bulk Uploading in progress": "ബുൽക് അപ്ലോഡ് പുരോഗമിക്കുന്നു",
    "Showing rides from last 10 days": "കഴിഞ്ഞ 10 ദിവസങ്ങളിൽ നടന്ന റൈഡുകൾ കാണിക്കുന്നു",
    "Total Ride Amount": "മൊത്ത റൈഡ് തുക",
    "Ride ID": "റൈഡ് ഐഡി",
    "Drivers": "ഡ്രൈവറുകൾ",
    "Vehicle Reg. No.": "വാഹന നമ്പർ",
    "Riders": "റൈഡർമാർ",
    "Partners": "പങ്കാളികൾ",
    "Drop off Location": "ഡ്രോപ്പ് ഓഫ് സ്ഥലം",
    "Ride Fare": "റൈഡ് ഫേയർ",
    "Ride Type": "റൈഡ് തരം",
    "Reject Activity": "നിരസിക്കൽ പ്രവർത്തനം",
    "Share Link": "ലിങ്ക് പങ്കിക്കുക",
    "Completed Time": "പൂർത്തിയായ സമയം",
    "Download Receipt": "രസീത് ഡൗൺലോഡ് ചെയ്യുക",
    "Not Verified": "പരിശോധിച്ചില്ല",
    "Location": "സ്ഥലം",
    "Timeline": "സമയരേഖ",
    "Ride Notification Log": "റൈഡ് അറിയിപ്പ് ലോഗ്",
    "Trip Fare": "ട്രിപ്പ് ഫേയർ",
    "Commission": "കമ്മീഷൻ",
    "Reviews & Reasons": "അവലോകനങ്ങൾ & കാരണങ്ങൾ",
    "Chats & Calls": "ചാറ്റുകൾ & കോൾസ്",
    "Ride Verification": "റൈഡ് പരിശോധന",
    "Remarks & Attachments": "അഭിപ്രായങ്ങൾ & അടയ്ക്കുകള്",
    "Invoice Details": "ഇൻവോയ്സ് വിശദാംശങ്ങൾ",
    "Billing Details": "ബില്ലിംഗ് വിശദാംശങ്ങൾ",
    "Partner Details": "പങ്കാളി വിശദാംശങ്ങൾ",
    "Assign Driver": "ഡ്രൈവർ നിയമിക്കുക",
    "Title": "തലക്കെട്ട്",
    "Long Title": "ലോംഗ് തലക്കെട്ട്",
    "Section Type": "വിഭാഗ തരം",
    "Sort Order": "വിവരണ ആരോപണം",
    "Ride Request Search Text": "റൈഡ് അഭ്യർത്ഥന തിരയൽ ടെക്സ്റ്റ്",
    "Ride Request Tips": "റൈഡ് അഭ്യർത്ഥന ടിപ്സ്",
    "Action": "പ്രവൃത്തി",
    "Add": "ചേർക്കുക",
    "Zone Name": "മേഖല പേര്",
    "Country": "രാജ്യം",
    "State": "സംസ്ഥാനം",
    "New Zone": "പുതിയ മേഖല",
    "City Name": "നഗരം",
    "City Boundary": "നഗര ബൗണ്ഡറി",
    "New City": "പുതിയ നഗരം",
    "Airport Name": "ഏയർപോർട്ട് പേര്",
    "New Airport": "പുതിയ ഏയർപോർട്ട്",
    "Toll Boundary": "ടോൾ ബൗണ്ഡറി",
    "Toll Amount": "ടോൾ തുക",
    "Toll Name": "ടോൾ പേര്",
    "New Toll": "പുതിയ ടോൾ",
    "Locations": "സ്ഥലങ്ങൾ",
    "Manage Popular Locations": "ലോകേഷനുകൾ നിയന്ത്രിക്കുക",
    "New Popular Package": "പുതിയ പൊപ്പുലർ പാക്കേജ്",
    "Spending Duration (Min)": "ചെലവഴിക്കുന്ന ദൈർഘ്യം (മിനിറ്റ്)",
    "Popular Locations": "പൊപ്പുലർ ലൊക്കേഷനുകൾ",
    "Description": "വിവരണം",
    "Package Title": "പാക്കേജ് തലക്കെട്ട്",
    "Location Name": "ലൊക്കേഷൻ്റെ പേര്",
    "New Popular Location": "പുതിയ പൊപ്പുലർ ലൊക്കേഷൻ",
    "Manage Attributes": "ആട്രിബ്യൂട്ടുകൾ നിയന്ത്രിക്കുക",
    "New Vehicle": "പുതിയ വാഹനം",
    "Model": "മോഡൽ",
    "Brand": "ബ്രാൻഡ്",
    "Seats": "സീറ്റുകൾ",
    "Languages": "ഭാഷകൾ",
    "Tax Name": "നികുതിയുടെ പേര്",
    "New Tax": "പുതിയ നികുതി",
    "City Ride": "സിറ്റി റൈഡ്",

    "Outstation One Way Trip": "ഔട്ട്‌സ്റ്റേഷൻ വൺവേ ട്രിപ്പ്",
    "Outstation Round Trip": "ഔട്ട്‌സ്റ്റേഷൻ റൗണ്ട് ട്രിപ്പ്",
    "Allowed Seats": "അനുവദിച്ച സീറ്റുകൾ",
    "No Dedicated Fare Charge Found": "ഡെഡിക്കേറ്റഡ് ഫെയർ ചാർജൊന്നും കണ്ടെത്തിയില്ല",
    "You can create a new fare charge for selected zone/city/vehicle catgory": "തിരഞ്ഞെടുത്ത സോൺ/നഗരം/വാഹന വിഭാഗത്തിനായി നിങ്ങൾക്ക് ഒരു പുതിയ നിരക്ക് ചാർജ് സൃഷ്ടിക്കാൻ കഴിയും",
    "Create Fare Charge": "നിരക്ക് ചാർജ് സൃഷ്ടിക്കുക",
    "Airport Ride": "എയർപോർട്ട് റൈഡ്",
    "Rental": "വാടകയ്ക്ക്",
    "Breakup Configurations": "ബ്രേക്ക്അപ്പ് കോൺഫിഗറേഷനുകൾ",
    "No Dedicated Tax/Invoice details Found": "സമർപ്പിത നികുതി/ഇൻവോയ്സ് വിശദാംശങ്ങളൊന്നും കണ്ടെത്തിയില്ല",
    "You can create a new ta/invoice details for selected zone/city": "തിരഞ്ഞെടുത്ത സോൺ/നഗരത്തിനായി നിങ്ങൾക്ക് ഒരു പുതിയ ടാ/ഇൻവോയ്സ് വിശദാംശങ്ങൾ സൃഷ്ടിക്കാൻ കഴിയും",
    "Create Tax Details": "നികുതി വിശദാംശങ്ങൾ സൃഷ്ടിക്കുക",
    "Wallet Configuration For": "വാലറ്റ് കോൺഫിഗറേഷൻ വേണ്ടി",
    "viaCash Configuration": "വിയാകാഷ് കോൺഫിഗറേഷൻ",
    "viaCoin Configuration": "വിയാക്കോയിൻ കോൺഫിഗറേഷൻ",
    "No Dedicated Wallet Configuration details Found for viaCash": "വിയാകാഷിനായി ഡെഡിക്കേറ്റഡ് വാലറ്റ് കോൺഫിഗറേഷൻ വിശദാംശങ്ങൾ കണ്ടെത്തിയില്ല",
    "You can create a new configuration details for selected zone/city/customer": "തിരഞ്ഞെടുത്ത മേഖല/നഗരം/ഉപഭോക്താവിന് പുതിയ കോൺഫിഗറേഷൻ വിശദാംശങ്ങൾ സൃഷ്ടിക്കാം",
    "No Dedicated Wallet Configuration details Found for viaCoin": "വഴി കോയിനിനായി സമർപ്പിത വാലറ്റ് കോൺഫിഗറേഷൻ വിശദാംശങ്ങളൊന്നും കണ്ടെത്തിയില്ല",
    "Included": "ഉൾപ്പെടുത്തിയിട്ടുണ്ട്",
    "Pricing": "വിലനിർണ്ണയം",
    "New Vehicle Group": "പുതിയ വാഹന ഗ്രൂപ്പ്",
    "Vehicle Category": "വാഹന വിഭാഗം",
    "Ride Cancelled By Rider Count": "റൈഡർ എണ്ണം പ്രകാരം റൈഡ് റദ്ദാക്കി",
    "Target Configuration (KPI) For": "ടാർഗറ്റ് കോൺഫിഗറേഷൻ (KPI) വേണ്ടി",
    "No Dedicated Target Configuration details Found": "ഡെഡിക്കേറ്റഡ് ടാർഗറ്റ് കോൺഫിഗറേഷൻ വിശദാംശങ്ങൾ കണ്ടെത്തിയില്ല",
    "You can create a new configuration details for selected zone/city/year/month": "തിരഞ്ഞെടുത്ത മേഖല/നഗരം/വർഷം/മാസത്തിന് പുതിയ കോൺഫിഗറേഷൻ വിശദാംശങ്ങൾ സൃഷ്ടിക്കാം",
    "Create Configuration": "കോൺഫിഗറേഷൻ സൃഷ്ടിക്കുക",
    "Ride Request Count": "റൈഡ് അഭ്യർത്ഥന എണ്ണം",
    "Ride Request Count is required": "റൈഡ് അഭ്യർത്ഥന എണ്ണം ആവശ്യമാണ്",
    "Ride Accept Count": "റൈഡ് അംഗീകരണം എണ്ണം",
    "Ride Accept Count is required": "റൈഡ് അംഗീകരണം എണ്ണം ആവശ്യമാണ്",
    "Ride Reject Count": "റൈഡ് നിരസിക്കൽ എണ്ണം",
    "Ride Reject Count is required": "റൈഡ് നിരസിക്കൽ എണ്ണം ആവശ്യമാണ്",
    "Ride Complete Count": "റൈഡ് പൂർത്തിയാക്കൽ എണ്ണം",
    "Ride Complete Count is required": "റൈഡ് പൂർത്തിയാക്കൽ എണ്ണം ആവശ്യമാണ്",
    "Ride Cancelled By Driver Count": "ഡ്രൈവർ റദ്ദാക്കിയ റൈഡ് എണ്ണം",
    "Ride Cancelled By Driver Count is required": "ഡ്രൈവർ റദ്ദാക്കിയ റൈഡ് എണ്ണം ആവശ്യമാണ്",
    "Ride Cancelled By Rider Count is required": "റൈഡർ റദ്ദാക്കിയ റൈഡ് എണ്ണം ആവശ്യമാണ്",
    "Total Ride Fare (Non Meter Taxi Ride)": "മൊത്തം റൈഡ് ഫയർ (നോൺ മീറ്റർ ടാക്‌സി റൈഡ്)",
    "Total Ride Fare (Non Meter Taxi Ride) is required": "മൊത്തം റൈഡ് ഫയർ (നോൺ മീറ്റർ ടാക്‌സി റൈഡ്) ആവശ്യമാണ്",
    "Meter Taxi Initiate Count": "മീറ്റർ ടാക്‌സി ആരംഭിച്ച എണ്ണം",
    "Meter Taxi Initiate Count is required": "മീറ്റർ ടാക്‌സി ആരംഭിച്ച എണ്ണം ആവശ്യമാണ്",
    "Meter Taxi Complete Count": "മീറ്റർ ടാക്‌സി പൂർത്തിയാക്കൽ എണ്ണം",
    "Meter Taxi Complete Count is required": "മീറ്റർ ടാക്‌സി പൂർത്തിയാക്കൽ എണ്ണം ആവശ്യമാണ്",
    "Total Ride Fare (Meter Taxi Ride)": "മൊത്തം റൈഡ് ഫയർ (മീറ്റർ ടാക്‌സി റൈഡ്)",
    "Total Ride Fare (Meter Taxi Ride) is required": "മൊത്തം റൈഡ് ഫയർ (മീറ്റർ ടാക്‌സി റൈഡ്) ആവശ്യമാണ്",
    "No Dedicated KYC Configuration Found": "ഡെഡിക്കേറ്റഡ് KYC കോൺഫിഗറേഷൻ കണ്ടെത്തിയില്ല",
    "You can create a new configuration for selected zone/city": "തിരഞ്ഞെടുത്ത മേഖല/നഗരത്തിന് പുതിയ കോൺഫിഗറേഷൻ സൃഷ്ടിക്കാം",
    "Create KYC Configuration": "KYC കോൺഫിഗറേഷൻ സൃഷ്ടിക്കുക",
    "Manage KYC Configuration": "KYC കോൺഫിഗറേഷൻ മാനേജുചെയ്യുക",
    "Driver KYC Configuration": "ഡ്രൈവർ KYC കോൺഫിഗറേഷൻ",
    "Mandatory Driver Documents": "അനിവാര്യമായ ഡ്രൈവർ ഡോക്യുമെന്റുകൾ",
    "Optional Driver Documents": "ഐച്ഛിക ഡ്രൈവർ ഡോക്യുമെന്റുകൾ",
    "Vehicle KYC Configuration": "വാഹന KYC കോൺഫിഗറേഷൻ",
    "Mandatory Vehicle Documents": "അനിവാര്യമായ വാഹന ഡോക്യുമെന്റുകൾ",
    "Optional Vehicle Documents": "ഐച്ഛിക വാഹന ഡോക്യുമെന്റുകൾ",
    "No Dedicated General Settings Found": "ഡെഡിക്കേറ്റഡ് ജനറൽ സജ്ജീകരണങ്ങൾ കണ്ടെത്തിയില്ല",
    "You can create a new settings for selected zone/city": "തിരഞ്ഞെടുത്ത മേഖല/നഗരത്തിന് പുതിയ സജ്ജീകരണങ്ങൾ സൃഷ്ടിക്കാം",
    "Create General Settings": "ജനറൽ സജ്ജീകരണങ്ങൾ സൃഷ്ടിക്കുക",
    "Manage General Settings": "ജനറൽ സജ്ജീകരണങ്ങൾ മാനേജുചെയ്യുക",
    "Ride Type Enable/Disable Settings": "യാത്രയുടെ തരം സജ്ജീകരണങ്ങൾ എനേബിൾ/ഡിസേബിൾ ചെയ്യുക",
    "Enabled Booking Modes": "എനേബിൾ ചെയ്ത ബുക്കിംഗ് മോഡുകൾ",
    "Enabled Services": "എനേബിൾ ചെയ്ത സേവനങ്ങൾ",
    "Surge/Exception Management Settings": "സർജ്/എക്സെപ്ഷൻ മാനേജ്മെന്റ് സജ്ജീകരണങ്ങൾ",
    "Enable Surge": "സർജ് എനേബിൾ ചെയ്യുക",
    "Enable Exception": "എക്സെപ്ഷൻ എനേബിൾ ചെയ്യുക",
    "Scheduled Ride Settings": "ഷെഡ്യൂൾ ചെയ്ത യാത്രയുടെ സജ്ജീകരണങ്ങൾ",
    "Min Schedule Time (Minutes)": "കുറഞ്ഞ ഷെഡ്യൂൾ സമയം (മിനിറ്റ്)",
    "Max Schedule Time (Days)": "അധികം ഷെഡ്യൂൾ സമയം (ദിനങ്ങൾ)",
    "Cool-Off Time (Time Between Rides)": "കൂൾ-ഓഫ് സമയം (യാത്രകൾക്കിടയിലുള്ള സമയം)",
    "Email List For Notification (Separate by coma[,])": "നോട്ടിഫിക്കേഷനുകൾക്കായുള്ള ഇമെയിൽ ലിസ്റ്റ് (കോമ ഉപയോഗിച്ച് വേർതിരിക്കുക)",
    "Automated KYC Settings": "ഓട്ടോമേറ്റഡ് KYC സജ്ജീകരണങ്ങൾ",
    "Select automated KYC partner for verification": "പരിശോധനയ്ക്കായി ഓട്ടോമേറ്റഡ് KYC പങ്കാളിയെ തിരഞ്ഞെടുക്കുക",
    "Ride Activity Settings": "യാത്രാ പ്രവർത്തനങ്ങളുടെ സജ്ജീകരണങ്ങൾ",
    "Minimum RFR Check Time (Driver Last Updated Time)": "കുറഞ്ഞ RFR പരിശോധനാ സമയം (ഡ്രൈവർ അവസാനമായി അപ്‌ഡേറ്റ് ചെയ്തത്)",
    "Ride Request Looping Time (Looping Time For OnDemand Ride)": "റൈഡ് റിക്ക്വസ്റ്റ് ലൂപ്പിംഗ് സമയം (ഓൺഡിമാൻഡ് യാത്രയ്ക്കുള്ള ലൂപ്പിംഗ് സമയം)",
    "Showing today's logs": "ഇന്നത്തെ ലോഗുകൾ കാണിക്കുന്നു",
    "Employee Name": "ജീവനക്കാരൻ്റെ പേര്",
    "Type of Event": "ഇവന്റിന്റെ തരം",
    "Event Description": "ഇവന്റിന്റെ വിവരണം",
    "Timestamp": "ടൈംസ്റ്റാമ്പ്",
    "Base Fare": "ബേസ് ഫെയർ",
    "Search Settings": "സെർച്ച് സജ്ജീകരണങ്ങൾ",
    "Pickup Charges": "പിക്കപ്പ് ചാർജുകൾ",
    "Cancellation Charges": "റദ്ദാക്കൽ ചാർജുകൾ",
    "Waiting Charges": "കാത്തിരിക്കൽ ചാർജുകൾ",
    "Enable": "പ്രവർത്തിപ്പിക്കുക",

    "Enable On-Demand Ride": "ഓൺഡിമാന്ഡ് റൈഡ് പ്രവർത്തിപ്പിക്കുക",

    "Enable Scheduled Ride": "ഷെഡ്യൂൾ ചെയ്ത റൈഡ് പ്രവർത്തിപ്പിക്കുക",

    "Enable Fixed Fare": "നിരബധ ഫെയർ പ്രവർത്തിപ്പിക്കുക",
    "Extra Pricing": "അധിക വിലനിർണ്ണയം",
    "Pricing Type": "വിലനിർണ്ണയം തരം",
    "Range": "ശ്രേണി",
    "Price": "വില",
    "Extra Distance Pricing Type (Per Km)": "അധിക ദൂരം വിലനിർണ്ണയം തരം (പെർ കിമീ)",
    "Extra Time Pricing Type (Per Minute)": "അധിക സമയ വിലനിർണ്ണയം തരം (പെർ മിനിറ്റ്)",
    "Search Time (Per cycle)": "തെരഞ്ഞെടുത്ത സമയം (പെർ സൈക്കിൾ)",
    "Applicable Km Limit (Pickup charge applies above this imit)": "പ്രയോഗിക്കാത്ത കിമീ പരിധി (പിക്കപ്പ് ചാർജ് ഈ പരിധിയിലേക്ക് അപ്ലൈ ചെയ്യും)",
    "Charge Paid By": "ചാർജ് ചെലവിച്ചവർ",
    "Pricing Model": "വിലനിർണ്ണയ മോഡൽ",
    "Fixed Price": "നിരബധമായ വില",
    "Per Km": "പെർ കിമീ",
    "Cancellation Charge Type": "റദ്ദാക്കൽ ചാർജ് തരം",
    "Km Limit (Km)": "കിമീ പരിധി (കിമീ)",
    "Time Limit (Minutes)": "സമയ പരിധി (മിനിറ്റ്)",
    "Cancellation Fee": "റദ്ദാക്കൽ ഫീ",
    "Waiting Charge Type": "കാത്തിരിക്കൽ ചാർജ് തരം",
    "Price Per Minute": "പ്രതി മിനിറ്റ് വില",
    "Free Waiting Time Type": "സ്വതന്ത്ര കാത്തിരിക്കൽ സമയ തരം",
    "Min Ride Fare (For Scheduling)": "കുറഞ്ഞ റൈഡ് ഫെയർ (ഷെഡ്യൂലിംഗ് കുറഞ്ഞത്)",
    "Enable Min Fare": "കുറഞ്ഞ ഫെയർ പ്രവർത്തിപ്പിക്കുക",
    "Reservation Charge Type": "റെസർവേഷൻ ചാർജ് തരം",
    "Charge(₹)": "നിരക്ക്(₹)",
    "Auto Cancellation Time (Before the Scheduled Time)": "ഓട്ടോ റദ്ദാക്കൽ സമയം (ഷെഡ്യൂൾ സമയത്തിന് മുൻപ്)",
    "Manual Assignment Time (Before the Auto Cancellation Time)": "മാന്യുവൽ അസൈൻമെന്റ് സമയം (ഓട്ടോ റദ്ദാക്കൽ സമയത്തിന് മുൻപ്)",
    "Auto Assignment Time (Before the Manual Assignment Time)": "ഓട്ടോ അസൈൻമെന്റ് സമയം (മാന്യുവൽ അസൈൻമെന്റ് സമയത്തിന് മുൻപ്)",
    "Min Ride Fare (Avail Cancellation Reward)": "കുറഞ്ഞ റൈഡ് ഫെയർ (റദ്ദാക്കൽ പ്രതിഫലം ലഭിക്കുന്നതിന്)",
    "Cancellation Reward Type": "റദ്ദാക്കൽ പ്രതിഫല തരം",
    "Cancellation Reward value ( ₹)": "റദ്ദാക്കൽ പ്രതിഫല നിരക്ക് (₹)",
    "Enable Time Specific": "സമയ നിരദേശം പ്രവർത്തിപ്പിക്കുക",
    "Enable Recurring": "പുനർനിയമനം പ്രവർത്തിപ്പിക്കുക",
    "To Search Distance": "അകലം തിരയുന്നതിന്",
    "From Search Distance": "അകലം തിരയുന്നതിന്",
    "Pricing Mode": "വിലനിർണ്ണയ മോഡ്",
    "Grace period": "ഗ്രേസ് പീരിയഡ്",
    "Grace Period Type": "ഗ്രേസ് പീരിയഡ് തരം",
    "Driver Bata": "ഡ്രൈവർ ബാട",
    "Enable Min Time": "കുറഞ്ഞ സമയം പ്രവർത്തിപ്പിക്കുക",
    "Min Time To Include Driver Bata": "ഡ്രൈവർ ബാട ചേർക്കാനുള്ള കുറഞ്ഞ സമയം",
    "Driver Batta (Per Day)": "ഡ്രൈവർ ബാട (ദിവസത്തെ ഒരു)",
    "Note: Please provide the maximum number of passengers allowed": "അടിസ്ഥാനമായി അനുവദിക്കപ്പെട്ട യാത്രക്കാരുകളുടെ പരമാവധി എണ്ണം നൽകുക",
    "Maximum allowed seats": "അനുവദിക്കപ്പെട്ട പരമാവധി സീറ്റുകൾ",
    "Amount Payable Defaulter Days": "നിരക്ക് ചെലവിക്കണം ദോഷി ദിവസങ്ങൾ",
    "Commission Value": "കമ്മീഷൻ നിരക്ക്",
    "Manage Tax & Invoice Settings": "നിക്ഷേപം മറ്റുള്ളവരുടെ നിക്ഷേപം സജ്ജീകരണങ്ങൾ മാനേജുചെയ്യുക",
    "Tax Number": "നിക്ഷേപം നമ്പർ",
    "Tax Number Label": "നിക്ഷേപം നമ്പർ ലേബല്",
    "Tax Applicable": "നിക്ഷേപം പ്രയോഗിക്കാത്തത്",
    "Invoice Name": "ഇൻവോയ്സ് പേര്",
    "Address Line 1": "വിലാസ ലൈൻ 1",
    "Address Line 2": "വിലാസ ലൈൻ 2",
    "Biller Address Details": "ബില്ലർ വിലാസ വിവരങ്ങൾ",
    "Applicable Transaction Types": "പ്രയോഗിക്കാത്ത ലളിത വിവരങ്ങൾ",
    "Valuation": "മൂല്യനിർധാരണ",
    "Min Withdrawal Limit": "കുറഞ്ഞ പിഴ പരിധി",
    "Max Withdrawal Limit (Per transaction)": "പരിശോധന (പ്രത്യേകം പ്രതിവിനിയോഗം) പരിധി",
    "Withdrawal Request Limit (No. of withdrawal per day per user)": "പിഴ അഭ്യർഥന പരിധി (ഉപയോക്താവിന് ദിവസത്തെ പ്രത്യേകം പിഴ അഭ്യർഥനകൾ)",
    "Usage Type": "ഉപയോഗം തരം",
    "Usage Limit (%) (Per transaction)": "ഉപയോഗ പരിധി (%) (പ്രത്യേകം പ്രതിവിനിയോഗം)",
    "Max Value Upto": "പരിധിയുള്ള പരിമിതി",
    "Enable Actual Driver Count (To Show Actual Driver Count in Price Calculate)": "വാസ്തവ ഡ്രൈവർ എണ്ണം പ്രവർത്തിപ്പിക്കുക (വില ഗണന ചെയ്യുന്നതിലെ വാസ്തവ ഡ്രൈവർ എണ്ണം കാണിക്കാന്)",
    "Enable Activity Notification (To Reactivate Drivers)": "പ്രവൃത്തി അറിയിപ്പ് പ്രവർത്തിപ്പിക്കുക (ഡ്രൈവർകൾ പുനരാക്ടിവേറ്റ് ചെയ്യാൻ)",
    "Enable Ride Notification (To Nearby Offline Drivers)": "റൈഡ് അറിയിപ്പ് പ്രവർത്തിപ്പിക്കുക (അടിമ ഡ്രൈവർകൾക്ക്)",
    "Enable Auto Incremental Search (Nearest Driver will get notified first)": "ഓട്ടോ ഇൻക്രിമെന്റൽ തിരയൽ പ്രവർത്തിപ്പിക്കുക (അടിമ ഡ്രൈവർ ആദ്യം അറിയിക്കപ്പെടും)",
    "Platform Fee Type": "പ്ലാറ്റ്ഫോം ഫീ തരം",
    "Platform Fee Value": "പ്ലാറ്റ്ഫോം ഫീ നിരക്ക്",
    "Manage Platform Fee Settings": "പ്ലാറ്റ്ഫോം ഫീ സജ്ജീകരണങ്ങൾ മാനേജുചെയ്യുക",
  }
};
