import { UploadFile } from "../../upload/_models/uploadFile.model";

export class Amenity {
    _id: string;
    name: string;
    type: string;
    description: string;
    category: string;
    amount: number;
    amenityimageDetails: UploadFile;
    amenityimageId: string;
    sortOrder: number;
    isDelete: boolean;
    readOnly: boolean;
    clear(): void {
        this._id = undefined;
        this.name = '';
        this.type = '';
        this.description = '';
        this.category = '';
        this.amount = 0;
        this.amenityimageId = undefined;
        this.amenityimageDetails = new UploadFile();
        this.amenityimageDetails.clear();
        this.isDelete = false;
        this.readOnly = false;
    }
}
