<form class="form-horizontal" [formGroup]="loginForm">

  <app-alert></app-alert>

  <div class="form-group mb-4">
    <label for="emailInput">Email</label>
    <input id="emailInput" type="email" autocomplete="username" formControlName="email" class="form-control" placeholder="Enter Your Email" [ngClass]="{ 'is-invalid': isControlHasError('email') }" />
    <div *ngIf="isControlHasError('email')" class="invalid-feedback">
      <div *ngIf="isControlHasError('email','required')">
        Email is required
      </div>
      <div *ngIf="isControlHasError('email','pattern')">
        Email must be a valid email address
      </div>
    </div>
  </div>
  <div class="form-group mb-4">
    <label for="passwordInput">
      Password
      <a href="javascript:void(0)" class="ml-1" (click)="togglePassword(0)"><i class="fa fa-{{passwordType[0] === 'text' ? 'eye-slash' : 'eye'}}"></i></a>
    </label>
    <input id="passwordInput" type="{{passwordType[0]}}" autocomplete="current-password" formControlName="password" class="form-control" [ngClass]="{ 'is-invalid': isControlHasError('password') }" placeholder="Enter Your Password" />
    <div *ngIf="isControlHasError('password')" class="invalid-feedback">
      <div *ngIf="isControlHasError('password','required')">
        Password is required
      </div>
    </div>
  </div>
  <div class="custom-control custom-checkbox">
    <input id="remindMeCheckbox" type="checkbox" class="custom-control-input" formControlName="remindMe"/>
    <label class="custom-control-label" for="remindMeCheckbox">Keep me signed in</label>
  </div>
  <div class="mt-3">
    <button  id="signInButton" class="btn btn-primary btn-block" (click)="onSubmit()" [disabled]="loading">
      Sign In
      <i *ngIf="loading" class="bx bx-loader bx-spin"></i>
    </button>
  </div>
  <div class="mt-4 text-center">
    <a id="forgotPasswordLink" routerLink="/auth/forgot-password" class="text-muted">
      <i class="mdi mdi-lock mr-1"></i> Forgot your password?
    </a>
  </div>
</form>
