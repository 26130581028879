export class TollCategories {
    public static tollCategories: any = [
        {
            category: "2W",
            amount:0
        },
        {
            category: "3W",
            amount:0
        },
        {
            category: "LMV",
            amount:0
        },
        {
            category: "MINI BUS",
            amount:0
        },
        
    ]
}