import { Zone } from '../../master-data/_models/zone.model';
import { City } from '../../master-data/_models/city.model';
import { DocType } from '../../kyc/_models/docType.model';

export class KycConfiguration {
  _id: string;
  mandatoryDocumentDriver: DocType[];
  mandatoryDocumentVehicle: DocType[];
  optionalDocumentDriver: DocType[];
  optionalDocumentVehicle: DocType[];
  isDefault: boolean;
  zoneId: Zone;
  cityId: City;
  createdById: string;
  __v: number;

    clear(): void {
      this._id = undefined;
      this.mandatoryDocumentDriver = [];
      this.mandatoryDocumentVehicle = [];
      this.optionalDocumentDriver = [];
      this.optionalDocumentVehicle = [];
      this.isDefault = false;
      this.zoneId = new Zone();
      this.zoneId.clear();
      this.cityId = new City();
      this.cityId.clear();
      this.createdById = '';
      this.__v = 0;
    }
}

export class RideTypeStatus {
  type: string;
  status: boolean;
  enabledService: string[]; // "hourly", "package", "oneway", "roundTrip"
  enabledBooking: string[]; // "onDemand", "schedule"
  reason: string;

  clear(): void {
    this.type = '';
    this.status = false;
    this.enabledService = [];
    this.enabledBooking = ['onDemand', 'schedule'];
    this.reason = 'Unavailable';
  }
}
