import { Injectable } from '@angular/core';
import { Observable, of} from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { debounceTime, delay, switchMap, tap, mergeMap, catchError, map } from "rxjs/operators";
import { environment } from '../../../../environments/environment';
import { apiUrl } from '../../_config/api.config';

import {
  LayoutUtilsService,
  MessageType
} from "src/app/core/_base/crud";

const BASE_URL = environment.apiUrl;

@Injectable({
  providedIn: "root"
})

export class GoogleMapService {

  constructor(private http: HttpClient,
    private layoutUtilService: LayoutUtilsService
  ) {}

  // Get autocomplete
  getAutoComplete(queryParams): Observable<any> {
    return this.http.get<any>(BASE_URL + apiUrl.googleAutocomplete +
      '?input=' + queryParams.input +
      '&sessiontoken=' + queryParams.sessiontoken +
      '&originLat=' + queryParams.originLat +
      '&originLong=' + queryParams.originLong +
      '&components=' + (queryParams.components ?? []).join('|').toString() +
      '&types=' + (queryParams.types ?? []).join('|').toString() +
      '&language=' + (queryParams.language ?? 'en') +
      '&radius=' + (queryParams.radius ?? '50000')).pipe(
        mergeMap(res => {
          console.log(res);
          if (res.response.code == 200) {
            const result = {
              status: 'success',
              errorMessage: '',
              predictions: res.data?.predictions ?? []
            };
            return of(result);
          } else {
            const result = {
              status: 'error',
              errorMessage: '',
              predictions: []
            };
            return of(result);
          }
        })
      );
  }

  // Get places details by id
  getDetailsByPlaceId(placeId, sessiontoken): Observable<any> {
    return this.http.get(BASE_URL + apiUrl.googlePlacesId + '?place_id=' + placeId + '&sessiontoken=' + sessiontoken);
  }

}
