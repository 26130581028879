// language list
import { locale as enLang } from './i18n/en';
import { locale as mlLang } from './i18n/ml';
import { locale as hiLang } from './i18n/hi';
import { locale as knLang } from './i18n/kn';

export interface LanguageFlag {
  lang: string;
  name: string;
  flag: string;
  translate: string;
  active?: boolean;
}

export class Locales {
  public static locales: any = [enLang, mlLang, hiLang, knLang]
}

export class Languages {
  public static languages: LanguageFlag[] = [
    {
      lang: 'en',
      name: 'English',
      translate: 'TRANSLATOR.ENGLISH',
      flag: 'assets/images/country-picker/in.png'
    },
    {
      lang: 'ml',
      name: 'Malayalam',
      translate: 'TRANSLATOR.MALAYALAM',
      flag: 'assets/images/country-picker/gm.png'
    },
    {
      lang: 'hi',
      name: 'Hindi',
      translate: 'TRANSLATOR.HINDI',
      flag: 'assets/images/country-picker/ru.png'
    },
    {
      lang: 'kn',
      name: 'Kannada',
      translate: 'TRANSLATOR.KANNADA',
      flag: 'assets/images/country-picker/it.png'
    },
  ]
}
