import { TollCategories } from '../_server/tollCategory.table';
import { City } from './city.model';
import { Zone } from './zone.model';

export class Toll {
  _id: string;
  name: string;
  // tollAmount: number;
  zone: Zone;
  tollBoundary: {
    coordinates: any[],
    type: string
  }
  createdById: string;
  isDelete: boolean;
  readOnly: boolean;
  city: City;
  tollAmount: any;
  clear(): void {
    this._id = undefined;
    this.name = '';
    this.tollAmount= TollCategories.tollCategories;
    this.zone = new Zone();
    this.zone.clear();
    this.city = new City();
    this.city.clear();
    this.tollBoundary = {
      coordinates: [],
      type: 'Polygon'
    }
    this.createdById = undefined;
    this.isDelete = false;
    this.readOnly = false;
  }
}
