<div class="modal-header border-bottom-0">
  <button
    id="closeDialogButton"
    type="button"
    class="close"
    aria-hidden="true"
    (click)="closeDialog()"
  >
    ×
  </button>
</div>
<div class="modal-body pt-0">
  <h5 class="modal-title mt-0 mb-0 font-size-15 text-dark text-center">
    Add Remark
  </h5>
  <div class="row">
    <div class="col-12 pt-0 mt-0 pb-2">
      <form class="form-horizontal" [formGroup]="remarkForm">
        <div class="col-md-12">
          <app-alert></app-alert>
        </div>
        <div class="form-group col-md-12 mt-1 mb-3">
          <label>Title</label>
          <input
            id="titleInput"
            type="text"
            formControlName="title"
            class="form-control font-size-13"
            pattern="(?![0-9,\-& ]*$)[a-zA-Z0-9,\-& ]+$"
            style="text-transform: capitalize;"
            [ngClass]="{ 'is-invalid': isControlHasError('title') }"
            placeholder="Enter Remark Title"
          />
          <div
            *ngIf="isControlHasError('title', 'required')"
            class="invalid-feedbacks"
          >
            Title is required
          </div>
          <div
            *ngIf="isControlHasError('title', 'pattern')"
            class="invalid-feedbacks"
          >
            Invalid title
          </div>
        </div>

        <div class="form-group col-md-12 mt-1 mb-3" style="position: relative;">
          <div>
            <label>Comments</label>
            <ckeditor id="commentsInput" [editor]="Editor" [config]="config" formControlName="comments" data="" (ready)="onReady($event)"></ckeditor>
          </div>
          <div
            *ngIf="isControlHasError('comments', 'required')"
            class="invalid-feedbacks"
          >
            Comment is required
          </div>
        </div>

        <div class="form-group col-md-12 mt-1 mb-3">
          <label>Attachments</label>
          <div class="drop-area">
            <div class="preview-area" *ngIf="attachments.length > 0">
              <div
                class="col-lg-3 image-box"
                *ngFor="let document of attachments; let i = index"
              >
                <img *ngIf="document.contentType.includes('image')" src="{{ document.imageUrl }}" />
                <img *ngIf="document.contentType.includes('application')" src="{{ 'assets/images/pdf-placeholder.png' }}" />
                <img *ngIf="document.contentType.includes('audio')" src="{{ 'assets/images/audio-placeholder.png' }}" />
                <div
                  *ngIf="!document.isUploading"
                  (click)="removeDocument(document)"
                  class="remove-btn"
                  ngbTooltip="Remove Document"
                >
                  <i class="fas fa-trash-alt text-danger font-size-13"></i>
                </div>
                <div
                  *ngIf="!document.isUploading"
                  class="update-btn"
                  ngbTooltip="Change Document"
                >
                  <input
                    id="fileUploadArea1"
                    type="file"
                    title=""
                    accept="image/*,application/*,audio/*"
                    (change)="handleUploadEventDoc($event, document)"
                    (click)="removeSelected($event)"
                  />
                  <span><i class="fas fa-pencil-alt text-success"></i></span>
                </div>
                <div class="loader" *ngIf="document.isUploading">
                  <i class="bx bx-loader bx-spin"></i>
                </div>
              </div>
            </div>
            <div class="upload-area">
              <div class="upload-text">
                <span *ngIf="!uploadLoading"
                  >Drag and drop or click to upload</span
                >
                <i *ngIf="uploadLoading" class="bx bx-loader bx-spin"></i>
              </div>
              <input
                type="file"
                title=""
                id="myFileInput"
                accept="image/*,application/*,audio/*"
                (change)="handleUploadEventDoc($event)"
                (click)="removeSelected($event)"
              />
            </div>
          </div>
        </div>

        <div class="mt-4 col-md-12 text-right">
          <button
            id="closeButton"
            type="button"
            class="btn btn-secondary w-sm"
            (click)="closeDialog()"
          >
            Close
          </button>
          <button
            id="saveButton"
            type="button"
            class="btn btn-primary w-sm ml-3"
            (click)="onSubmit()"
            [disabled]="
              loading ||
              ((remarkForm.invalid || remarkForm.pristine) &&
              !isUpdated)
            "
          >
            Save
            <i *ngIf="loading" class="bx bx-loader bx-spin"></i>
          </button>
        </div>
      </form>
    </div>
  </div>
</div>
