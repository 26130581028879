// Angular
import { Injectable } from '@angular/core';
// RxJS
import { mergeMap, map, tap } from 'rxjs/operators';
import { defer, Observable, of } from 'rxjs';
// NGRX
import { createEffect, Actions, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
// Services
import { AuthService } from '../_services/auth.service';
// Actions
import {
    AllModulesLoaded,
    AllModulesRequested,
    ModuleActionTypes
} from '../_actions/module.actions';
// Models
import { Module } from '../_models/module.model';

@Injectable()
export class ModuleEffects {

    loadAllModules$ = createEffect(() => this.actions$
        .pipe(
            ofType<AllModulesRequested>(ModuleActionTypes.AllModulesRequested),
            mergeMap(() => this.auth.getAllModules()),
            map((result: Module[]) => {
                return  new AllModulesLoaded({
                    modules: result
                });
            })
          ));

    init$: Observable<Action> = createEffect(() => defer(() => {
        return of(new AllModulesRequested());
    }));

    constructor(private actions$: Actions, private auth: AuthService) { }
}
