<div class="dropdown d-inline-block" ngbDropdown>
  <button type="button" class="btn header-item" id="page-header-user-dropdown" ngbDropdownToggle>
    <img class="" src="{{language?.flag}}" alt="Header Language" height="16">
  </button>
  <div class="dropdown-menu dropdown-menu-right" ngbDropdownMenu>

    <ng-container *ngFor="let language of languages">
      <!-- item-->
      <a href="javascript:void(0);" class="dropdown-item notify-item" (click)="setLanguage(language.lang)">
        <img  src="{{language.flag}}" alt="user-image" class="mr-2" height="12">
        <span class="align-middle" [translate]="language.translate"></span>
      </a>
    </ng-container>
  </div>
</div>
