import { Component } from '@angular/core';
import { NavigationStart, Router } from '@angular/router';

// Translate
import { TranslationService } from '../../../../core/_base/layout';
import { filter } from 'rxjs';
import { Languages, LanguageFlag } from 'src/app/core/_config/language.config';

@Component({
  selector: 'app-languages',
  templateUrl: './languages.component.html',
})

export class LanguagesComponent {

  language: LanguageFlag;
  languages: LanguageFlag[] = Languages.languages;

  /**
   * Component constructor
   *
   * @param translationService: TranslationService
   * @param router: Router
   */
  constructor(private translationService: TranslationService, private router: Router) {
  }

  /**
   * @ Lifecycle sequences => https://angular.io/guide/lifecycle-hooks
   */

  /**
   * On init
   */
  ngOnInit() {
    this.setSelectedLanguage();
    this.router.events
      .pipe(filter(event => event instanceof NavigationStart))
      .subscribe(event => {
        this.setSelectedLanguage();
      });
  }

  /**
   * Set language
   *
   * @param lang: any
   */
  setLanguage(lang) {
    this.languages.forEach((language: LanguageFlag) => {
      if (language.lang === lang) {
        language.active = true;
        this.language = language;
      } else {
        language.active = false;
      }
    });
    this.translationService.setLanguage(lang);
  }

  /**
   * Set selected language
   */
  setSelectedLanguage(): any {
    this.setLanguage(this.translationService.getSelectedLanguage());
  }
}
