export class amenityCategories {
    public static amenityCategories: any = [
        {
            value: "Amenities",
            label: "Amenities",
            isChecked: false
          },
            {
              value: "Vehicle Features",
              label: "Vehicle Features",
              isChecked:false
        },
        {
            value: "Extra Luggage",
            label: "Extra Luggage",
            isChecked:false
        },
        {
            value: "Pet Friendly",
            label: "Pet Friendly",
            isChecked:false
        },
        {
            value: "fuel Type",
            label: "Fuel Type",
            isChecked:false
        },
        {
            value: "Safety",
            label: "Safety",
            isChecked:false
        }
        
    ]
}