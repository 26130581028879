import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'customFilter' })
export class CustomFilterPipe implements PipeTransform {
  transform(items: any[], searchText: string): any[] {
    if (!items) return [];
    if (!searchText) return items;

    searchText = searchText.toLowerCase();
    return items.filter(item => this.searchInObject(item, searchText));
  }

  private searchInObject(obj: any, searchText: string): boolean {
    if (typeof obj === 'string' || typeof obj === 'number') {
      return obj.toString()?.toLowerCase()?.includes(searchText);
    }

    if (Array.isArray(obj)) {
      return obj.some(element => this.searchInObject(element, searchText));
    }

    if (typeof obj === 'object' && obj !== null) {
      return Object.values(obj).some(value => this.searchInObject(value, searchText));
    }

    return false;
  }
}
