import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { inject } from '@angular/core/testing';
import { environment } from 'src/environments/environment';
import { apiUrl } from '../../_config/api.config';
import { BehaviorSubject, Subject, Observable, of } from 'rxjs';
import { QueryParamsModel, QueryResultsModel} from '../../_base/crud';
import { catchError, map, mergeMap } from 'rxjs/operators';

// Models
import { DocType } from '../_models/docType.model';
import { DocTypes } from '../_server/docTypes.table';

const BASE_URL = environment.apiUrl;

@Injectable({providedIn: 'root'})
export class KycService {


  constructor(private http: HttpClient){}

  // DocType List
  getAllDocTypes(): Observable<DocType[]> {
    const data = DocTypes.docTypes;
    return of(data);
  }

  // get all riders
  getAllKycList(queryParams: QueryParamsModel):Observable<any> {
    return this.http.get<any>(BASE_URL+ apiUrl.getAllKyc+
    '?pageSize='+queryParams.pageSize +
    '&pageNumber='+queryParams.pageNumber+
    '&sortField=' + queryParams.sortField +
    '&sortOrder=' + queryParams.sortOrder +
    '&searchText='+queryParams.searchText
    +queryParams.filter).pipe(
      mergeMap(res => {
          console.log("Kyc data", res);
          if(res.response.code === 200) {
              const result = {
                items: res.data.list,
                totalCount: res.data.totalCount
              }
              return of(result);
          } else {
            const result = {
              items: [],
              totalCount: 0
            }
            return of(result);
          }
      })
    );
  }

  // get all riders
  searchKycDosument(queryParams: QueryParamsModel): Observable<any> {
    return this.http.get<any>(BASE_URL + apiUrl.searchKycDocument +
      '?pageSize=' + queryParams.pageSize +
      '&pageNumber=' + queryParams.pageNumber +
      '&sortField=' + queryParams.sortField +
      '&sortOrder=' + queryParams.sortOrder +
      '&documentNumber=' + queryParams.searchText
      + queryParams.filter).pipe(
        mergeMap(res => {
          if (res.response.code === 200) {
            const result = {
              items: res.data,
              totalCount: res.data.length
            }
            return of(result);
          } else {
            const result = {
              items: [],
              totalCount: 0
            }
            return of(result);
          }
        })
      );
  }

  // Export all riders
  exportAllKycList(queryParams: QueryParamsModel): Observable<any> {
    return this.http.get<any>(BASE_URL + apiUrl.exportAllKyc +
    '?pageSize=' + queryParams.pageSize +
    '&pageNumber=' + queryParams.pageNumber +
    '&sortField=' + queryParams.sortField +
    '&sortOrder=' + queryParams.sortOrder +
    '&searchText=' + queryParams.searchText + queryParams.filter).pipe(
      mergeMap(res => {
        console.log("Kyc data", res);
        return of(res);
      })
    );
  }

  // Get single kyc
  getSingleKyc(_id) {
    return this.http.get(BASE_URL + apiUrl.getKycDriver +'?driverId='+_id);
  }

  // Get soft copy details
  getsoftcopyKyc(id: string): Observable<any> {
    return this.http.get(BASE_URL+ apiUrl.getSoftCopyKyc +'?driverId='+id);
  }

  // Update soft copy
  addSoftCopyStatus(data) {
    return this.http.post(BASE_URL + apiUrl.softCopyKyc , data);
  }

  // Update soft copy
  updateSoftCopyStatus(data){
    return this.http.put(BASE_URL+ apiUrl.softCopyKyc ,data);
  }

  // Check soft copy digital record
  checkDigitalDocument(params) {
    return this.http.get(BASE_URL + apiUrl.digitalKyc +'?' + params);
  }

  // Confirm soft copy digital record
  confirmDigitalDocument(data) {
    return this.http.post(BASE_URL + apiUrl.digitalKyc, data);
  }

  // Mark kyc document as invalid
  markDocumentInvalid(data) {
    return this.http.put(BASE_URL + apiUrl.markKycInvalid, data);
  }

  // Notify kyc status
  notifyKycStatus(data) {
    return this.http.post(BASE_URL + apiUrl.notifyKycStatus, data);
  }

  // Link razorpay account
  linkRazorpayAccount(data) {
    return this.http.post(BASE_URL + apiUrl.linkRazorPay, data);
  }

  /* Unused API in the latest release */

  // Update driver vehicle type
  updateVehicleType(vehicle) {
    return this.http.put(BASE_URL + apiUrl.driverVehicleType, vehicle);
  }

  // Get hard copy details
  gethardcopyKyc(_id: string) {
    return this.http.get(BASE_URL + apiUrl.hardCopyKyc +'?driverId=' + _id);
  }

  // Update hard copy step 1
  updateHardCopyStatus1(data){
    return this.http.put(BASE_URL + apiUrl.hardCopyKyc,data);
  }

  // Update hard copy step 2
  updateHardCopyStatus2(data){
    return this.http.put(BASE_URL + apiUrl.hardCopyKycStatus,data);
  }

  // Get all kyc document logs
  getAllKycDocLogs(queryParams: QueryParamsModel): Observable<any> {
    return this.http
      .get<any>(
        BASE_URL +
        apiUrl.getKycLog +
        '?pageSize=' +
        queryParams.pageSize +
        '&pageNumber=' +
        queryParams.pageNumber +
        '&sortField=' +
        queryParams.sortField +
        '&sortOrder=' +
        queryParams.sortOrder +
        '&searchText=' +
        queryParams.searchText +
        queryParams.filter
      )
      .pipe(
        mergeMap(res => {
          console.log(res);
          if (res.response.code === 200) {
            const result = {
              items: res.data.list,
              totalCount: res.data.totalCount
            };
            return of(result);
          } else {
            const result = {
              items: [],
              totalCount: 0
            };
            return of(result);
          }
        })
      );
  }

  // Get driver bank accounts
  getDriverBankAccounts(queryParams: QueryParamsModel): Observable<any> {
    return this.http.get(BASE_URL + apiUrl.getDriverAccounts +
      '?pageSize=' + queryParams.pageSize +
      '&pageNumber=' + queryParams.pageNumber +
      '&sortField=' + queryParams.sortField +
      '&sortOrder=' + queryParams.sortOrder + queryParams.filter);
  }

}
