<div class="modal-header border-bottom-0">
  <button id="closeDialogButton" type="button" class="close" aria-hidden="true" (click)="onClose()">
    ×
  </button>
</div>
<div class="modal-body">
  <h5 class="modal-title pt-0 mt-0 mb-4 font-size-16 text-dark text-center">Photo/File Editor</h5>
  <div class="row">
    <div class="col-12 pt-0 mt-0 pb-2">
      <!-- <div class="col-md-12">
        <app-alert></app-alert>
      </div> -->
      <div class="col-md-12">
        <div class="row">
          <div class="col-lg-12">
            <button id="ChangeFileButton" class="btn btn-primary btn-sm mr-2 my-1" style="position: relative;">
              Change
              <input type="file" accept="image/*" (change)="fileChangeEvent($event)"/>
            </button>
            <ng-container *ngIf="contentType !== 'application/pdf'">
              <button id="rotateLeftButton" (click)="rotateLeft()" class="btn btn-secondary btn-sm mr-2 my-1">
                <i class="fa fa-undo"></i>
              </button>
              <button id="rotateRightButton" (click)="rotateRight()" class="btn btn-secondary btn-sm mr-2 my-1">
                <i class="fa fa-redo" aria-hidden="true"></i>
              </button>
              <button id="flipHorizontalButton" (click)="flipHorizontal()" class="btn btn-secondary btn-sm mr-2 my-1">
                <i class="fa fa-arrows-alt-h"></i>
              </button>
              <button id="flipVerticalButton" (click)="flipVertical()" class="btn btn-secondary btn-sm mr-2 my-1">
                <i class="fa fa-arrows-alt-v"></i>
              </button>
              <button id="zoomInButton" (click)="zoomIn()" class="btn btn-secondary btn-sm mr-2 my-1">
                <i class="fa fa-search-plus"></i>
              </button>
              <button id="zoomOutButton" (click)="zoomOut()" class="btn btn-secondary btn-sm mr-2 my-1">
                <i class="fa fa-search-minus"></i>
              </button>
              <button id="maintainAspectRatioButton" *ngIf="!data.maintainRatio" (click)="maintainAspectRatio()" class="btn btn-secondary btn-sm mr-2 my-1">
                Ratio
              </button>
              <button id="resetImageButton" (click)="resetImage()" class="btn btn-secondary btn-sm mr-2 my-1">
                Reset
              </button>
            </ng-container>
          </div>
        </div>
        <div class="row pt-3" *ngIf="!imageChange">
          <div class="col-lg-12">
            <div class="upload-box">
              <div class="overlay">
                <div class="content">
                  <i class="fa fa-upload"></i>
                  <p class="pt-1">Click or drag and drop to upload a file. Maximum upload size allowed is 10MB</p>
                </div>
              </div>
              <input id="fileDragArea" type="file" accept="image/*" (change)="fileChangeEvent($event)"/>
            </div>
          </div>
        </div>
        <div class="row pt-3" *ngIf="imageChange">
          <div class="col-lg-6" *ngIf="contentType !== 'application/pdf'">
            <h6>Editor</h6>
            <div class="crop-box">
              <!-- [imageURL]="imageURL" -->
              <image-cropper
                [imageChangedEvent]="imageChangedEvent"
                [imageBase64]="imageBase64"
                [maintainAspectRatio]="maintainRatio"
                [containWithinAspectRatio]="containWithinAspectRatio"
                [aspectRatio]="aspectRatio"
                [canvasRotation]="canvasRotation"
                [transform]="transform"
                format="jpeg"
                output="base64"
                (imageCropped)="imageCropped($event)"
                (imageLoaded)="imageLoaded()"
                (cropperReady)="cropperReady()"
                (loadImageFailed)="loadImageFailed()"
                style="height: 100%;"
                ></image-cropper>
            </div>
          </div>
          <div class="col-lg-6" *ngIf="contentType !== 'application/pdf'">
            <h6>Preview</h6>
            <div class="preview-box">
              <img style="height: 100%;width: 100%;" [src]="croppedImage" />
            </div>
          </div>
          <div class="col-lg-12" *ngIf="contentType === 'application/pdf'">
            <iframe [src]="imageURL" width="100%" height="500px"></iframe>
          </div>
        </div>
      </div>
      <div class="mt-3 col-md-12 text-right">
        <button id="cancelButton" type="button" class="btn btn-secondary w-sm mr-3" (click)="onClose()">
          Cancel
        </button>
        <button id="uploadButton" type="button" class="btn btn-primary w-sm" [disabled]="loading" (click)="onSubmit()">
          Upload
          <i *ngIf="loading" class="bx bx-loader bx-spin"></i>
        </button>
      </div>
    </div>
  </div>
</div>
