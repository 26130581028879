import { Injectable, PipeTransform } from "@angular/core";

import { BehaviorSubject, Observable, of, Subject } from "rxjs";
import { debounceTime, delay, switchMap, tap, mergeMap, catchError, map } from "rxjs/operators";

import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { apiUrl } from '../../_config/api.config';
import { QueryParamsModel } from '../../_base/crud';
import { amenityCategories } from "../_server/amenitiesCategories.table";

const BASE_URL = environment.apiUrl;

@Injectable({
  providedIn: "root",
})

export class AmenitiesService {

  constructor(private http: HttpClient) { }
   // Aminity Type List
   getAllamenityCategory(): Observable<amenityCategories[]> {
    const data = amenityCategories.amenityCategories;
    return of(data);
  }
  // Get all taxess with filter
  getAllAmenities(queryParams: QueryParamsModel): Observable<any> {
    return this.http.get<any>(BASE_URL + apiUrl.amenity +
    '?pageSize=' + queryParams.pageSize +
    '&pageNumber=' + queryParams.pageNumber +
    '&sortOrder=' + queryParams.sortOrder +
    '&sortField=' + queryParams.sortField +
    '&searchText=' + queryParams.searchText + queryParams.filter).pipe(
      mergeMap(res => {
        console.log(res);
        if (res.response.code === 200) {
          const result = {
            items: res.data.list,
            totalCount: res.data.totalCount
          };
          return of(result);
        } else {
          const result = {
            items: [],
            totalCount: 0
          };
          return of(result);
        }
      })
    );
  }

  // Without filter
  getAmenity() {
    return this.http.get<any>(BASE_URL + apiUrl.amenity);
  }

  // Add new amenity
  createAmenity(amenity: any): Observable<any> {
    return this.http.post(BASE_URL + apiUrl.createAminity, amenity);
  }

  // Update amenity
  updateAmenity(amenity: any): Observable<any> {
    return this.http.put(BASE_URL + apiUrl.updateAminity, amenity);
  }

  // Delete amenity
  deleteAmenity(amenity: any): Observable<any> {
    return this.http.delete(BASE_URL + apiUrl.deleteAminity +'?_id=' + amenity._id);
  }

}
