// Angular
import { Injectable } from '@angular/core';
// RxJS
import { mergeMap, map, tap, delay, debounceTime, distinctUntilChanged, switchMap } from 'rxjs/operators';
import { Observable, defer, of, forkJoin } from 'rxjs';
// NGRX
import { createEffect, Actions, ofType } from '@ngrx/effects';
import { Store, select, Action } from '@ngrx/store';
// CRUD
import { QueryResultsModel, QueryParamsModel } from '../../_base/crud';
// Services
import { PromotionService } from '../_services/promotion.service';
// State
import { AppState } from '../../reducers';
// Action
import {
    PromotionSettlementActionTypes,
    PromotionSettlementPageRequested,
    PromotionSettlementPageLoaded,
    PromotionSettlementActionToggleLoading,
    PromotionSettlementPageToggleLoading
} from '../_actions/promotionSettlement.actions';

@Injectable()
export class PromotionSettlementEffects {
    showPageLoadingDistpatcher = new PromotionSettlementPageToggleLoading({ isLoading: true });
    hidePageLoadingDistpatcher = new PromotionSettlementPageToggleLoading({ isLoading: false });

    showActionLoadingDistpatcher = new PromotionSettlementActionToggleLoading({ isLoading: true });
    hideActionLoadingDistpatcher = new PromotionSettlementActionToggleLoading({ isLoading: false });

    loadDriversPage$ = createEffect(() => this.actions$
        .pipe(
          ofType<PromotionSettlementPageRequested>(PromotionSettlementActionTypes.PromotionSettlementPageRequested),
            debounceTime(50),
            distinctUntilChanged(),
            switchMap(( { payload } ) => {
                this.store.dispatch(this.showPageLoadingDistpatcher);
              const requestToServer = this.service.getAllPromotionSettlements(payload.page);
                const lastQuery = of(payload.page);
                return forkJoin(requestToServer, lastQuery);
            }),
            map(response => {
                console.log(response);
                const result: QueryResultsModel = response[0];
                const lastQuery: QueryParamsModel = response[1];
                return new PromotionSettlementPageLoaded({
                    promotionSettlements: result.items,
                    totalCount: result.totalCount,
                    page: lastQuery
                });
            }),
        ));

  constructor(private actions$: Actions, private service: PromotionService, private store: Store<AppState>) { }
}
