<div class="driver-right-bar">
  <mat-progress-bar class="progress-bar-top" *ngIf="loading" mode="indeterminate"></mat-progress-bar>
  <div class="h-100">
    <div class="rightbar-title px-3 py-4">
      <a id="hideActionLink" href="javascript:void(0);" class="driver-right-bar-toggle float-right" (click)="hide();">
        <i class="mdi mdi-close noti-icon"></i>
      </a>
      <h5 class="m-0">Driver Details</h5>
    </div>
    <hr class="mt-0">
    <div class="" *ngIf="!loading" style="position:relative;">
      <div class="suspend-badge" *ngIf="driverDetail?.status?.suspend?.isSuspend || driverDetail?.isDefaulter">
        <img *ngIf="driverDetail.status?.suspend.suspendBy === 'admin'" src="/assets/images/badge/admin-suspend.svg">
        <img *ngIf="driverDetail.status?.suspend.suspendBy === 'master'" src="/assets/images/badge/master-suspend.svg">
        <img *ngIf="driverDetail.isDefaulter && !driverDetail?.status?.suspend?.isSuspend"
          src="/assets/images/badge/defaulter.svg">
      </div>
      <div class="border-bottom">
        <div class="pr-3 pl-3 pb-3">
          <div class="pb-2">
            <img class="rounded-circle avatar-lg" [src]="
                      driverDetail?.profileImage?.imageUrl != null
                        ? driverDetail?.profileImage?.imageUrl
                        : profileImgUrl
                    " alt="" />
          </div>
          <div>
            <h5 class="">{{(driverDetail?.name || '-') | uppercase}}</h5>
            <p class="text-muted mb-1">{{driverDetail?.email}}</p>
            <p class="text-muted mb-0">
              {{ driverDetail?.mobileNumber?.length > 0 ? getPrimaryNumber(driverDetail?.mobileNumber) : '-' }}
              <app-initiate-call *ngIf="driverDetail?._id" [id]="driverDetail?._id" [userType]="'driver'">
              </app-initiate-call>
            </p>
          </div>
        </div>
      </div>
      <div class="border-bottom">
        <div class="pr-3 pl-3 pt-2">
          <div class="row">
            <div class="col-sm-4">
              <div>
                <p class="text-muted mb-2">Status</p>
                <p class="font-weight-bold font-size-14 mb-2">
                  {{(driverDetail?.isDelete ? 'Deleted' : (driverDetail?.status?.suspend?.isSuspend ?
                  'Suspended'+(driverDetail?.isDefaulter ? ' (Defaulter)' : '') : (driverDetail?.status?.isActive |
                  titlecase)))}}
                  <span [ngClass]="{
                                online: driverDetail?.status?.isActive === 'online',
                                offline: (driverDetail?.status?.isActive === 'offline' || driverDetail?.status?.suspend?.isSuspend || driverDetail?.isDelete),
                                ontrip: driverDetail?.status?.isActive === 'onTrip'
                              }">
                  </span>
                </p>
              </div>
            </div>
            <div class="col-sm-8">
              <div class="text-sm-right mt-4 mt-sm-0">
                <p class="text-muted mb-2">Last Updated</p>
                <h6>{{ formatTime(driverDetails?.time, false) }}</h6>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="border-bottom">
        <div class="pr-3 pl-3 pt-2">
          <div class="row">
            <div class="col-sm-6">
              <div>
                <p class="text-muted mb-2">KYC Status</p>
                <h6>{{ driverDetail?.kycStatus || '-' }}</h6>
              </div>
            </div>
            <div class="col-sm-6">
              <div class="text-sm-right mt-4 mt-sm-0">
                <p class="text-muted mb-2">Subscription</p>
                <h6>{{ driverDetail?.subscription?.status ? 'Active' : 'Inactive' }}</h6>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="border-bottom" *ngIf="vehicleDetails?._id && driverDetail?._id">
        <div class="pr-3 pl-3 pt-2">
          <div class="row">
            <div class="col-sm-12">
              <div>
                <p class="text-muted mb-2">Vehicle Details</p>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-sm-4">
              <div class="mt-4 mt-sm-0">
                <img style="width: 100%;" [src]="getVehicleTypeImage(vehicleDetails?.vehicleType?.name || 'sedan')"
                  alt="" />
              </div>
            </div>
            <div class="col-sm-8">
              <div class="mt-4 mt-sm-0">
                <p class="text-muted mb-1">{{(vehicleDetails?.registrationNumber || '-') | uppercase}}</p>
                <p class="text-muted mb-1">{{(vehicleDetails?.brand?.name || '-') | uppercase}}
                  {{(vehicleDetails?.vehicleModel?.name || '-') | uppercase}}</p>
                <p class="text-muted mb-1">{{(vehicleDetails?.vehicleType?.name || '-') | uppercase}}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="border-bottom" *ngIf="false">
        <div class="pr-3 pl-3 pt-2">
          <div class="row">
            <div class="col-sm-12" style="margin-bottom: 80px;">
              <h6>Today's Tracking Details</h6>
              <div class="map-view">
                <div class="map-view-box" *ngIf="!isMapLoading">
                  <agm-map [latitude]="latitude" [zoom]="zoom" [styles]="styles" [longitude]="longitude"
                    [disableDefaultUI]="true" (zoomChange)="zoomChange($event)" [zoomControl]="false"
                    [fullscreenControl]="true" class="map-view" language="en" [mapTypeId]="mapType"
                    (mapReady)="onMapReady($event)">
                    <!-- <agm-marker *ngIf="boundaryPath.length > 0"
                        [latitude]="boundaryPath[0].lat"
                        [longitude]="boundaryPath[0].lng"
                        [markerDraggable]="false"
                        [iconUrl]="'./assets/images/map-marker/car.png'"
                        >
                    </agm-marker> -->

                    <agm-overlay *ngIf="boundaryPath.length > 0" [latitude]="boundaryPath[0].lat"
                      [longitude]="boundaryPath[0].lng">
                      <div (click)="clickedMarker(driverLoc)" style="cursor: pointer !important" [style.transform]="
                          'rotate(' + (boundaryPath[0]?.heading ? boundaryPath[0]?.heading : 0) + 'deg)'
                        ">
                        <img src="./assets/images/map-marker/car.png">
                      </div>
                    </agm-overlay>

                    <agm-marker *ngIf="boundaryPath.length > 0" [latitude]="boundaryPath[boundaryPath.length-1].lat"
                      [longitude]="boundaryPath[boundaryPath.length-1].lng" [markerDraggable]="false"
                      [iconUrl]="'./assets/images/map-marker/source.png'">
                    </agm-marker>
                    <agm-polyline [visible]="true" [strokeWeight]="3" [strokeColor]="'#000000'">
                      <agm-polyline-point *ngFor="let coordinate of boundaryPath; let i=index"
                        [latitude]="coordinate.lat" [longitude]="coordinate.lng">
                      </agm-polyline-point>
                    </agm-polyline>
                  </agm-map>
                  <div class="disable-overlay" *ngIf="isMapDataLoading">
                    <p>Loading Tracking Details... <i class="fa fa-spinner fa-spin"></i></p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="text-center" *ngIf="loading" style="margin-top: 50%;">
      <p>Loading Driver Details...</p>
    </div>
    <ng-template ngxPermissionsOnly="ridesManagementEdit">
      <div class="bottom-action-btn-1" *ngIf="!loading && rideDetails?._id">
        <button id="assignDriverButton" [disabled]="assignLoding" class="btn btn-secondary btn-sm"
          style="width: 100% !important;" (click)="assignDriver()">
          Assign Driver <i *ngIf="assignLoding" class="bx bx-loader bx-spin"></i>
        </button>
      </div>
    </ng-template>
    <ng-template ngxPermissionsOnly="driverManagementView">
      <div class="bottom-action-btn" *ngIf="!loading">
        <button id="viewDetailsButton" class="btn btn-primary btn-sm" style="width: 100% !important;"
          (click)="viewDriver()">
          View Details
        </button>
      </div>
    </ng-template>
  </div>
</div>
<!-- /Right-bar -->

<!-- Right bar overlay-->
<!-- <div class="rightbar-overlay"></div> -->
