import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { environment } from '../../../../environments/environment';
import { apiUrl } from '../../_config/api.config';
// CRUD
import { QueryParamsModel, QueryResultsModel } from "../../_base/crud";

import { getMessaging, getToken, onMessage } from "firebase/messaging";
import { BehaviorSubject } from 'rxjs';

//import { AngularFireMessaging } from '@angular/fire/compat/messaging';

//import { initializeApp } from 'firebase/app';

const BASE_URL = environment.apiUrl;

@Injectable()
export class MessagingService {

  currentMessage = new BehaviorSubject(null);

  constructor(private http: HttpClient,
              //private angularFireMessaging: AngularFireMessaging,
              ) {
    // this.angularFireMessaging.messages.subscribe(
    //   (_messaging) => {
    //     console.log(_messaging);
    //     // _messaging.onMessage = _messaging.onMessage.bind(_messaging);
    //     // _messaging.onTokenRefresh = _messaging.onTokenRefresh.bind(_messaging);
    //   }
    // );
  }

  requestPermission() {
    //const app = initializeApp(environment.firebaseConfig);

    console.log('Requesting permission...');
    Notification.requestPermission().then((permission) => {
      if (permission === 'granted') {
        console.log('Notification permission granted.');
        const messaging = getMessaging();
        getToken(messaging, { vapidKey: environment.vapidKey }).then((currentToken) => {
          if (currentToken) {
            console.log(currentToken);
            let data = {
              regToken: currentToken
            }
            this.updateFcmToken(data);
          } else {
            console.log('No registration token available. Request permission to generate one.');
          }
        }).catch((err) => {
          console.log('An error occurred while retrieving token. ', err);
        });
      }
    });
  }

  receiveMessage() {
    const messaging = getMessaging();
    onMessage(messaging, (payload) => {
      console.log('foreground : ',payload);
      this.currentMessage.next(payload);
      if (payload?.data?.module == 'pendingRequestManagement'){
        // Play custom ride request sound
        this.playAudio("../../../assets/audio/ride_request.mp3");
      }else {
        this.playAudio("../../../assets/audio/message.mp3");
      }
    });
  }

  // Forgot Password API
  updateFcmToken(data: any) {
    this.http.post<any>(BASE_URL+ apiUrl.updateFcmToken, data).subscribe(res=>{
      console.log(res);
    });
  }

  // Get all notifications
  getAllNotifications(queryParams: QueryParamsModel): Observable<any> {
    return this.http
      .get<any>(
        BASE_URL +
          apiUrl.getAllNotification +
          '?pageSize=' +
          queryParams.pageSize +
          '&pageNumber=' +
          queryParams.pageNumber +
          '&sortField=' +
          queryParams.sortField +
          '&sortOrder=' +
          queryParams.sortOrder +
          '&searchText=' +
          queryParams.searchText +
          queryParams.filter
      )
      .pipe(
        mergeMap(res => {
          console.log(res);
          if (res.response.code === 200) {
            const result = {
              items: res.data.list,
              totalCount: res.data.totalCount,
              readCount: res.data.readCount,
              unReadCount: res.data.unReadCount
            };
            return of(result);
          } else {
            const result = {
              items: [],
              totalCount: 0,
              readCount: 0,
              unReadCount: 0
            };
            return of(result);
          }
        })
      );
  }

  // Update read Status
  updateMessageReadStatus(data: any) {
    return this.http.put<any>(BASE_URL+ apiUrl.markAsRead, data);
  }

  playAudio(source) {
    let audio = new Audio();
    audio.src = source;
    try {
      audio.load();
      audio.play();
    } catch (error) {
      console.log(error);
    }
  }
}
