import { Role } from './role.model';
import { Asset } from '../../operations/_models/asset.model';
import { Zone } from '../../master-data';

export class User {
    _id: string;
    mobileNumber: {
        number: number,
        countryCode: number
    };
    name: string;
    email: string;
    verified: boolean;
    address: string;
    dateOfJoining: string;
    access: string;
    // accessDetails: Role;
    // assetId: string[];
    // assetDetails: Asset[];
    // documentImageId: string[];
    activeStatus: boolean;
    employeeID: string;
    firstTimeLogin: boolean;
    isSuperAdmin: boolean;
    profileImage: {
        imageUrl: string
    };
    zone: Zone[];
    isNotAuthUser: boolean;
    partnerId: string;
    partnerType: string;

    clear(): void {
        this._id = undefined;
        this.mobileNumber = {
            number: 91,
            countryCode: 9876543210
        };
        this.name = '';
        this.email = '';
        this.verified = false;
        this.address = '';
        this.dateOfJoining = '';
        this.access = undefined;
        //this.accessDetails = new Role();
        //this.accessDetails.clear();
        // this.assetId = [];
        // this.assetDetails = [];
        // this.documentImageId = [];
        this.activeStatus = false;
        this.employeeID = undefined;
        this.firstTimeLogin = true;
        this.isSuperAdmin = false;
        this.profileImage = {
            imageUrl: ''
        };
        this.zone = [];
        this.isNotAuthUser = false;
        this.partnerId = undefined;
        this.partnerType = undefined;
    }
}
