import { WalletConfiguration } from '../../control-panel';
import { Promotion } from '../../offer';
export class TripFareDetails {
  _id: string;
  fareDetail: FareDetail;
  fareAmount: number;
  surgeCharge: number;
  commission: number;
  totalDiscount: number;
  discount: Discount[];
  waitingCharge: number;
  waitingChargePerMin: number;
  freeWaitingTime: number;
  gracePeriodTime: number;
  cancellationFee: number;
  driverBatta: number;
  tax: number;
  due: number;
  isDelete: boolean;
  rideId: string;
  taxDetail: {
    invoiceId: string,
    applicableTaxes: TaxApplicable[]
  };
  dueRideDetail: DueRideDetail[];
  transferFee: number;
  razorPayDetail: RazorPayDetail;
  driverPromotionDetail: Promotion;
  promotionDetail: Promotion;
  walletAmount: number;
  walletDetail: WalletConfiguration;

  riderFare: number;
  driverFare: number;
  commissionDetail: {
    isPercentage: boolean,
    commissionFare: number,
  }
  promotionDiscount: number;
  commissionDiscount: {
    reason: string,
    value: number,
  }

  rentalPackageDetails: {
    spendingTime: number
  }
  scheduleCancellationRewardDetail: {
    isPercentage: boolean,
    fixedPrice: number,
  }
  scheduleCancellationReward: number;
  reservationCharge: number;
  pickUpChargeDetail: {
    isFixed: boolean,
    fixedPrice: number,
    paidBy: string,
    fixedRange: number,
  }
  pickUpCharge: number;
  cancellationChargeDetail: {
    fixedPrice: number,
    fixedRange: number,
    fixedTime: number,
  }

  tollCharge: number;

  __v: 0;

  clear(): void {
    this._id = undefined;
    this.fareDetail = new FareDetail();
    this.fareDetail.clear();
    this.fareAmount = 0;
    this.surgeCharge = 0;
    this.commission = 0;
    this.totalDiscount = 0;
    this.discount = [];
    this.waitingCharge = 0;
    this.waitingChargePerMin = 0;
    this.freeWaitingTime = 0;
    this.gracePeriodTime = 0;
    this.cancellationFee = 0;
    this.driverBatta = 0;
    this.tax = 0;
    this.due = 0;
    this.isDelete = false;
    this.rideId = undefined;
    this.taxDetail = {
      invoiceId: '',
      applicableTaxes: []
    };
    this.dueRideDetail = [];
    this.transferFee = 0;
    this.razorPayDetail = new RazorPayDetail();
    this.driverPromotionDetail = new Promotion();
    this.promotionDetail = new Promotion();
    this.walletAmount = 0;
    this.walletDetail = new WalletConfiguration();

    this.riderFare = 0;
    this.driverFare = 0;
    this.commissionDetail = {
      isPercentage: false,
      commissionFare: 0,
    }
    this.promotionDiscount = 0;
    this.commissionDiscount = {
      reason: '',
      value: 0,
    }
    this.rentalPackageDetails = {
      spendingTime: 0
    }
    this.scheduleCancellationRewardDetail = {
      isPercentage: false,
      fixedPrice: 0,
    }
    this.scheduleCancellationReward = 0;
    this.reservationCharge = 0;
    this.pickUpChargeDetail = {
      isFixed: false,
      fixedPrice: 0,
      paidBy: '',
      fixedRange: 0,
    }
    this.pickUpCharge = 0;
    this.cancellationChargeDetail = {
      fixedPrice: 0,
      fixedRange: 0,
      fixedTime: 0,
    }

    this.tollCharge = 0;

    this.__v = 0;
  }
}

export class FareDetail {
  baseFare: {
    fixedRange: number,
    fixedPrice: number,
    fixedTime: number,
    minRange: number,
    maxRange: number,
    slab: number,
  };
  extraDistancePrice: number;
  extraTimePrice: number;
  driverBatta: number;
  permitCharge: number;

  cancellationCharge: number;
  isSurgeApplicable: boolean;
  isExceptionApplicable: boolean;
  isAirportFareFixed: boolean;
  isExtraTimePriceHourly: boolean;
  searchRange: number;
  searchRangeMin: number;
  searchTime: number;
  fromSearchRange: number;
  fromSearchRangeMin: number;
  fromSearchTime: number;

  clear(): void {
    this.baseFare = {
      fixedRange: 0,
      fixedPrice: 0,
      fixedTime: 0,
      minRange: 0,
      maxRange: 0,
      slab: undefined,
    };
    this.extraDistancePrice = 0;
    this.extraTimePrice = 0;
    this.driverBatta = 0;
    this.permitCharge = 0;

    this.cancellationCharge = 0;
    this.isSurgeApplicable = false;
    this.isExceptionApplicable = false;
    this.isAirportFareFixed = false;
    this.isExtraTimePriceHourly = false;
    this.searchRange = 0;
    this.searchRangeMin = 0;
    this.searchTime = 0;
    this.fromSearchRange = 0;
    this.fromSearchRangeMin = 0;
    this.fromSearchTime = 0;
  }
}

export class TaxApplicable {
  _id: string;
  taxName: string;
  taxPercentage: string;
  taxAmount: number;

  clear(): void {
    this._id = undefined;
    this.taxName = '';
    this.taxPercentage = '';
    this.taxAmount = 0;
  }
}

export class DueRideDetail {
  _id: string;
  rideId: string;
  due: number;

  clear(): void {
    this._id = undefined;
    this.rideId = undefined;
    this.due = 0;
  }
}

export class Discount {
  reason: string;
  value: number;

  clear(): void {
    this.reason = '';
    this.value = 0;
  }
}

export class RazorPayDetail {
  settlementAmount: number;
  paymentMethod: string;
  orderId: string;
  fee: number;
  tax: number;
  paymentProcessedTime: string;
  paymentCompletedTime: string;
  paymentSettlementStatus: string;
  utr: string;

  clear(): void {
    this.settlementAmount = 0;
    this.paymentMethod = '';
    this.orderId = undefined;
    this.fee = 0;
    this.tax = 0;
    this.paymentProcessedTime = '';
    this.paymentCompletedTime = '';
    this.paymentSettlementStatus = '';
    this.utr = undefined;
  }
}


