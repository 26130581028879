import { QueryParamsModel } from '../../_base/crud/models/query-models/query-params.model';

// Angular
import { Injectable } from '@angular/core';
// RxJS
import { mergeMap, map, tap, delay, debounceTime, distinctUntilChanged, switchMap } from 'rxjs/operators';
import { Observable, defer, of, forkJoin } from 'rxjs';
// NGRX
import { createEffect, Actions, ofType } from '@ngrx/effects';
import { Store, select, Action } from '@ngrx/store';
// import { map, switchMap, catchError } from "rxjs/operators";
// CRUD
import { QueryResultsModel } from '../../_base/crud';
import { AppState } from '../../reducers';

//Service
import { RideReportService } from '../_services/rideReport.services';
import {
    RideReportActionTypes,
    RideReportsPageRequested,
    RideReportsPageLoaded,
    RideReportsPageToggleLoading,
    RideReportsActionToggleLoading
} from '../_actions/rideReport.actions';

@Injectable()
export class RideReportEffects {
    showPageLoadingDistpatcher = new RideReportsPageToggleLoading({ isLoading: true });
    hidePageLoadingDistpatcher = new RideReportsPageToggleLoading({ isLoading: false });

    showActionLoadingDistpatcher = new RideReportsActionToggleLoading({ isLoading: true });
    hideActionLoadingDistpatcher = new RideReportsActionToggleLoading({ isLoading: false });

    loadRideReportsPage$ = createEffect(() => this.actions$.pipe(
            ofType<RideReportsPageRequested>(RideReportActionTypes.RideReportsPageRequested),
            debounceTime(50),
            distinctUntilChanged(),
            switchMap(( { payload } ) => {
                this.store.dispatch(this.showPageLoadingDistpatcher);
                console.log(payload.page.apiUrl);
                const requestToServer = this.service.getAllRideReports(payload.page);
                const lastQuery = of(payload.page);
                return forkJoin(requestToServer, lastQuery);
            }),
            map(response => {
                console.log(response);
                const result: QueryResultsModel = response[0];
                console.log(result);

                const lastQuery: QueryParamsModel = response[1];
                return new RideReportsPageLoaded({
                    rideReports: result.items,
                    totalCount: result.totalCount,
                    totalAmount: result.extra.totalAmount,
                    page: lastQuery
                });
            }),
        ));

    constructor(private actions$: Actions, private service: RideReportService, private store: Store<AppState>) { }
}
