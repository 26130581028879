<div class="dropdown d-none d-lg-inline-block ml-1" ngbDropdown>
  <button type="button" class="btn header-item noti-icon" ngbDropdownToggle>
    <i class="bx bx-customize"></i>
  </button>
  <div class="dropdown-menu dropdown-menu-lg dropdown-menu-right" ngbDropdownMenu>
    <div class="px-lg-2">
      <div class="row no-gutters">
        <div class="col">
          <a class="dropdown-icon-item" href="javascript:void(0);" routerLink="/tools/url-shortener" routerLinkActive="active">
            <img src="assets/images/tools/url-shortner.png" alt="URL Shortner">
            <span>{{'TOOLS.URL_SHORTNER' | translate}}</span>
          </a>
        </div>
        <div class="col">
          <a class="dropdown-icon-item" href="javascript:void(0);" routerLink="/tools/data-sync" routerLinkActive="active">
            <img src="assets/images/tools/data-sync.png" alt="Data sync">
            <span>{{'TOOLS.DATA_SYNC'| translate}}</span>
          </a>
        </div>
      </div>

      <!-- <div class="row no-gutters">
        <div class="col">
          <a class="dropdown-icon-item" href="javascript: void(0);">
            <img src="assets/images/brands/dropbox.png" alt="dropbox">
            <span>Dropbox</span>
          </a>
        </div>
        <div class="col">
          <a class="dropdown-icon-item" href="javascript: void(0);">
            <img src="assets/images/brands/mail_chimp.png" alt="mail_chimp">
            <span>Mail Chimp</span>
          </a>
        </div>
        <div class="col">
          <a class="dropdown-icon-item" href="javascript: void(0);">
            <img src="assets/images/brands/slack.png" alt="slack">
            <span>Slack</span>
          </a>
        </div>
      </div> -->
    </div>
  </div>
</div>
