import { Zone, City } from '../../master-data';
import { Activity } from '../../logs';

export class Driver {
  id: string;
  _id: string;
  emergency: {
    countryCode: string;
    contact: string;
  };
  differentlyAbled: {
    status: boolean;
    details: string;
  };
  otpManagement: {
    otp: number;
    time: string;
  };
  subscriptionDetails: any[];
  subscription: any;
  status: {
    suspend: {
      isSuspend: boolean;
      reason: string;
      suspendBy: string;
    };
    isActive: string;
  };
  report: {
    master: {
      isReport: boolean;
    };
    admin: {
      isReport: boolean;
    };
  };
  name: string;
  gender: string;
  driverID: string;
  address: string;
  email: string;
  dob: string;
  step: number;
  availableSeats: number;
  rating: string;
  kycStatus: string;
  bloodGroup: string;
  qualification: string;
  languageKnown: any[];
  tripInterest: string;
  statusToTakeRides: boolean;
  signUpVerification: boolean;
  isDelete: boolean;
  isDeleteReason: string;
  isTrialUsed: boolean;
  isDefaulter: boolean;
  masterMobileNumber: {
    countryCode: string;
    number: string;
  };
  dateOfJoining: string;
  master: Driver;
  mobileNumber: any[];
  totalRevenue: number;
  totalRides: number;
  __v: number;
  driverType: string;
  downStreamLabel: string;
  upStreamLabel: string;
  profileImageId: any;
  zoneId : string;
  zone: Zone;
  cityId: string;
  city: City;
  profileImage: {
    imageUrl: string;
  };
  isTermsAgreed: boolean;
  version: Activity;

  isVerified: boolean;

  remarks: {
    comment: string,
  }

  clear(): void {
    this.id = undefined;
    this._id = undefined;
    this.emergency = {
      contact: "",
      countryCode: "91"
    };
    this.differentlyAbled = {
      status: false,
      details: ""
    };
    this.otpManagement = {
      otp: 7483,
      time: ""
    };
    this.subscriptionDetails = [];
    this.subscription = null;
    this.status = {
      suspend: {
        isSuspend: false,
        reason: "",
        suspendBy: "admin"
      },
      isActive: 'offline'
    };
    this.report = {
      master: {
        isReport: false
      },
      admin: {
        isReport: false
      }
    };
    this.name = "";
    this.gender = ""
    this.driverID = undefined;
    this.address = "";
    this.email = "";
    this.dob = "";
    this.bloodGroup = "";
    this.qualification = "";
    this.languageKnown = [];
    this.tripInterest = "";
    this.statusToTakeRides = true;
    this.signUpVerification = true;
    this.isDelete = false;
    this.isDeleteReason = '';
    this.isTrialUsed = false;
    this.isDefaulter = false;
    this.step = 0;
    this.availableSeats = 2;
    this.masterMobileNumber = {
      countryCode: "91",
      number: ""
    };
    this.dateOfJoining = '';
    this.master = undefined;
    this.mobileNumber = [];
    this.totalRevenue = 0;
    this.totalRides = 0;
    this.__v = 0;
    this.profileImageId = undefined;
    this.profileImage = {
      imageUrl: ""
    };
    this.kycStatus = "";
    this.downStreamLabel = undefined;
    this.upStreamLabel = undefined;
    this.driverType = "";
    this.rating = "";
    this.zoneId = "";
    this.zone = new Zone();
    this.zone.clear();
    this.cityId = "";
    this.city = new City();
    this.city.clear();
    this.isTermsAgreed = false;
    this.version = new Activity();
    this.version.clear();

    this.isVerified = false;

    this.remarks = {
      comment: '',
    }
  }
}
