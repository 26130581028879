// NGRX
import { createFeatureSelector, createSelector } from '@ngrx/store';
// State
import { TollsState } from '../_reducers/toll.reducers';
import * as fromTolls from '../_reducers/toll.reducers';

export const selectTollsState = createFeatureSelector<TollsState>('tolls');

export const selectTollById = (tollId: string) => createSelector(
    selectTollsState,
    ps => ps.entities[tollId]
);

export const selectAllTolls = createSelector(
    selectTollsState,
    fromTolls.selectAll
);

export const selectAllTollsIds = createSelector(
    selectTollsState,
    fromTolls.selectIds
);

export const allTollsLoaded = createSelector(
    selectTollsState,
    ps  => ps._isAllTollsLoaded
);
