import { Injectable, PipeTransform } from "@angular/core";

import { BehaviorSubject, Observable, of, Subject } from "rxjs";
import { debounceTime, delay, switchMap, tap, mergeMap, catchError, map } from "rxjs/operators";

import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { apiUrl } from '../../_config/api.config';
import { QueryParamsModel } from '../../_base/crud';

const BASE_URL = environment.apiUrl;

@Injectable({
  providedIn: "root",
})

export class LocationService {

  constructor(private http: HttpClient) { }

  //Get all countries
  getAllCountries(): Observable<any> {
    return this.http.get<any>(BASE_URL + apiUrl.country).pipe(
      map((res: any) => {
        if (res.response.code === 200) {
          return res.data.list;
        } else {
          return [];
        }
      }),
      catchError(err => {
        console.log(err);
        return [];
      })
    );
  }

  //Get all countries
  getAllStates(countryId): Observable<any> {
    return this.http.get<any>(BASE_URL + apiUrl.state + '?countryId=' + countryId).pipe(
      map((res: any) => {
        if (res.response.code === 200) {
          return res.data.list;
        } else {
          return [];
        }
      }),
      catchError(err => {
        console.log(err);
        return [];
      })
    );
  }

  //Get all countries
  getAllPincodes(stateName): Observable<any> {
    return this.http.get<any>(BASE_URL + apiUrl.postalCode +'?stateName=' + stateName).pipe(
      map((res: any) => {
        if (res.response.code === 200) {
          return res.data.list;
        } else {
          return [];
        }
      }),
      catchError(err => {
        console.log(err);
        return [];
      })
    );
  }

  // Get all zones with filter
  getAllZones(queryParams: QueryParamsModel): Observable<any> {
    return this.http.get<any>(BASE_URL + apiUrl.getAllZones +
    '?pageSize=' + queryParams.pageSize +
    '&pageNumber=' + queryParams.pageNumber +
    '&sortOrder=' + queryParams.sortOrder +
    '&sortField=' + queryParams.sortField +
    '&searchText=' + queryParams.searchText + queryParams.filter).pipe(
      mergeMap(res => {
        console.log(res);
        if (res.response.code === 200) {
          const result = {
            items: res.data.list,
            totalCount: res.data.totalCount
          };
          return of(result);
        } else {
          const result = {
            items: [],
            totalCount: 0
          };
          return of(result);
        }
      })
    );
  }

  // Get all zones without filter
  getZones(): Observable<any> {
    return this.http.get<any>(BASE_URL + apiUrl.getAllZones +'?isDelete=false&pageSize=1000000&pageNumber=1').pipe(
      map((res: any) => {
        if (res.response.code === 200) {
          return res.data.list;
        } else {
          return [];
        }
      }),
      catchError(err => {
        console.log(err);
        return [];
      })
    );
  }

  // Get single zone
  getZoneById(_id: string): Observable<any> {
    return this.http.get(BASE_URL + apiUrl.zone +'?zoneId=' + _id);
  }

  // Add new zone
  createZone(zone: any): Observable<any> {
    return this.http.post(BASE_URL + apiUrl.zone, zone);
  }

  // Update zone
  updateZone(zone: any): Observable<any> {
    return this.http.put(BASE_URL + apiUrl.zone, zone);
  }

  // Delete zone
  deleteZone(zone: any): Observable<any> {
    return this.http.delete(BASE_URL + apiUrl.zone +'?zoneId=' + zone._id + '&isDelete=' + zone.isDelete);
  }

  // Get all city with filter
  getAllCities(queryParams: QueryParamsModel): Observable<any> {
    return this.http.get<any>(BASE_URL + apiUrl.getAllCites +
    '?pageSize=' + queryParams.pageSize +
    '&pageNumber=' + queryParams.pageNumber +
    '&sortOrder=' + queryParams.sortOrder +
    '&sortField=' + queryParams.sortField +
    '&searchText=' + queryParams.searchText + queryParams.filter).pipe(
      mergeMap(res => {
        console.log(res);
        if (res.response.code === 200) {
          const result = {
            items: res.data.list,
            totalCount: res.data.totalCount
          };
          return of(result);
        } else {
          const result = {
            items: [],
            totalCount: 0
          };
          return of(result);
        }
      })
    );
  }

  // Get all city with filter
  getCities(): Observable<any> {
    return this.http.get<any>(BASE_URL + apiUrl.getAllCites + '?isDelete=false&pageSize=1000&pageNumber=1').pipe(
      map((res: any) => {
        if (res.response.code === 200) {
          return res.data.list;
        } else {
          return [];
        }
      }),
      catchError(err => {
        console.log(err);
        return [];
      })
    );
  }

  // Get single city
  getCityById(_id: string): Observable<any> {
    return this.http.get(BASE_URL + apiUrl.city +'?cityId=' + _id);
  }

  // Add new city
  createCity(city: any): Observable<any> {
    return this.http.post(BASE_URL + apiUrl.city, city);
  }

  // Update city
  updateCity(city: any): Observable<any> {
    return this.http.put(BASE_URL + apiUrl.city, city);
  }

  // Delete city
  deleteCity(city: any): Observable<any> {
    return this.http.delete(BASE_URL + apiUrl.city +'?cityId=' + city._id + '&isDelete=' + city.isDelete);
  }

  // Get all airport with filter
  getAllAirports(queryParams: QueryParamsModel): Observable<any> {
    return this.http.get<any>(BASE_URL + apiUrl.getAllAirports +
    '?pageSize=' + queryParams.pageSize +
    '&pageNumber=' + queryParams.pageNumber +
    '&sortOrder=' + queryParams.sortOrder +
    '&sortField=' + queryParams.sortField +
    '&searchText=' + queryParams.searchText + queryParams.filter).pipe(
      mergeMap(res => {
        console.log(res);
        if (res.response.code === 200) {
          const result = {
            items: res.data.list,
            totalCount: res.data.totalCount
          };
          return of(result);
        } else {
          const result = {
            items: [],
            totalCount: 0
          };
          return of(result);
        }
      })
    );
  }

  // Get all airport without filter
  getAirports(query): Observable<any> {
    return this.http.get<any>(BASE_URL + apiUrl.getAllAirports +'?isDelete=false&pageSize=1000&pageNumber=1' + query).pipe(
      map((res: any) => {
        if (res.response.code === 200) {
          return res.data.list;
        } else {
          return [];
        }
      }),
      catchError(err => {
        console.log(err);
        return [];
      })
    );
  }

  // Get single airport
  getAirportById(_id: string): Observable<any> {
    return this.http.get(BASE_URL + apiUrl.airport +'?airportId=' + _id);
  }

  // Add new airport
  createAirport(airport: any): Observable<any> {
    return this.http.post(BASE_URL + apiUrl.airport, airport);
  }

  // Update airport
  updateAirport(airport: any): Observable<any> {
    return this.http.put(BASE_URL + apiUrl.airport, airport);
  }

  // Delete airport
  deleteAirport(airport: any): Observable<any> {
    return this.http.delete(BASE_URL + apiUrl.airport +'?airportId=' + airport._id + '&isDelete=' + airport.isDelete);
  }

  // Get all popular location with filter
  getAllPopularLocations(queryParams: QueryParamsModel): Observable<any> {
    return this.http.get<any>(BASE_URL + apiUrl.getAllPopularLocations +
      '?pageSize=' + queryParams.pageSize +
      '&pageNumber=' + queryParams.pageNumber +
      '&sortOrder=' + queryParams.sortOrder +
      '&sortField=' + queryParams.sortField +
      '&searchText=' + queryParams.searchText + queryParams.filter).pipe(
        mergeMap(res => {
          console.log(res);
          if (res.response.code === 200) {
            const result = {
              items: res.data.list,
              totalCount: res.data.totalCount
            };
            return of(result);
          } else {
            const result = {
              items: [],
              totalCount: 0
            };
            return of(result);
          }
        })
      );
  }

  getPopularLocations() {
    return this.http.get<any>(BASE_URL + apiUrl.getAllPopularLocations +'?isDelete=false&isSuspend=false&pageSize=1000000&pageNumber=1').pipe(
      map((res: any) => {
        if (res.response.code === 200) {
          return res.data.list;
        } else {
          return [];
        }
      }),
      catchError(err => {
        console.log(err);
        return [];
      })
    );
  }

  // Get single popular location
  getPopularLocationById(_id: string): Observable<any> {
    return this.http.get(BASE_URL + apiUrl.popularLocation +'?_id=' + _id);
  }

  // Add new popular location
  createPopularLocation(popularLocation: any): Observable<any> {
    return this.http.post(BASE_URL + apiUrl.popularLocation, popularLocation);
  }

  // Update popular location
  updatePopularLocation(popularLocation: any): Observable<any> {
    return this.http.put(BASE_URL + apiUrl.popularLocation, popularLocation);
  }

  // Delete popular location
  deletePopularLocation(popularLocation: any): Observable<any> {
    return this.http.delete(BASE_URL + apiUrl.popularLocation +'?_id=' + popularLocation._id + '&isDelete=' + popularLocation.isDelete);
  }

  // Get all popular package with filter
  getAllPopularPackages(queryParams: QueryParamsModel): Observable<any> {
    return this.http.get<any>(BASE_URL + apiUrl.getAllPopularPackages +
      '?pageSize=' + queryParams.pageSize +
      '&pageNumber=' + queryParams.pageNumber +
      '&sortOrder=' + queryParams.sortOrder +
      '&sortField=' + queryParams.sortField +
      '&searchText=' + queryParams.searchText + queryParams.filter).pipe(
        mergeMap(res => {
          console.log(res);
          if (res.response.code === 200) {
            const result = {
              items: res.data.list,
              totalCount: res.data.totalCount
            };
            return of(result);
          } else {
            const result = {
              items: [],
              totalCount: 0
            };
            return of(result);
          }
        })
      );
  }

  // Get all popular package without filter
  getPopularPackages(query): Observable<any> {
    return this.http.get<any>(BASE_URL + apiUrl.getAllPopularPackages +'?isDelete=false&pageSize=1000&pageNumber=1' + query).pipe(
      map((res: any) => {
        if (res.response.code === 200) {
          return res.data.list;
        } else {
          return [];
        }
      }),
      catchError(err => {
        console.log(err);
        return [];
      })
    );
  }

  // Get single popular package
  getPopularPackageById(_id: string): Observable<any> {
    return this.http.get(BASE_URL + apiUrl.popularPackage +'?_id=' + _id);
  }

  // Add new popular package
  createPopularPackage(popularPackage: any): Observable<any> {
    return this.http.post(BASE_URL + apiUrl.popularPackage, popularPackage);
  }

  // Update popular package
  updatePopularPackage(popularPackage: any): Observable<any> {
    return this.http.put(BASE_URL + apiUrl.popularPackage, popularPackage);
  }

  // Delete popular package
  deletePopularPackage(popularPackage: any): Observable<any> {
    return this.http.delete(BASE_URL + apiUrl.popularPackage +'?_id=' + popularPackage._id + '&isDelete=' + popularPackage.isDelete);
  }

}
