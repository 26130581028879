import { Subscription } from 'rxjs';
// Angular
import { ChangeDetectionStrategy, Component, OnDestroy, OnInit, ChangeDetectorRef } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
// Layout
import { SplashScreenService } from './core/_base/layout';
import { ConnectionService } from 'ng-connection-service';
// CRUD
import { InterceptService, LayoutUtilsService } from './core/_base/crud';
import { TranslationService } from './core/_base/layout';
import { SwUpdate, VersionReadyEvent } from '@angular/service-worker';

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AppComponent implements OnInit, OnDestroy {
  // Public properties
  title = 'Dots Taxi';
  loader: boolean = true;
  private unsubscribe: Subscription[] = [];
  noInternetConnection: boolean;
  isConnected = true;

  constructor(private router: Router,
              private splashScreenService: SplashScreenService,
              private connectionService: ConnectionService,
              private interceptService: InterceptService,
              private cdr: ChangeDetectorRef,
              private updates: SwUpdate,
              private layoutUtilsService: LayoutUtilsService,
              private translationService: TranslationService) {
                // register translations
                this.translationService.loadTranslations();

                this.interceptService.getClientIp();
                this.unsubscribe.push(this.connectionService.monitor().subscribe(isConnected => {
                  this.isConnected = isConnected?.hasNetworkConnection;
                  console.log("Network connection status : " + this.isConnected);
                  if (isConnected) {
                    this.noInternetConnection = false;
                  }
                  else {
                    this.noInternetConnection = true;
                  }
                  this.cdr.markForCheck();
                }));
              }

  ngOnInit(): void {
    if (this.updates.isEnabled) {
      this.updates.versionUpdates.subscribe((event: VersionReadyEvent) => {
        console.log("Event Tpe : ",event)
        if (event.type === "VERSION_READY") {
          const latestversionData = event.latestVersion.appData as any;
          const currentVersionData = event.currentVersion.appData as any;

          const _type = 'info';
          const _title = 'Update Available';
          const _description = `A new version of the app is available!. You are currently using ${currentVersionData.version}(${currentVersionData.code}). The latest version ${latestversionData.version}(${latestversionData.code}) is now available. Would you like to update now for the best experience?`;
          const _confirmButton = 'Update'
          const dialogRef = this.layoutUtilsService.confirmationPopup(_type, _title, _description, _confirmButton);
          dialogRef.afterClosed().subscribe(res => {
            if (!res) {
              console.log("User chose to stay on the old version.");
              return;
            }
            if (res) {
              this.updates.activateUpdate().then(() => {
                window.location.replace(window.location.href);
                //window.location.reload();
              });
            }
          });
        }
      });

      // **Force checking for updates on every reload**
      setTimeout(() => {
        this.updates.checkForUpdate().then(() => {
          console.log("Checked for updates.");
        });
      }, 2000);
    }

    const routerSubscription = this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        // hide splash screen
        this.splashScreenService.hide();

        // scroll to top on every route change
        if (!(this.router.url.indexOf('/driver-management/cug/') > -1)) {
          window.scrollTo(0, 0);
        }

        // to display back the body content
        setTimeout(() => {
          document.body.classList.add('kt-page--loaded');
        }, 500);
      }
    });

    this.unsubscribe.push(routerSubscription);
  }

  /**
   * On Destroy
   */
  ngOnDestroy() {
    this.unsubscribe.forEach(sb => sb.unsubscribe());
  }
}
