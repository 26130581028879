//Moment
import moment from 'moment';

export class QueryParamsModel {
	// fields
	filter: string;
	sortOrder: string; // asc || desc || ''
	sortField: string;
	pageNumber: number;
	pageSize: number;
	searchText: string;
  lastRequested: string;
  apiUrl: string;

	// constructor overrides
	constructor(_filter: string = '',
	           _sortOrder: string = '',
	           _sortField: string = '',
	           _pageNumber: number = 1,
	           _pageSize: number = 10,
             _searchText: string = '',
             _lastRequested: string = '',
             _apiUrl: string = undefined,
    ) {
		this.filter = _filter;
		this.sortOrder = _sortOrder;
		this.sortField = _sortField;
		this.pageNumber = _pageNumber;
		this.pageSize = _pageSize;
		this.searchText = _searchText;
    this.lastRequested = _lastRequested;
    this.apiUrl = _apiUrl;
	}
}
