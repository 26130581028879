import { Injectable, PipeTransform } from "@angular/core";

import { BehaviorSubject, Observable, of, Subject } from "rxjs";
import { debounceTime, delay, switchMap, tap, mergeMap } from "rxjs/operators";

import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { apiUrl } from '../../_config/api.config';
import { QueryParamsModel } from '../../_base/crud';

const BASE_URL = environment.apiUrl;

@Injectable({
  providedIn: "root",
})

export class VehicleService {

  constructor(private http: HttpClient) {}

  // Get all vehicles
  getAllVehicles(queryParams: QueryParamsModel):Observable<any> {
    return this.http.get<any>(BASE_URL+apiUrl.getAllVehicle+
    '?pageSize='+queryParams.pageSize +
    '&pageNumber='+queryParams.pageNumber+
    '&sortOrder='+queryParams.sortOrder+
    '&sortField='+queryParams.sortField+
    '&searchText='+queryParams.searchText+
    queryParams.filter).pipe(
      mergeMap(res => {
          console.log(res);
          if(res.response.code === 200) {
              const result = {
                items: res.data.list,
                totalCount: res.data.totalCount
              };
              return of(result);
          } else {
            const result = {
              items: [],
              totalCount: 0
            };
            return of(result);
          }
      })
    );
  }

  // Get a vehicle
  getVehicle(id: string): Observable<any> {
      return this.http.get<any>(BASE_URL+ apiUrl.vehicle +'?_id='+id);
  }

  // Add a new vehicle
  addVehicle(vehicle: any): Observable<any> {
      return this.http.post<any>(BASE_URL+apiUrl.vehicle, vehicle);
  }

  // Update a vehicle
  updateVehicle(vehicle: any): Observable<any> {
    return this.http.put<any>(BASE_URL + apiUrl.vehicle, vehicle);
  }

  // Delete a vehicle
  deleteVehicle(vehicle: any): Observable<any> {
    return this.http.delete(BASE_URL +  apiUrl.vehicle +'?_id=' + vehicle._id+'&isDelete='+vehicle.isDelete);
  }

  // Get all vehicle filter attribute
  getVehicleFilter(_brand: string, _model: string): Observable<any> {
    return this.http.get(BASE_URL + apiUrl.vehicleFilter +'?brand=' + _brand + '&vehicleModel='+_model);
  }

  // Get all vehicle attributes
  getAllAttributes(isDelete: boolean = true): Observable<any>{
    // let url = '';
    // if (!isDelete) {
    //   url = '?isDelete=false';
    // }
    return this.http.get(BASE_URL+apiUrl.getAllVehicleAttribute);
  }

  // Get single vehicle attribute
  getAttributes(type: string): Observable<any>{
    return this.http.get(BASE_URL+ apiUrl.vehicleAttribute +'?attribute='+type);
  }

  // Add new vehicle attribute
  addAttribute(attribute: any): Observable<any>{
    return this.http.post(BASE_URL+apiUrl.vehicleAttribute,attribute);
  }

  // Update vehicle attribute
  updateAttribute(attribute: any): Observable<any>{
    return this.http.put(BASE_URL+apiUrl.vehicleAttribute,attribute);
  }

  // Delete vehicle attribute
  deleteAttribute(id: string, attribute: string, status: boolean): Observable<any>{
    return this.http.delete(BASE_URL+ apiUrl.vehicleAttribute +'?attribute='+attribute+'&_id='+id+'&isDelete='+status);
  }

  // Get all vehicle groups with filter
  getAllVehicleGroup(queryParams: QueryParamsModel): Observable<any> {
    return this.http.get<any>(BASE_URL + apiUrl.vehicleGroup +
      '?pageSize=' + queryParams.pageSize +
      '&pageNumber=' + queryParams.pageNumber +
      '&sortOrder=' + queryParams.sortOrder +
      '&sortField=' + queryParams.sortField +
      '&searchText=' + queryParams.searchText + queryParams.filter).pipe(
        mergeMap(res => {
          console.log(res);
          if (res.response.code === 200) {
            const result = {
              items: res.data,
              totalCount: res.data.length
            };
            return of(result);
          } else {
            const result = {
              items: [],
              totalCount: 0
            };
            return of(result);
          }
        })
      );
  }

}
