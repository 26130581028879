// Angular
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
// Material
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';

import { LocationPickerComponent } from '../../../../views/partials/crud/location-picker/location-picker.component';

@Injectable({
	providedIn: 'root'
})
export class LocationPickerService {

	constructor(private dialog: MatDialog) {}

  // Show location picker component
	pickLocation(data: any, type: string = 'pickup') {
		return this.dialog.open(LocationPickerComponent, {
			data: { data , type },
      width: '650px'
		});
	}

}
