<div class="modal-header border-bottom-0">
  <button type="button" class="close" aria-hidden="true" (click)="closeDialog()">×</button>
</div>
<div class="modal-body pt-0">
  <h5 class="modal-title mt-0 mb-0 font-size-15 text-dark text-center">{{getTitle()}}</h5>
  <div class="row">
    <div class="col-12 pt-0 mt-0 pb-2">
      <form class="form-horizontal">
        <div class="col-md-12">
          <app-alert></app-alert>
        </div>
        <div class="col-md-12 mt-4 mb-0" *ngIf="!isMapLoading">
          <label>Location Picker</label>
          <div class="map-container">
            <div class="search-box">
              <input type="text" class="form-control font-size-13" (keydown.enter)="$event.preventDefault()"
                placeholder="Search Location" autocorrect="off" autocapitalize="off" spellcheck="off" type="text" #search />
              <!-- <div class="auto-complete-prediction" *ngIf="placesPrediction.length > 0">
                <div class="auto-complete-prediction-item" *ngFor="let item of placesPrediction" (click)="onPredictionSelect(item, 'osm')">
                  {{item?.display_name}}</div>
              </div> -->

              <div class="auto-complete-prediction" *ngIf="placesPrediction.length > 0">
                <div class="auto-complete-prediction-item" *ngFor="let item of placesPrediction" (click)="onPredictionSelect(item, 'gsm')">
                  {{item?.description}}</div>
              </div>
              <!-- <mat-icon>search</mat-icon> -->
            </div>
            <!-- <agm-map [latitude]="location?.lat" [zoom]="zoom" [longitude]="location?.long" [styles]="styles"
              [disableDefaultUI]="false" [fullscreenControl]="true" language="en" [mapTypeId]="mapType"
              (mapReady)="onMapReady($event)">
              <agm-marker [latitude]="location?.lat" [longitude]="location?.long" [markerDraggable]="true"
                (dragEnd)="markerDragEnd($event)" iconUrl="./assets/images/map-marker.png" animation="BOUNCE">
              </agm-marker>
            </agm-map> -->
            <google-map #mapComponent [center]="{ lat: location?.lat, lng: location?.long }" [zoom]="zoom" height="100%"
              width="100%" [options]="mapOptions" [mapTypeId]="mapType" (mapInitialized)="onMapReady($event)">
              <map-marker [position]="{ lat: location?.lat, lng: location?.long }" [options]="markerOptions" 
                (mapDragEnd)="markerDragEnd($event)" [icon]="'./assets/images/map-marker.png'" animation="BOUNCE">
              </map-marker>
            </google-map>
            <!-- <div class="disable-overlay" *ngIf="promotion.isReadOnly"></div> -->
          </div>
        </div>
        <div class="mt-4 col-md-12 text-right">
          <button type="button" class="btn btn-secondary w-sm" (click)="closeDialog()">Cancel</button>
          <button type="button" class="btn btn-primary w-sm ml-3" (click)="onSubmit()">Confirm</button>
        </div>
      </form>
    </div>
  </div>
</div>
