import { Injectable, PipeTransform } from "@angular/core";

import { BehaviorSubject, Observable, of, Subject } from "rxjs";
import { debounceTime, delay, switchMap, tap, mergeMap } from "rxjs/operators";

import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { apiUrl } from '../../_config/api.config';
import { QueryParamsModel } from '../../_base/crud';

const BASE_URL = environment.apiUrl;

@Injectable({
  providedIn: "root",
})

export class LogsService {

  constructor(private http: HttpClient) {}

  // Get all logs
  getAllLogs(queryParams: QueryParamsModel):Observable<any> {
    return this.http.get<any>(BASE_URL+apiUrl.getAllLogs+
    '?pageSize='+queryParams.pageSize +
    '&pageNumber='+queryParams.pageNumber+
    '&sortField=' + queryParams.sortField +
    '&sortOrder=' + queryParams.sortOrder +
    '&searchText='+queryParams.searchText+
    queryParams.filter).pipe(
      mergeMap(res => {
          console.log(res);
          if(res.response.code === 200) {
              const result = {
                items: res.data.list,
                totalCount: res.data.totalCount
              }
              return of(result);
          } else {
            const result = {
              items: [],
              totalCount: 0
            }
            return of(result);
          }
      })
    );
  }

  // Export all logs
  exportAllLogs(queryParams: QueryParamsModel): Observable<any> {
    return this.http.get<any>(BASE_URL + apiUrl.exportAllLogs +
      '?pageSize=' + queryParams.pageSize +
      '&pageNumber=' + queryParams.pageNumber +
      '&sortField=' + queryParams.sortField +
      '&sortOrder=' + queryParams.sortOrder +
      '&searchText=' + queryParams.searchText +
      queryParams.filter).pipe(
        mergeMap(res => {
          console.log(res);
          return of(res);
        })
      );
  }

}
