// Angular
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
// RxJS
import { from, Observable, of } from 'rxjs';
import { tap, map, switchMap, filter, take } from 'rxjs/operators';
// NGRX
import { select, Store } from '@ngrx/store';
// Module reducers and selectors
import { AppState } from '../../../core/reducers/';
import { currentUserModules } from '../_selectors/auth.selectors';
import { Module } from '../_models/module.model';
import { NgxPermissionsService } from 'ngx-permissions';

@Injectable()
export class ModuleGuard implements CanActivate {
  constructor(private store: Store<AppState>, private router: Router, private modulesService: NgxPermissionsService,) { }

  /**
   * Capitalize the first letter
   */
  capitalizeFirstLetter(str) {
    if (!str) return str;
    return str.charAt(0).toUpperCase() + str.slice(1);
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {

    const moduleNames = route.data.moduleNames as string[];
    if (!moduleNames) {
      return of(false);
    }

    return this.store
      .pipe(
        select(currentUserModules),
        filter((modules: Module[]) => modules && modules.length > 0),
        take(1),
        map((modules: Module[]) => {
          let permissionSet: string[] = [];
          permissionSet.push('defaultDashboard');
          
          modules.forEach((module: Module) => {
            permissionSet.push(module.module);
            for (const key in module.operations) {
              if (module.operations.hasOwnProperty(key)) { // To avoid prototype properties
                if (module.operations[key]) {
                  permissionSet.push(module.module + this.capitalizeFirstLetter(key));
                }
              }
            }
          });
          //console.log('Permissions :: ', permissionSet)
          //console.log('Checking Permissions :: ', moduleNames);
          console.log(moduleNames,permissionSet.some((item) => moduleNames.includes(item)))
          return permissionSet.some((item) => moduleNames.includes(item));
        }),
        tap(hasAccess => {
          console.log('Has Permissions ::', hasAccess)
          if (!hasAccess) {
            this.router.navigateByUrl('/error/403');
          }
        })
      );
  }
}
