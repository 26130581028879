<div class="dropdown d-none d-lg-inline-block ml-1" ngbDropdown>
  <button type="button" class="btn header-item noti-icon" ngbDropdownToggle>
    <i class="bx bx-help-circle"></i>
  </button>
  <div class="dropdown-menu dropdown-menu-lg dropdown-menu-right" ngbDropdownMenu>
    <div class="px-lg-2">
      <div class="row no-gutters">
        <div class="col">
          <a class="dropdown-icon-item" [routerLink]="'help-center/user-guide'" routerLinkActive="active">
            <img src="assets/images/help/user-guide.png" alt="URL Shortner">
            <span>{{'HELP.USER_GUIDE'| translate}}</span>
          </a>
        </div>
        <div class="col" >
          <a class="dropdown-icon-item" [routerLink]="'help-center/faq'" routerLinkActive="active">
            <img src="assets/images/help/faq.png" alt="Data sync">
            <span>{{'HELP.FAQ'| translate}}</span>
          </a>
        </div>

      </div>

      <div class="row no-gutters">
        <div class="col">
          <a class="dropdown-icon-item" [routerLink]="'help-center/support'" routerLinkActive="active">
            <img src="assets/images/help/support.png" alt="Data sync">
            <span>{{'HELP.SUPPORT'| translate}}</span>
          </a>
        </div>
        <div class="col">
          <a class="dropdown-icon-item" [routerLink]="'help-center/change-log'" routerLinkActive="active">
            <img src="assets/images/help/change-log.png" alt="Data sync">
            <span>{{'HELP.CHANGE_LOG'| translate}}</span>
          </a>
        </div>
      </div>
    </div>
  </div>
</div>
