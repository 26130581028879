<div id="layout-wrapper">

  <app-topbar [user]="user" (settingsButtonClicked)="onSettingsButtonClicked()" (mobileMenuButtonClicked)="onToggleMobileMenu()">
  </app-topbar>

  <app-sidebar [isCondensed]="isCondensed"></app-sidebar>

  <div class="main-content">
    <div class="page-content" style="min-height: 100vh !important;">
      <!-- content -->
      <div class="container-fluid">
        <app-subheader></app-subheader>
        <router-outlet></router-outlet>
      </div>
    </div>

    <!-- footer -->
    <app-footer></app-footer>
  </div>
</div>
