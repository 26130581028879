// Angular
import { Component, Inject, OnInit, ChangeDetectorRef, OnDestroy } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
// RxJS
import { Observable, of, Subscription, Subject } from 'rxjs';
import { finalize, takeUntil, tap } from 'rxjs/operators';

import { ReasonsService, Attribute, VehicleService } from '../../../../core/master-data';
import { Reason } from '../../../../core/master-data';
import { Driver, DriversService } from '../../../../core/driver';

@Component({
  selector: 'app-update-status-entity',
  templateUrl: './update-status-entity.component.html',
  styleUrls: ['./update-status-entity.component.scss']
})
export class UpdateStatusEntityComponent implements OnInit, OnDestroy {

  // Public properties
  viewLoading: boolean = false;

  statusForm: FormGroup;

  reasonList: Reason[] = [];
  driverList: Driver[] = [];
  vehicleTypeList: Attribute[] = [];

  type: string = '';

  isMaster: boolean = false;
  tempDriverId: string = '';

  disableList: string[] = [
    'Coming Soon',
    'Unavilable',
    'Disabled',
    'High Demand',
    'Maintenance'
  ];

  private subscriptions: Subscription[] = [];

  constructor(
    public dialogRef: MatDialogRef<UpdateStatusEntityComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private formBuilder: FormBuilder,
    private cdr: ChangeDetectorRef,
    private service: ReasonsService,
    private vehicleService: VehicleService,
    private driverService: DriversService
  ) { }

  ngOnInit() {
    if (this.data.type === 'vehicleType') {
      this.getAllVehicleType();
    }else {
      this.getAllReaons();
    }
    // if (this.data.extra?.driverDetails?.driverType === 'Master') {
    //   this.isMaster = true;
    //   console.log(this.data.extra.driverDetails);
    //   this.getAllCUGMembers(this.data.extra?.driverDetails?.downStreamLabel);
    // }
    this.initForm();
    this.cdr.detectChanges();
  }

  ngOnDestroy() {
    this.subscriptions.forEach(el => el.unsubscribe());
  }

  getAllReaons() {
    this.reasonList = [];
    this.subscriptions.push(this.service.getReason(this.data.reason).subscribe(
      (res: any) => {
        console.log(res);
        if (res.response.code === 200) {
          this.reasonList = res.data[0].collections;
          this.cdr.detectChanges();
        } else {
          this.reasonList = [];
        }
      },
      error => {
        this.reasonList = [];
      }
    ));
  }

  getAllVehicleType() {
    this.vehicleTypeList = [];
    this.subscriptions.push(this.vehicleService.getAttributes('Type').subscribe(
      (res: any) => {
        console.log(res);
        if (res.response.code === 200) {
          this.vehicleTypeList = res.data;
          this.cdr.detectChanges();
        } else {
          this.vehicleTypeList = [];
        }
      },
      error => {
        this.vehicleTypeList = [];
      }
    ));
  }

  getAllCUGMembers(cugId) {
    this.driverList = [];
    let query = 'cug=' + cugId + '&isDelete=false&kycStatus=Approved,Partially Approved&driverType=Member';
    console.log(query);
    this.subscriptions.push(this.driverService.getAllCugMembers(query).subscribe(
      (res: any) => {
        console.log(res);
        if (res.response.code === 200) {
          this.driverList = res.data.list;
          this.cdr.detectChanges();
        } else {
          this.driverList = [];
        }
      },
      error => {
        this.driverList = [];
      }
    ));
  }

  initForm() {
    if ((this.type === 'other') || (this.data.type === 'delete') || (this.data.type === 'kycReject') || (this.data.type === 'kycValid') || (this.data.type === 'settlementStatus') || (this.data.type === 'add')) {
      this.statusForm = this.formBuilder.group({
        reason: [this.type, Validators.compose([
          //Validators.required
        ])
        ],
        driverId: [this.tempDriverId, Validators.compose([
        ])
        ],
        comment: ['', Validators.compose([
          //Validators.required
        ])
        ]
      });
    } else {
      this.statusForm = this.formBuilder.group({
        reason: [this.type, Validators.compose([
          //Validators.required
        ])
        ],
        driverId: [this.tempDriverId, Validators.compose([
        ])
        ],
      });
    }
  }

  reasonChange(value, type) {
    if (type === 'reason') {
      this.type = value;
    }else if (type === 'driver') {
      this.tempDriverId = value;
    }
    this.initForm();
  }

  onCancel(): void {
    this.dialogRef.close();
  }

  onConfirm(): void {
    const controls = this.statusForm.controls;
    /** check form */
    if (this.statusForm.invalid) {
      Object.keys(controls).forEach(controlName =>
        controls[controlName].markAsTouched()
      );
      return;
    }
    this.viewLoading = true;
    let reason = controls.reason.value;
    if (this.type === 'other') {
      reason = controls.comment.value + '(Other)';
    }
    if (this.data.type === 'delete' || this.data.type === 'kycReject' || this.data.type === 'kycValid' || this.data.type === 'settlementStatus' || this.data.type === 'add') {
      reason = controls.comment.value;
    }
    setTimeout(() => {
      if (this.data.type === 'vehicleType') {
        this.dialogRef.close({ vehicleType: reason }); // Keep only this row
      }else{
        this.dialogRef.close({ reason: reason, tempMasterId: controls.driverId.value, status: controls.reason.value }); // Keep only this row
      }
    }, 500);
  }

  isControlHasError(controlName: string, validationType: string = undefined): boolean {
    const control = this.statusForm.controls[controlName];
    if (!control) {
      return false;
    }
    if (validationType) {
      const result = control.hasError(validationType) && (control.dirty || control.touched);
      return result;
    }
    if (control.errors && (control.dirty || control.touched)) {
      return true;
    }
    return false;
  }

  getIcon(type) {
    switch (type) {
      case 'suspend':
      case 'kycReject':
      case 'disable':
      case 'settlementStatus':
        return 'fa-times';
      case 'reinstate':
      case 'kycValid':
        return 'fa-check';
      case 'vehicleType':
        return 'fa-car';
      case 'add':
        return 'fa-plus';
      default:
        return 'fa-trash';
    }
  }
}
