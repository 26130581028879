// Anglar
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
// Layout Directives
// Services
import {
	CustomFilterPipe,
	FirstLetterPipe,
	GetObjectPipe,
	JoinPipe,
	SafePipe,
	TimeElapsedPipe,
} from './_base/layout';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
	imports: [CommonModule],
	declarations: [
		// pipes
		TimeElapsedPipe,
		JoinPipe,
		GetObjectPipe,
		SafePipe,
		FirstLetterPipe,
		CustomFilterPipe,
		
	],
	exports: [
		// pipes
		TimeElapsedPipe,
		JoinPipe,
		GetObjectPipe,
		SafePipe,
		FirstLetterPipe,
		CustomFilterPipe,
		TranslateModule
	],
	providers: []
})
export class CoreModule {
}
