// Angular
import { ChangeDetectorRef, Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
// RxJS
import { Subject, Subscription } from 'rxjs';
import { finalize, takeUntil, tap } from 'rxjs/operators';
// Store
import { Store } from '@ngrx/store';
import { AppState } from '../../../../core/reducers';
// Layout
import { EncryptionService, LayoutUtilsService, MessageType } from '../../../../core/_base/crud/';
// Auth
import { AuthService, Login, UserLoaded } from '../../../../core/auth';

import { environment } from '../../../../../environments/environment';
import { UserLoad, UserModuleLoad } from 'src/app/core/auth/_actions/auth.actions';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html'
})
export class LoginComponent implements OnInit, OnDestroy {
  // Public params
  loginForm: FormGroup;
  loading = false;
  passwordType: string[] = ['password'];
  DEMO: any = {};
  returnUrl: string;
  userId: string;

  private subscriptions: Subscription[] = [];

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private auth: AuthService,
    private store: Store<AppState>,
    private fb: FormBuilder,
    private cdr: ChangeDetectorRef,
    private encryptionService: EncryptionService,
    private layoutService: LayoutUtilsService
  ) { }

  ngOnInit(): void {

    if (environment.production) {
      this.DEMO = {
        email: "",
        password: ""
      }
    } else {
      this.DEMO = {
        email: "",
        password: ""
        // email: "dotsadmin@viadots.com",
        // password: "Dots@123"
      }
    }
    this.initLoginForm();
    const user = localStorage.getItem(environment.userSession);
    this.subscriptions.push(this.route.queryParams.subscribe(params => {
      if (params.type === 'expired') {
        this.layoutService.showAlert(MessageType.info, "Active session has been expired, please login again to continue.");
      }
      if (params.session) {
        let session = this.encryptionService.decryptQuery(params.session);
        this.returnUrl = session.returnUrl;
        this.userId = session.userId;
        console.log(session);
      }
      if (user && user.length > 0) {
        this.router.navigate(['/auth/lock'], { queryParams: params });
      }
    }));
  }

  /**
   * On destroy
   */
  ngOnDestroy(): void {
    this.layoutService.showAlert();
    this.loading = false;
    this.subscriptions.forEach(el => el.unsubscribe());
  }

  togglePassword(index) {
    if (this.passwordType[index] === 'password') {
      this.passwordType[index] = 'text';
    } else {
      this.passwordType[index] = 'password';
    }
  }

  initLoginForm() {
    this.loginForm = this.fb.group({
      email: [this.DEMO.email, Validators.compose([
        Validators.required,
        Validators.pattern("[a-zA-Z0-9.-]{1,}@[a-zA-Z.-]{2,}[.]{1}[a-zA-Z]{2,}")
      ])
      ],
      password: [this.DEMO.password, Validators.compose([
        Validators.required
      ])
      ],
      remindMe: [false, Validators.compose([])
      ],
    });
  }

  onSubmit() {
    const controls = this.loginForm.controls;
    /** check form */
    if (this.loginForm.invalid) {
      Object.keys(controls).forEach(controlName =>
        controls[controlName].markAsTouched()
      );
      return;
    }
    this.loading = true;
    const authData = {
      email: controls.email.value,
      password: controls.password.value
    };
    this.subscriptions.push(this.auth.login(authData).subscribe(
      res => {
        console.log(res);
        this.loading = false;
        this.cdr.detectChanges();
        if (res.response.code === 201) {
          if (!res.data.firstTimeLogin) {
            if (controls.remindMe.value) {
              localStorage.setItem(environment.userSession, JSON.stringify(res.data));
            }
            this.store.dispatch(new Login({ authToken: res.data.token, accessToken: res.data.accessToken }));
            this.store.dispatch(new UserLoad({ user: res.data }));
            this.store.dispatch(new UserModuleLoad({ allModules: res.data.accessDetails?.modules || [] }));
            if (this.userId == res.data?._id) {
              this.router.navigateByUrl(this.returnUrl || '/dashboard');
            }else {
              this.router.navigateByUrl('/dashboard');
            }
            this.layoutService.showActionNotification(MessageType.success, 'Welcome to viaDOTS', 'Manage dots taxi platform seamlessly, and get updates of latest statistics and analytics.');
          } else {
            this.router.navigate(['/auth/change-password'], { queryParams: { token: res.data.token, type: 'first-login' } });
          }
        }
      },
      error => {
        this.loading = false;
        this.cdr.detectChanges();
        console.log(error);
        if (error.status === 404) {
          this.layoutService.showAlert(MessageType.danger, "User does not exist");
        } else if (error.status === 401) {
          this.layoutService.showAlert(MessageType.danger, "Incorrect login password");
        } else if (error.status === 403) {
          this.layoutService.showAlert(MessageType.warning, "Oops! Your account has been suspended. Contact admin to reactivate account.");
        } else {
          this.layoutService.showAlert(MessageType.danger, "Something went wrong, Please try again later.");
        }
      }
    ));
  }

  isControlHasError(controlName: string, validationType: string = undefined): boolean {
    const control = this.loginForm.controls[controlName];
    if (!control) {
      return false;
    }
    if (validationType) {
      const result = control.hasError(validationType) && (control.dirty || control.touched);
      return result;
    }
    if (control.errors && (control.dirty || control.touched)) {
      return true;
    }
    return false;
  }
}

