// Angular
import { Injectable } from '@angular/core';
// RxJS
import { of, forkJoin, Observable, defer } from 'rxjs';
import { mergeMap, map } from 'rxjs/operators';
// NGRX
import { createEffect, Actions, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
// CRUD
import { QueryResultsModel, QueryParamsModel } from '../../_base/crud';
// Services
import { AuthService } from '../_services/auth.service';
// State
import { AppState } from '../../../core/reducers';

// Actions
import {
    RoleActionTypes,
    RolesPageRequested,
    RolesPageLoaded,
    RolesPageToggleLoading,
    RolesActionToggleLoading,
    AllRolesRequested,
    AllRolesLoaded
} from '../_actions/role.actions';

@Injectable()
export class RoleEffects {
    showPageLoadingDistpatcher = new RolesPageToggleLoading({ isLoading: true });
    hidePageLoadingDistpatcher = new RolesPageToggleLoading({ isLoading: false });

    showActionLoadingDistpatcher = new RolesActionToggleLoading({ isLoading: true });
    hideActionLoadingDistpatcher = new RolesActionToggleLoading({ isLoading: false });
    loadAllRoles$ = createEffect(() => this.actions$
    .pipe(
        ofType<AllRolesRequested>(RoleActionTypes.AllRolesRequested),
        // withLatestFrom(this.store.pipe(select(allRolesLoaded))),
        // filter(([action, isAllRolesLoaded]) => !isAllRolesLoaded),
        mergeMap(() => this.auth.getAllRoles()),
        map(roles => {
            console.log(roles);
            return new AllRolesLoaded({roles});
        })
      ));
    loadRolesPage$ = createEffect(() => this.actions$
        .pipe(
            ofType<RolesPageRequested>(RoleActionTypes.RolesPageRequested),
            mergeMap(( { payload } ) => {
                this.store.dispatch(this.showPageLoadingDistpatcher);
                const requestToServer = this.auth.findRoles(payload.page);
                const lastQuery = of(payload.page);
                return forkJoin(requestToServer, lastQuery);
            }),
            map(response => {
              console.log(response)
                const result: QueryResultsModel = response[0];
                const lastQuery: QueryParamsModel = response[1];
                this.store.dispatch(this.hidePageLoadingDistpatcher);

                return new RolesPageLoaded({
                    roles: result.items,
                    totalCount: result.totalCount,
                    page: lastQuery
                });
            }),
        ));

        init$: Observable<Action> = createEffect(() => defer(() => {
            return of(new AllRolesRequested());
        }));
    constructor(private actions$: Actions, private auth: AuthService, private store: Store<AppState>) { }
}
