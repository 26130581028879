// Angular
import { Injectable } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
// RxJS
import { filter, mergeMap, tap, withLatestFrom } from 'rxjs/operators';
import { defer, Observable, of } from 'rxjs';
// NGRX
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, select, Store } from '@ngrx/store';
// Auth actions
import { AuthActionTypes, Login, Logout, UserLoad, UserLoaded, UserModuleLoad, UserRequested } from '../_actions/auth.actions';
import { AuthService } from '../_services/auth.service';
import { AppState } from '../../reducers';
import { environment } from '../../../../environments/environment';
import { currentUser, isUserLoaded } from '../_selectors/auth.selectors';
import { EncryptionService } from '../../_base/crud';

@Injectable()
export class AuthEffects {

  private session: any = {
    userId: '',
    returnUrl: ''
  };

  constructor(private actions$: Actions,
    private router: Router,
    private auth: AuthService,
    private encryptionService: EncryptionService,
    private store: Store<AppState>) {

    this.store.pipe(select(currentUser)).subscribe(res => {
      if (res) {
        this.session.userId = res?._id || null;
      }
    });

    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.session.returnUrl = event.url;
        if (this.session.returnUrl?.includes("auth")) {
          this.session.returnUrl = null;
        }
      }
    });
  }

  login$ = createEffect(() => this.actions$.pipe(
    ofType<Login>(AuthActionTypes.Login),
    tap(action => {
      localStorage.setItem(environment.authTokenKey, action.payload.authToken);
      localStorage.setItem(environment.refreshTokenKey, action.payload.accessToken);
      //this.store.dispatch(new UserRequested());
    }),
  ), { dispatch: false });

  logout$ = createEffect(() => this.actions$.pipe(
    ofType<Logout>(AuthActionTypes.Logout),
    tap(action => {
      localStorage.removeItem(environment.authTokenKey);
      localStorage.removeItem(environment.refreshTokenKey);
      this.router.navigate(['/auth/login'], {queryParams: { type: action.payload?.type, session: this.encryptionService.encryptQuery(this.session) }});
    })
  ), { dispatch: false });

  loadUser$ = createEffect(() => this.actions$
    .pipe(
      ofType<UserRequested>(AuthActionTypes.UserRequested),
      withLatestFrom(this.store.pipe(select(isUserLoaded))),
      filter(([action, _isUserLoaded]) => !_isUserLoaded),
      mergeMap(([action, _isUserLoaded]) => this.auth.getUserByToken()),
      tap(res => {
        console.log(res);
        if (res.response.code === 200) {
          const user = localStorage.getItem(environment.userSession);
          if (user && user.length > 0) {
            localStorage.setItem(environment.userSession, JSON.stringify(res.data));
          }
          this.store.dispatch(new UserLoad({ user: res.data }));
          this.store.dispatch(new UserModuleLoad({ allModules: res.data.accessDetails?.modules || [] }));
        } else {
          this.store.dispatch(new Logout({ type: 'expired' }));
        }
      })
    ), { dispatch: false });

  init$: Observable<Action> = createEffect(() => defer(() => {
    console.log("Session state monitor");
    const authToken = localStorage.getItem(environment.authTokenKey);
    const refreshToken = localStorage.getItem(environment.refreshTokenKey);
    let observableResult = of({ type: 'NO_ACTION' });
    if (authToken) {
      this.store.dispatch(new UserRequested());
      observableResult = of(new Login({ authToken: authToken , accessToken : refreshToken }));
    }
    return observableResult;
  }));
}
