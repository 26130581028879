import { Action } from '@ngrx/store';
import { User } from '../_models/user.model';
import { Module } from '../_models/module.model';

export enum AuthActionTypes {
    Login = '[Login] Action',
    Logout = '[Logout] Action',
    UserRequested = '[Request User] Action',
    UserLoad = '[Load User] Action',
    UserLoaded = '[Load User] Auth API',
    UserModuleLoad = '[Load User Module] Auth API'
}

export class Login implements Action {
    readonly type = AuthActionTypes.Login;
    constructor(public payload: { authToken: string ,accessToken:string}) { }
}

export class Logout implements Action {
    readonly type = AuthActionTypes.Logout;
    constructor(public payload?: { type?: string }) { }
}

export class UserRequested implements Action {
    readonly type = AuthActionTypes.UserRequested;
}

export class UserLoad implements Action {
  readonly type = AuthActionTypes.UserLoad;
  constructor(public payload: { user: User }) { }
}

export class UserLoaded implements Action {
    readonly type = AuthActionTypes.UserLoaded;
    constructor(public payload: { user: User }) { }
}

export class UserModuleLoad implements Action {
  readonly type = AuthActionTypes.UserModuleLoad;
  constructor(public payload: { allModules: Module[] }) { }
}

export type AuthActions = Login | Logout | UserRequested | UserLoaded | UserLoad | UserModuleLoad;
