<!-- Right Sidebar -->

<div class="right-bar" (clickOutside)="hide()">
  <div class="h-100">
    <div class="rightbar-title px-3 py-4">
      <a
        href="javascript:void(0);"
        class="right-bar-toggle float-right"
        (click)="hide()"
      >
        <i class="mdi mdi-close noti-icon"></i>
      </a>
      <h5 class="m-0">Settings</h5>
    </div>
    <div role="region" class="simplebar-content-wrapper" tabindex="0" aria-label="scrollable content" style="height: 100%; overflow: hidden scroll;">
      <div class="simplebar-content" style="padding: 0px;">
    <hr class="mt-0" />
    <div class="p-0">
      <h6 class="mb-0 ml-2">Choose Layouts</h6>
      <div class="rightbar-title px-2 py-4">

        <div class="page-title-right m-0">
          <div class="toggle-group">
            <label class="toggle">
              <input type="checkbox"/>
              <span class="slider"></span>
              <!-- label element 👇 -->
              <span class="labels"></span>
            </label>          <label
            for="is-layout"
            class="form-check-label"
            >Vertical</label
          >
          </div>
        </div>
        <h6 class="mt-4 mb-3 ">Width</h6>
        <hr />
        <div class="form-check ml-2">
          <input
           
            type="radio"
            name="layout-width"
            id="layout-width-fuild"
            value="fluid"
            class="form-check-input"
          /><label
           
            for="layout-width-fuild"
            class="form-check-label"
            >Fluid</label
          >
        </div>
        <div class="form-check ml-2">
          <input
           
            type="radio"
            name="layout-width"
            id="layout-width-boxed"
            value="boxed"
            class="form-check-input"
          /><label
           
            for="layout-width-boxed"
            class="form-check-label"
            >Boxed</label
          >
        </div>
        <div class="form-check ml-2">
          <input
           
            type="radio"
            name="layout-width"
            id="layout-width-scrollable"
            value="scrollable"
            class="form-check-input"
          /><label
           
            for="layout-width-scrollable"
            class="form-check-label"
            >Scrolable</label
          >
        </div>
        <div>
          <h6 class="mt-4 mb-3">Sidebar Color</h6>
          <hr />
          <div class="form-check sidebar-setting ml-2">
            <input
             
              type="radio"
              name="sidebar-color"
              id="sidebar-color-light"
              value="light"
              class="form-check-input"
            /><label
             
              for="sidebar-color-light"
              class="form-check-label"
              >Light</label
            >
          </div>
          <div class="form-check sidebar-setting ml-2">
            <input
             
              type="radio"
              name="sidebar-color"
              id="sidebar-color-dark"
              value="dark"
              class="form-check-input"
            /><label
             
              for="sidebar-color-dark"
              class="form-check-label"
              >Dark</label
            >
          </div>
          <div class="form-check sidebar-setting ml-2">
            <input
             
              type="radio"
              name="sidebar-color"
              id="sidebar-color-brand"
              value="compact"
              class="form-check-input"
            /><label
             
              for="sidebar-color-brand"
              class="form-check-label"
              >Compact</label
            >
          </div>
          <div class="form-check sidebar-setting ml-2">
            <input
             
              type="radio"
              name="sidebar-color"
              id="sidebar-color-icon"
              value="icon"
              class="form-check-input"
            /><label
             
              for="sidebar-color-icon"
              class="form-check-label"
              >Icon</label
            >
          </div>
          <div class="form-check sidebar-setting ml-2">
            <input
             
              type="radio"
              name="sidebar-color"
              id="sidebar-color"
              value="colored"
              class="form-check-input"
            /><label
             
              for="sidebar-color"
              class="form-check-label"
              >Colored</label
            >
          </div>
        </div>
        <!---->
        <h6 class="mt-4 mb-3">Theme Mode</h6>
        <hr />
        <div class="form-check sidebar-setting ml-2">
          <input
           
            type="radio"
            name="theme-color"
            id="theme-color-light"
            class="form-check-input"
          /><label
           
            for="theme-color-light"
            class="form-check-label"
            >Light</label
          >
        </div>
        <div class="form-check sidebar-setting ml-2">
          <input
           
            type="radio"
            name="theme-color"
            id="theme-color-dark"
            class="form-check-input"
          /><label
           
            for="theme-color-dark"
            class="form-check-label"
            >Dark</label
          >
        </div>
        <!---->
      </div>
    </div>
  </div>
</div>
</div>
</div>
<!-- /Right-bar -->

<!-- Right bar overlay-->
<div class="rightbar-overlay"></div>
