// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  envName: 'dev',
  versionName: '4.13.7' + '-DEV',
  versionCode: 78,
  authTokenKey: 'authTokenDev',
  refreshTokenKey:'refreshTokenDev',
  userSession: 'userSessionDev',
  clientIpv4: 'clientIpv4Dev',
  clientMac: 'clientMacDev',

  // Encryption
  encryptKey: 1203199320052021,
  encryptIV: 1203199320052021,

  // RazorPay
  razorPayKey: 'rzp_test_ROP5kg4BrRfTUq',

  // Google Services
  googlePlacesRestriction: ['IN', 'CA'],
  googleApiKey: 'AIzaSyAciCkBw7q2YzA6C10ykDwcISjT8W7PZ5c',
  vapidKey: 'BJGZ9x2Qm_XibkVfjULE7CnRqbvgcW9IgtSDYLxg4ZryTkm9RJ_vTXgv2ebI3d-DoNCw12GdemYxerVbcyws9bg',

  // App URL
  invoiceUrl: 'https://dev.receipt.dotstaxi.com',
  trackUrl: 'https://d.t.saaksan.com',
  apiUrl: 'https://development.backend.viadots.com',

  // Thingsboard
  tbUrl: 'http://3.109.99.0:8080',
  tbSocketUrl: '3.109.99.0:8080',
  tbSecretKey: 'eyJhbGciOiJIUzUxMiJ9.eyJzdWIiOiJkZXYudGhpbmdzYm9hcmRAdmlhZG90cy5jb20iLCJ1c2VySWQiOiIyNzBhNjVlMC1lM2VhLTExZWYtYjQ3My0xZGZiNmQwM2EyMjkiLCJzY29wZXMiOlsiUkVGUkVTSF9UT0tFTiJdLCJzZXNzaW9uSWQiOiI1Njg4MmFkNy1mZDBmLTRkZTItOTA5Yi01MmFmMTU0MzI2ZDAiLCJleHAiOjE4OTcyNzEzODIsImlzcyI6InZpYWRvdHMiLCJpYXQiOjE3NDE3NTEzODIsImlzUHVibGljIjpmYWxzZSwianRpIjoiYzgyNWRhODUtNTM0ZS00ZDkxLTgxMmQtNTNiMjBhMWJkOGM0In0.S6vCHeA9r_QY1OtFv8SXPtwJz5cqOqlBNN33v1Xm4zcM88CIeAY_H5ApPIqInKf0RFrJq02qzhWtCWyWbExAMg',

  firebaseConfig: {
    apiKey: "AIzaSyAp0eAYAoY_D0ag157z8dCQfwP2PEo9cRA",
    authDomain: "dots-taxi-dev.firebaseapp.com",
    databaseURL: "https://dots-taxi-dev.firebaseio.com",
    projectId: "dots-taxi-dev",
    storageBucket: "dots-taxi-dev.appspot.com",
    messagingSenderId: "1026074205887",
    appId: "1:1026074205887:web:16df26d9fda3e17560700a",
    measurementId: "G-1LESWNVV56"
  }

};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
