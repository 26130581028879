export class Module {
  _id: number;
  group: string;
  title: string;
  module: string;
  operations: any;
  __v: number;

  clear(): void {
    this._id = undefined;
    this.group = '';
    this.title = '';
    this.module = '';
    this.operations = {};
    this.__v = 0;
  }
}
